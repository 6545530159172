import { doc, getDoc } from 'firebase/firestore';
import { FirestoreDB } from '../utils/firebase/firebase';
// import { useQuery } from 'react-query';
import { useQuery } from '@tanstack/react-query';
const useGetInvitation = (id) => {
  const getInvitationDetails = async (inviteId) => {
    const invitedDoc = await getDoc(doc(FirestoreDB, 'invitations', inviteId));
    return { ...invitedDoc.data(), inviteId };
  };
  const { data, isLoading, isError } = useQuery({
    queryKey: [id],
    queryFn: () => getInvitationDetails(id),
    staleTime: 300000,
  });
  return { data, isLoading, isError };
};
export default useGetInvitation;