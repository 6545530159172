import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  TextField,
  Tabs,
  Tab,
  Box,
  Typography,
  Table,
  Paper,
  TableHead,
  TableRow,
  Tooltip,
  TableCell,
  TableBody,
  IconButton,
  Skeleton,
  TableContainer,
  MenuItem,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Switch,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MainLayout from '../../hoc/MainLayout';
import { Edit, Delete } from '@mui/icons-material';
import { FirestoreDB } from '../../utils/firebase/firebase';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';
import AuthContext from '../../contexts/AuthContext';
import { toast } from 'react-toastify';
import { updateStudentProfileDetails } from '../../api';
import useSchoolsData from '../../hooks/useSchoolsData';
import useStudentData from '../../hooks/useStudentsData';
import useTeachersData from '../../hooks/useTeachersData';

const StudentsPage = () => {
  const { user, typeOfUser, updateGlobalUser } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [students, setStudents] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [grade, setGrade] = useState('');
  const [schoolType, setSchoolType] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [studentIndexDelete, setStudentIndexDelete] = useState(0);

  const {
    data: fetchStudent = [],
    isLoading: isStudentsLoading,
    error: studentsError,
    refetch: refetchStudents,
  } = useStudentData(user?.uid, typeOfUser);

  const {
    data: schools = [],
    isLoading: isSchoolsLoading,
    error: schoolsError,
    refetch: refetchSchools,
  } = useSchoolsData();

  const {
    data: fetchedTeachers = [],
    isLoading: isTeachersLoading,
    error: teachersError,
    refetch: refetchTeachers,
  } = useTeachersData(user?.uid, typeOfUser);

  const initialStudentData = {
    name: '',
    grade: '',
    class: '',
    email: '',
    admissionNumber: '',
    schoolId: '',
    schoolName: '',
    teacherId: '',
    teacherName: '',
    parentName: '',
  };

  const [studentData, setStudentData] = useState(initialStudentData);
  const [filteredTeachers, setFilteredTeachers] = useState([]);

  const grades = [
    { id: '1', name: 'Pre-School' },
    { id: '2', name: 'Kinder garden' },
    { id: '3', name: '1-12 grade' },
    { id: '4', name: 'k-2' },
  ];
  const schoolTypes = [
    { id: '1', name: 'Elementary School' },
    { id: '2', name: 'Middle School' },
    { id: '3', name: 'High School' },
  ];

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setStudentData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
    if (name === 'schoolName') {
      const selectedSchool = schools.find(
        (school) => school.schoolName === value
      );
      const relatedTeachers = fetchedTeachers.filter(
        (teacher) => teacher.schoolName === selectedSchool?.schoolName
      );
      setFilteredTeachers(relatedTeachers);
    }
  };

  const handleOpen = (studentId = null) => {
    if (studentId !== null) {
      const studentToEdit = fetchStudent.find(
        (student) => student.id === studentId
      );

      if (studentToEdit) {
        setEditIndex(studentId);

        const selectedSchool = schools.find(
          (school) => school.schoolName === studentToEdit.schoolName
        );

        const relatedTeachers = fetchedTeachers.filter(
          (teacher) => teacher.schoolName === selectedSchool?.schoolName
        );
        setFilteredTeachers(relatedTeachers);

        const selectedTeacher = relatedTeachers.find(
          (teacher) => teacher.name === studentToEdit.teacherName
        );

        setStudentData({
          name: studentToEdit.name || '',
          email: studentToEdit.email || '',
          admissionNumber: studentToEdit.admissionNumber || '',
          parentName: studentToEdit.parentName || '',
          schoolId:
            schools.find(
              (school) => school.schoolName === studentToEdit.schoolName
            )?.id || '',
          schoolName: studentToEdit.schoolName || '',
          teacherId: selectedTeacher?.id || '',
          teacherName: selectedTeacher?.name || '',
          grade:
            grades.find((grade) => grade.name === studentToEdit.grade)?.id ||
            '',
          schoolType:
            schoolTypes.find((type) => type.name === studentToEdit.schoolType)
              ?.id || '',
        });

        setGrade(
          grades.find((grade) => grade.name === studentToEdit.grade)?.id || ''
        );
        setSchoolType(
          schoolTypes.find((type) => type.name === studentToEdit.schoolType)
            ?.id || ''
        );
      }
    } else {
      setEditIndex(null);
      setStudentData(initialStudentData);
      setGrade('');
      setSchoolType('');
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setActiveTab(0);
    setStudentData(initialStudentData);
    setSchoolType('');
    setGrade('');
  };

  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  const handleAddOrEditStudent = async () => {
    const selectedSchool = schools.find(
      (school) => school.schoolName === studentData.schoolName
    );
    if (editIndex !== null) {
      try {
        const updatedStudent = {
          fullname: studentData.name,
          admissionNumber: studentData.admissionNumber,
          parentName: studentData.parentName,
          schoolName: studentData.schoolName,
          schoolId: selectedSchool?.schoolId || selectedSchool?.id || '',
          teacherName: studentData.teacherName,
          email: studentData.email,
          timestamp: new Date(),
        };

        console.log('Update student data, uid', updatedStudent, editIndex);

        const result = await updateStudentProfileDetails(
          btoa(JSON.stringify(updatedStudent)),
          editIndex
        );

        if (result) {
          updateGlobalUser({ ...user, ...studentData });

          handleClose();
          console.log('Student profile updated successfully via API');
          await refetchStudents();
        } else {
          console.error('API returned an error or no result');
        }
      } catch (error) {
        console.error('Error updating student profile:', error);
      }
    } else {
      const params = {
        fromEmail: user?.email,
        inviteType: 'STUDENT',
        message: '',
        fullname: studentData.name,
        parentName: studentData.parentName,
        teacherName: studentData.teacherName,
        schoolName: studentData.schoolName,
        schoolId: selectedSchool?.schoolId || selectedSchool?.id || '',
        teacherIds: [studentData.teacherId] || [],
        timestamp: new Date(),
        toEmail: studentData.email,
      };

      try {
        const docRef = await addDoc(
          collection(FirestoreDB, 'invitations'),
          params
        );
        console.log('Invitation document written with ID: ', docRef.id);

        // setStudents((prevStudents) => [...prevStudents, studentData]);
      } catch (e) {
        console.error('Error adding document: ', e);
      }
    }
    handleClose();
    if (editIndex === null)
      toast.success('Student added successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    else
      toast.success('Student updated successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
  };

  const handleStatusChange = async (studentId, isActive) => {
    try {
      const studentDocRef = doc(FirestoreDB, 'studentsList', studentId);
      await updateDoc(studentDocRef, {
        status: isActive ? 'active' : 'inactive',
      });

      console.log(`Status updated for student ID: ${studentId}`);

      setStudents((prevStudents) =>
        prevStudents.map((student) =>
          student.id === studentId
            ? { ...student, status: isActive ? 'active' : 'inactive' }
            : student
        )
      );
      await refetchStudents();
      console.log(`Status updated for student ID: ${studentId}`);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleDeleteStudent = async (studentId) => {
    try {
      const studentDocRef = doc(FirestoreDB, 'studentsList', studentId);

      await deleteDoc(studentDocRef);

      console.log(`Student with ID ${studentId} deleted successfully.`);

      await refetchStudents();
      toast.success('Student deleted successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error) {
      console.error(`Error deleting student with ID ${studentId}:`, error);
    }
  };

  useEffect(() => {
    if (fetchStudent?.length > 0) {
      setStudents(fetchStudent);
    }
  }, [fetchStudent]);

  const SkeletonRow = () => {
    return (
      <TableRow>
        <TableCell>
          <Skeleton variant='text' width={200} />
        </TableCell>

        <TableCell>
          <Skeleton variant='text' width={75} />
        </TableCell>

        <TableCell>
          <Skeleton variant='text' width={75} />
        </TableCell>

        <TableCell>
          <Skeleton variant='text' width={75} />
        </TableCell>

        <TableCell>
          <Skeleton variant='text' width={75} />
        </TableCell>

        <TableCell>
          <Skeleton
            variant='rectangular'
            width={50}
            height={25}
            style={{ marginRight: '10px' }}
          />
        </TableCell>
        <TableCell>
          <Skeleton
            variant='rectangular'
            width={50}
            height={25}
            style={{ marginRight: '10px' }}
          />
        </TableCell>
      </TableRow>
    );
  };
  return (
    <MainLayout>
      <Box sx={{ padding: '20px' }}>
        <Typography variant='h4' gutterBottom>
          Students
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
          }}
        >
          <Button
            variant='contained'
            color='primary'
            onClick={() => handleOpen()}
            sx={{ marginBottom: '20px' }}
          >
            + Add Student
          </Button>
        </div>

        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>
                  Admission Number
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  Student Name
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  Parent Name
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  School Name
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  Teacher Name
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.length > 0 ? (
                students.map((student) => (
                  <TableRow key={student.id}>
                    <TableCell>{student.admissionNumber}</TableCell>
                    <TableCell>{student.name}</TableCell>
                    <TableCell>{student.parentName}</TableCell>
                    <TableCell>{student.schoolName}</TableCell>
                    <TableCell>{student.teacherName}</TableCell>
                    <TableCell>
                      <Switch
                        checked={student.status === 'active'}
                        onChange={(e) =>
                          handleStatusChange(student.id, e.target.checked)
                        }
                        name='status'
                        color='primary'
                      />
                    </TableCell>
                    <TableCell>
                      <Tooltip title='Edit Student Information'>
                        <Button onClick={() => handleOpen(student.id)}>
                          <Edit sx={{ color: 'black' }} />
                        </Button>
                      </Tooltip>
                      <Tooltip title='Delete Student'>
                        <Button
                          onClick={() => {
                            setDeleteModal(true);
                            setStudentIndexDelete(student.id);
                          }}
                        >
                          <Delete sx={{ color: 'red' }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : isStudentsLoading || isSchoolsLoading ? (
                [...Array(5)].map((_, index) => <SkeletonRow key={index} />)
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align='center'>
                    No students yet. Add your first student above.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              width: '600px',
              backgroundColor: 'white',
              padding: '20px',
              margin: '50px auto',
              borderRadius: '8px',
            }}
          >
            <Typography variant='h6' gutterBottom>
              {editIndex !== null ? 'Edit Student' : 'Add New Student'}
            </Typography>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant='fullWidth'
            >
              <Tab label='Basic Information' />
            </Tabs>
            <Box
              sx={{ marginTop: '20px', maxHeight: '550px', overflow: 'auto' }}
            >
              {activeTab === 0 && (
                <Box>
                  <TextField
                    fullWidth
                    label='Name'
                    name='name'
                    value={studentData.name}
                    onChange={handleInputChange}
                    variant='outlined'
                    sx={{ marginBottom: '10px' }}
                  />
                  <TextField
                    fullWidth
                    label='Email'
                    name='email'
                    value={studentData.email}
                    onChange={handleInputChange}
                    variant='outlined'
                    sx={{ marginBottom: '10px' }}
                  />
                  <TextField
                    fullWidth
                    label='Parent Name'
                    name='parentName'
                    value={studentData.parentName}
                    onChange={handleInputChange}
                    variant='outlined'
                    sx={{ marginBottom: '10px' }}
                  />
                  <TextField
                    fullWidth
                    label='Admission Number'
                    name='admissionNumber'
                    value={studentData.admissionNumber}
                    onChange={handleInputChange}
                    variant='outlined'
                    sx={{ marginBottom: '10px' }}
                  />
                  <TextField
                    select
                    label='School'
                    name='schoolName'
                    value={studentData.schoolName}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  >
                    {schools.map((school) => (
                      <MenuItem key={school.id} value={school.schoolName}>
                        {school.schoolName}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    select
                    label='Teacher Name'
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    name='teacherId'
                    value={studentData.teacherId}
                    onChange={(e) => {
                      const selectedTeacher = filteredTeachers.find(
                        (teacher) => teacher.id === e.target.value
                      );
                      setStudentData((prevData) => ({
                        ...prevData,
                        teacherId: selectedTeacher?.id || '',
                        teacherName: selectedTeacher?.name || '',
                      }));
                    }}
                    disabled={
                      !studentData.schoolName || filteredTeachers.length === 0
                    }
                  >
                    {filteredTeachers.map((teacher) => (
                      <MenuItem key={teacher.id} value={teacher.id}>
                        {teacher.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    select
                    label='Grade Level'
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                  >
                    {grades.map((grade) => (
                      <MenuItem key={grade.id} value={grade.id}>
                        {grade.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    select
                    label='School Type'
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    value={schoolType}
                    onChange={(e) => setSchoolType(e.target.value)}
                  >
                    {schoolTypes.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              }}
            >
              <Button onClick={handleClose} sx={{ marginRight: '10px' }}>
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={handleAddOrEditStudent}
              >
                {editIndex !== null ? 'Update Changes' : 'Add Student'}
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
      <Dialog open={deleteModal} onClose={() => setStudentIndexDelete(0)}>
        <DialogContent>
          <Typography
            sx={{
              color: 'var(--clr-theme-gray)',
              fontFamily: 'Karla',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            Are you sure you want to delete this student?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='success'
            onClick={() => setDeleteModal(false)}
            sx={{
              backgroundColor: '#D3D3D3',
              textTransform: 'capitalize',
              fontFamily: 'Lato',
              fontWeight: 400,
              fontSize: '1.2rem',
              width: '100%',
            }}
          >
            No
          </Button>
          <Button
            variant='contained'
            color='success'
            onClick={() => {
              handleDeleteStudent(studentIndexDelete);
              setDeleteModal(false);
            }}
            sx={{
              backgroundColor: '#9291f0',
              textTransform: 'capitalize',
              fontFamily: 'Lato',
              fontWeight: 400,
              fontSize: '1.2rem',
              width: '100%',
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </MainLayout>
  );
};

export default StudentsPage;
