import React from 'react';
import { MedicalInformation } from '@mui/icons-material';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  Typography,
  Skeleton,
} from '@mui/material';
import './PatientOverview.css';

const PatientDiagnosis = ({ currentPatientData, loading }) => {
  const loadingList = Array(5)
    .fill(null)
    .map((_, index) => (
      <ListItem key={index}>
        <ListItemIcon>
          <MedicalInformation sx={{ color: 'var(--clr-theme-gray-dark)' }} />
        </ListItemIcon>
        <Skeleton
          variant='rectangular'
          width='85%'
          height={15}
          animation='pulse'
          sx={{
            borderRadius: '4px',
          }}
        />
      </ListItem>
    ));

  if (loading) {
    return loadingList;
  }

  return (
    <Card
      className='fullWidth fullHeight flexBox flexColumn patientDiagnosis'
      sx={{ justifyContent: 'space-between', boxShadow: 'none' }}
    >
      <CardContent>
        {loading ? (
          <Box className='fullHeight flexBox flexCenter'>
            <List>{loadingList}</List>
          </Box>
        ) : currentPatientData?.data.length <= 0 ? (
          <Box className='fullHeight flexBox flexCenter'>
            <Typography
              gutterBottom
              sx={{
                fontFamily: 'Lato',
                fontSize: '1.1rem',
              }}
            >
              No Diagnosis
            </Typography>
          </Box>
        ) : (
          <List>
            {currentPatientData?.data.map((item, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <MedicalInformation
                    sx={{ color: 'var(--clr-theme-gray-dark)' }}
                  />
                </ListItemIcon>
                <ListItemText primary={item?.treatment_name} />
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

export default PatientDiagnosis;
