import React, { useContext, useState } from 'react'
import AuthContext from '../../contexts/AuthContext';
import UpdateTreatments from '../../components/UpdateTreatments';
import { Box, Button, Card, CardActions, List, ListItem, ListItemIcon, Skeleton, Tab, Tabs, Typography } from '@mui/material';
import { Medication, Psychology } from '@mui/icons-material';
import { ACM } from '../../constants/UserRoles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const StudentMeds = (student, currentStudentData, loading, refetchCurrentStudentData) => {
  const {
    setOpenRightPanel,
    setPanelContent,
    typeOfUser,
  } = useContext(AuthContext);

  const EditMedsAndTherapy = () => {
    setOpenRightPanel(true);
    if (ACM[typeOfUser]?.isTeacher || ACM[typeOfUser]?.isStudent) {
      setPanelContent(
        <UpdateTreatments
          studentID={student?.student?.id}
          refetchCurrentStudentData={refetchCurrentStudentData}
        />
      );
    }
    // LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTSLIST.UPDATETREATMENTINFORMATION, {
    //   patient_id: patient?.id,
    // });
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const loadingList = Array(3)
    .fill(null)
    .map((_, index) => (
      <ListItem key={index}>
        <ListItemIcon>
          <Medication sx={{ color: 'var(--clr-theme-gray-dark)' }} />
        </ListItemIcon>
        <Skeleton
          variant='rectangular'
          width='85%'
          height={15}
          animation='pulse'
          sx={{
            borderRadius: '4px',
          }}
        />
      </ListItem>
    ));

    const userList = Array.isArray(student?.currentStudentData?.data)
    ? student.currentStudentData.data
    : [];

  return (
    <Card
    className='fullWidth fullHeight flexBox flexColumn '
    sx={{
      alignItems: 'center',
      justifyContent: 'space-between',
      boxShadow: 'none',
      position: 'relative',
      height: '100%',
    }}
  >
    <Box sx={{ width: '100%', position: 'relative', height: '100%' }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='Meds and Therapies Tabs'
        >
          <Tab label='Meds' {...a11yProps(0)} />
          <Tab label='Therapies' {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '48px',
          bottom: '56px',
          left: 0,
          right: 0,
          overflowY: 'auto',
        }}
      >
        <TabPanel value={value} index={0}>
          <List disablePadding sx={{ width: '100%' }}>
            <ListItem
              className='flexBox flexColumn'
              sx={{
                gap: 2,
                alignItems: 'flex-start',
              }}
            >
              {loading
                ? loadingList
                : userList.length > 0
                ? userList.map((item, i) =>
                    item?.meds?.map((med, j) => (
                      <Box
                        key={j}
                        className='flexBox'
                        sx={{ justifyContent: 'center' }}
                      >
                        <ListItemIcon>
                          <Medication
                            sx={{ color: 'var(--clr-theme-gray-dark)' }}
                          />
                        </ListItemIcon>
                        <Typography
                          gutterBottom
                          sx={{
                            textAlign: 'center',
                            fontFamily: 'Karla',
                            color: 'var(--clr-theme-gray)',
                          }}
                        >
                          {med?.drug_name}
                        </Typography>
                      </Box>
                    ))
                  )
                : 'No Medicine'}
            </ListItem>
          </List>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <List disablePadding sx={{ width: '100%' }}>
            <ListItem
              className='flexBox flexColumn'
              sx={{ gap: 2, alignItems: 'flex-start' }}
            >
              {loading
                ? loadingList
                : userList.length > 0
                ? userList.map((item, i) =>
                    item?.therapies?.map((therapy, j) => (
                      <Box key={j} className='flexBox flexCenter'>
                        <ListItemIcon>
                          <Psychology
                            sx={{ color: 'var(--clr-theme-gray-dark)' }}
                          />
                        </ListItemIcon>
                        <Typography
                          gutterBottom
                          sx={{
                            textAlign: 'center',
                            fontFamily: 'Karla',
                            color: 'var(--clr-theme-gray)',
                          }}
                        >
                          {therapy?.therapy_name || '-'}
                        </Typography>
                      </Box>
                    ))
                  )
                : 'No Therapy'}
            </ListItem>
          </List>
        </TabPanel>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          backgroundColor: 'white',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CardActions>
          <Button
            variant='contained'
            onClick={EditMedsAndTherapy}
            sx={{ backgroundColor: 'var(--clr-theme-purple) !important' }}
          >
            Update treatment methods
          </Button>
        </CardActions>
      </Box>
    </Box>
  </Card>
  )
} 

export default StudentMeds