import { Card, CardContent, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import MainLayout from '../../hoc/MainLayout';
import AuthContext from '../../contexts/AuthContext';
import { fetchingTeachersAndStudentsListForSchoolAdmin } from '../../api';
import { ACMContext } from '../../contexts/ACMProvider';

const SchoolAdminHome = () => {
  const { ACM } = useContext(ACMContext);
  const [fetchedData, setFetchedData] = useState([]);
  const { user, typeOfUser } = useContext(AuthContext);

  const getSchoolAdminData = async () => {
    if (ACM[typeOfUser]?.isSchoolAdmin) {
      try {
        const fetchedSchoolAdminData =
          await fetchingTeachersAndStudentsListForSchoolAdmin(
            user?.uid,
            'SCHOOLADMIN'
          );
        setFetchedData(fetchedSchoolAdminData);
      } catch (err) {
        console.log('Error fetching schoolAdmin data:', err);
      }
    }
  };

  useEffect(() => {
    if (ACM[typeOfUser]?.isSchoolAdmin) {
      getSchoolAdminData();
    }
  }, [user, typeOfUser]);

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant='h6'>Enrolled Students</Typography>
              <Typography variant='h4'>
                {fetchedData?.studentsList?.length}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant='h6'>Active Teachers</Typography>
              <Typography variant='h4'>
                {fetchedData?.teachersList?.length}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default SchoolAdminHome;
