import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ContactPage, Home } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import GroupsIcon from "@mui/icons-material/Groups";

import HomePage from "./HomePage";
import Contact from "./Contact";
import OurTeam from "./OurTeam";
import OurStory from "./OurStory";
import LogoWhite from "../../assets/images/LogoWhite.png";
import NewLogo from "../../assets/images/WhiteLogo.png";
import "./UpdatedUI.css";
import MenuContext from "../../contexts/MenuContext";
import { LogAnalyticsEvent } from "../../utils/firebase/firebase";
import { ANALYTICS_KEYS } from "../../constants/AnalyticKeys";
const MainPage = () => {
  const scrolling = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [activeButton, setActiveButton] = React.useState("Home");
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [colorChange, setColorChange] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(
    <HomePage setScrollPosition={setScrollPosition} scrolling={scrolling} />
  );

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const buttonAnalyticsMapping = {
    home: ANALYTICS_KEYS.LANDINGPAGE.VISITSONHOME,
    about: ANALYTICS_KEYS.LANDINGPAGE.VISITSONABOUT,
    teams: ANALYTICS_KEYS.LANDINGPAGE.VISITSONTEAMS,
    blog: ANALYTICS_KEYS.LANDINGPAGE.VISITSONBLOG,
    contact: ANALYTICS_KEYS.LANDINGPAGE.VISITSONCONTACT,
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    const eventKey = buttonAnalyticsMapping[buttonName];
    if (eventKey) {
      LogAnalyticsEvent(eventKey, {
        buttonName: buttonName,
      });
    } else {
      console.error(`Invalid button name: ${buttonName}`);
    }
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      PaperProps={{
        elevation: 0,
        className: "header-menu-popover",
        sx: {
          mt: 7,
        },
      }}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={() =>
          setCurrentPage(
            <HomePage
              setScrollPosition={setScrollPosition}
              scrolling={scrolling}
            />
          )
        }
      >
        <ListItemIcon>
          <Home fontSize="small" sx={{ color: "var(--clr-theme-white)" }} />
        </ListItemIcon>
        <ListItemText>Home</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => setCurrentPage(<OurStory />)}>
        <ListItemIcon>
          <InfoIcon fontSize="small" sx={{ color: "var(--clr-theme-white)" }} />
        </ListItemIcon>
        <ListItemText>About</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => setCurrentPage(<OurTeam />)}>
        <ListItemIcon>
          <GroupsIcon
            fontSize="small"
            sx={{ color: "var(--clr-theme-white)" }}
          />
        </ListItemIcon>
        <ListItemText>Teams</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => window.open('http://blog.mindly.care/','_blank')}>
        <ListItemIcon>
          <GroupsIcon
            fontSize="small"
            sx={{ color: "var(--clr-theme-white)" }}
          />
        </ListItemIcon>
        <ListItemText>Blog</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => setCurrentPage(<Contact />)}>
        <ListItemIcon>
          <ContactPage
            fontSize="small"
            sx={{ color: "var(--clr-theme-white)" }}
          />
        </ListItemIcon>
        <ListItemText>Contact</ListItemText>
      </MenuItem>
    </Menu>
  );
  React.useEffect(() => {
    if (
      scrollPosition + window.innerWidth * window.innerHeight >
      1193 + window.innerWidth * window.innerHeight
    ) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  }, [scrollPosition]);

  return (
    <MenuContext.Provider
      value={{ currentPage, setCurrentPage, setActiveButton, activeButton }}
    >
      <Box className="updatedLandingPage flexBox flexColumn">
        <Box>
          <AppBar
            position="static"
            className={colorChange ? "mainAppbar changeColor" : "mainAppbar"}
          >
            <Toolbar>
              <ListItemAvatar
                sx={{
                  display: { xs: "flex", md: "flex" },
                  mx: 1,
                  cursor: "pointer",
                }}
              >
                {colorChange ? (
                  <img src={NewLogo} height="50px" />
                ) : (
                  <img src={LogoWhite} height="50px" />
                )}
              </ListItemAvatar>
              <Typography
                className="brand"
                variant="h6"
                noWrap
                sx={{
                  // display: { xs: "none", md: "flex" },
                  letterSpacing: ".1rem",
                  color: colorChange ? "var(--clr-theme-purple)" : "inherit",
                  fontSize: "20px !important",
                  textDecoration: "none",
                }}
              >
                Mindly
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "none", md: "flex", gap: 25 } }}>
                <Button
                  variant="text"
                  color={colorChange ? "secondary" : "inherit"}
                  sx={{
                    color: colorChange ? "var(--clr-theme-purple)" : "inherit",
                  }}
                  onClick={() => {
                    setCurrentPage(
                      <HomePage
                        setScrollPosition={setScrollPosition}
                        scrolling={scrolling}
                      />
                    );
                    handleButtonClick("Home");
                    handleMenuClose();
                  }}
                  className={activeButton === "Home" ? "active" : "notActive"}
                >
                  Home
                </Button>
                <Button
                  variant="text"
                  color={colorChange ? "secondary" : "inherit"}
                  sx={{
                    color: colorChange ? "var(--clr-theme-purple)" : "inherit",
                  }}
                  onClick={() => {
                    setCurrentPage(<OurStory />);
                    handleButtonClick("about");
                    handleMenuClose();
                  }}
                  className={activeButton === "about" ? "active" : "notActive"}
                >
                  About
                </Button>
                <Button
                  variant="text"
                  color={colorChange ? "secondary" : "inherit"}
                  sx={{
                    color: colorChange ? "var(--clr-theme-purple)" : "inherit",
                  }}
                  onClick={() =>{
                    window.open('http://blog.mindly.care/','_blank')
                    handleButtonClick("blog");
                  }
                 }
                  className={activeButton === "blog" ? "active" : "notActive"}
                >
                  Blog
                </Button>
                <Button
                  variant="text"
                  color={colorChange ? "secondary" : "inherit"}
                  sx={{
                    color: colorChange ? "var(--clr-theme-purple)" : "inherit",
                  }}
                  onClick={() => {
                    setCurrentPage(<OurTeam />);
                    handleButtonClick("team");
                    handleMenuClose();
                  }}
                  className={activeButton === "team" ? "active" : "notActive"}
                >
                  Team
                </Button>
                <Button
                  variant="text"
                  color={colorChange ? "secondary" : "inherit"}
                  sx={{
                    color: colorChange ? "var(--clr-theme-purple)" : "inherit",
                  }}
                  onClick={() => {
                    setCurrentPage(<Contact />);
                    handleButtonClick("Contact");
                    handleMenuClose();
                  }}
                  className={
                    activeButton === "Contact" ? "active" : "notActive"
                  }
                >
                  Contact
                </Button>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  sx={{
                    color: colorChange ? "var(--clr-theme-purple)" : "inherit",
                  }}
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          {renderMobileMenu}
        </Box>
        {currentPage}
      </Box>
    </MenuContext.Provider>
  );
};

export default MainPage;
