import {
  Typography,
  Box,
  Card,
  CardContent,
  TextField,
  Slider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Button,
  CardActions,
  CircularProgress,
} from "@mui/material";
import { Delete, Medication, Psychology } from "@mui/icons-material";
import React, { useState } from "react";

const EditVideoFeedBack = ({
  data,
  medsAndTherapies,
  isCustomSymptom,
  setIsCustomSymptom,
  symptoms,
  onSave,
  onCancel,
  loading,
}) => {
  const [symptomData, setSymptomData] = useState(data);
  const [value, setValue] = React.useState(symptomData?.rating || 0);
  const [hover, setHover] = React.useState(-1);
  console.log(" data of prop :: ", symptomData);
  const marks = [
    { value: 0, label: "Low" },
    { value: 1, label: "Mild" },
    { value: 2, label: "Moderate" },
    { value: 3, label: "Severe" },
  ];

  const updateTreatments = (dataList, type, treatmentID) => {
    console.log(" on change :: ", dataList, type, treatmentID);
    const myTreatments = { ...symptomData };
    if (type === "Therapy") {
      myTreatments.therapies = dataList;
    } else if (type === "Meds") {
      myTreatments.meds = dataList;
    } else if (type === "Symptom") {
      myTreatments.symptom = dataList;
    } else if (type === "Diagnosis") {
      myTreatments.diagnosis = dataList;
    } else {
      myTreatments.rating = dataList;
    }

    setSymptomData({ ...myTreatments });
  };


  return (
    <Card
      sx={{
        background: "white",
        color: "white",
        p: 3,
        pt: 4,
        pb: 4,
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <CardContent className="flexBox flexColumn" sx={{ gap: 2 }}>
        {/* Text boxes for editing */}

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Select diagnosis
          </InputLabel>
          <Select
            label="Select diagnosis"
            // value={treatment.diagnosis}
            value={symptomData.diagnosis}
            disabled={loading}
            onChange={(e) => {
              updateTreatments(e.target.value, "Diagnosis", symptomData.id);
            }}
          >
            {medsAndTherapies?.treatment_name?.length > 0 &&
              medsAndTherapies?.treatment_name?.map((item, i) => {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select Symptom</InputLabel>
          <Select
            label="Select symptom"
            value={isCustomSymptom ? "other" : symptomData?.symptom}
            disabled={loading}
            onChange={(e) => {
              if (e.target.value !== "other") {
                updateTreatments(e.target.value, "Symptom", symptomData.id);
                setIsCustomSymptom(false);
              } else {
                setIsCustomSymptom(true);
              }
            }}
          >
            {symptoms?.length > 0 &&
              symptoms?.map((item, i) => {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            <MenuItem value="other">Other</MenuItem>
          </Select>
          {isCustomSymptom ? (
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              // label={`Add Symptom type *${index + 1}`}
              label={`Add Symptom `}
              // value={treatment.symptom}
              onChange={(e) => {
                updateTreatments(e.target.value, "Symptom", symptomData.id);
              }}
            />
          ) : null}
        </FormControl>

        <DynamicList
          label={"Therapy"}
          list={[...symptomData.therapies]}
          treatmentID={symptomData.id}
          icon={<Psychology color="success" />}
          updateTreatments={updateTreatments}
          medAndTherapies={medsAndTherapies}
          loading={loading}
        />
        <DynamicList
          label={"Meds"}
          list={[...symptomData.meds]}
          treatmentID={symptomData.id}
          icon={<Medication color="success" />}
          updateTreatments={updateTreatments}
          medAndTherapies={medsAndTherapies}
          loading={loading}
        />

        <Slider
          className="custom-slider"
          sx={{
            height: "40%",
            color: "var(--clr-theme-purple)",
            fontSize: "0.678rem",
          }}
          size="large"
          valueLabelDisplay="auto"
          step={1}
          marks={marks}
          min={0}
          max={3}
          value={symptomData?.rating}
          onChange={(event, newValue) => {
            setHover(newValue);
            setValue(newValue);
            updateTreatments(newValue, "rating", symptomData.id);
          }}
          onChangeCommitted={(event, newHover) => {
            setHover(newHover);
          }}
        />
        {value !== null && (
          <Typography
            variant="subtitle2"
            sx={{
              color: "var(--clr-theme-gray-dark)",
              fontFamily: "Lato",
              pt: 1,
            }}
            gutterBottom
          >
            Selected Rating: {hover !== -1 ? hover : value}
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <Box className="flexBox flexCenter fullWidth" sx={{ gap: 2 }}>
          <Button
            fullWidth
            sx={{
              border: "1px solid var(--clr-theme-purple) !important",
              textTransform: "capitalize",
              color: "var(--clr-theme-purple)",
            }}
            variant="outlined"
            color="primary"
            onClick={onCancel}
            disabled={loading}
          >
            Close
          </Button>
          <Button
            fullWidth
            variant="contained"
            disabled={loading}
            onClick={() => onSave(symptomData)}
            sx={{
              textTransform: "capitalize",
              backgroundColor: "var(--clr-theme-purple) !important",
              "&:disabled": {
                color: "var(--clr-theme-white) !important",
                backgroundColor: "rgb(211, 211, 211) !important",
              },
            }}
          >
            Update
          </Button>
        </Box>
      </CardActions>
      {loading && (
        <CircularProgress
          size={"4rem"}
          sx={{
            position: "absolute",
            top: "50%",
            left: "45%",
            color: "var(--clr-theme-purple)",
          }}
        />
      )}
    </Card>
  );
};

export default EditVideoFeedBack;

const DynamicList = ({
  label,
  list,
  icon,
  updateTreatments,
  treatmentID,
  medAndTherapies,
  loading,
}) => {
  const [currentValue, setCurrentValue] = useState("");
  const [myList, setMyList] = useState(list);

  const changeHandler = (e) => {
    setCurrentValue(e.target.value);
    setMyList([...myList, e.target.value]);
    setCurrentValue("");
    updateTreatments([...myList, e.target.value], label, treatmentID);
  };

  const addListItem = () => {
    setMyList([...myList, currentValue]);
    setCurrentValue("");
    updateTreatments([...myList, currentValue], label, treatmentID);
  };

  const removeItem = (item) => {
    setMyList([...myList.filter((x) => x !== item)]);
    updateTreatments([...myList.filter((x) => x !== item)], label, treatmentID);
  };

  return (
    <>
      <List subheader={label} sx={{ color: "black", textAlign: "start" }}>
        {myList
          .filter(
            (item) =>
              (label === "Therapy" && item.therapy_name?.trim()) ||
              (label !== "Therapy" && item.drug_name?.trim())
          )
          .map((item, idx) => {
            return (
              <ListItem
                key={idx}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => removeItem(item)}
                  >
                    <Delete color="error" />
                  </IconButton>
                }
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText sx={{ color: "black" }}>
                  {label === "Therapy" ? item.therapy_name : item.drug_name}
                </ListItemText>
              </ListItem>
            );
          })}
      </List>
      <Box className="flexBox">
        <FormControl variant="outlined" sx={{ width: "100%", minWidth: 120 }}>
          {label === "Therapy" ? (
            <>
              <InputLabel id="demo-simple-select-label">
                Select Therapy
              </InputLabel>
              <Select
                onChange={changeHandler}
                value={currentValue}
                label="Select Therapy"
                disabled={loading}
              >
                {medAndTherapies?.therapies?.length > 0 &&
                  medAndTherapies?.therapies?.map((item, i) => {
                    return (
                      <MenuItem key={i} value={item}>
                        {item?.therapy_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </>
          ) : (
            <>
              <InputLabel id="demo-simple-select-label">
                Select Medication
              </InputLabel>
              <Select
                onChange={changeHandler}
                value={currentValue}
                label="Select Medication"
                disabled={loading}
              >
                {medAndTherapies?.meds?.length > 0 &&
                  medAndTherapies?.meds?.map((item, i) => {
                    return (
                      <MenuItem key={i} value={item}>
                        {item.drug_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </>
          )}
        </FormControl>
      </Box>
    </>
  );
};