import React, { useState, useEffect } from 'react';
import MainLayout from '../../hoc/MainLayout';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import './Calendar.css';
import {
  getCalendarEvents,
  getSignedInUserEmail,
  initClient,
  signInToGoogle,
} from '../../utils/calendar';
import { gapi } from 'gapi-script';

import AddEvent from './addEvent';

import { Close, DuoOutlined, Google } from '@mui/icons-material';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const Appointments = () => {
  const [addEventOpen, setAddEventOpen] = useState(false);
  const [signedin, setSignedIn] = useState(false);
  const [googleAuthedEmail, setgoogleAuthedEmail] = useState(null);
  const [events, setEvents] = useState([]);
  const [todayEvents, setTodayEvents] = useState([]);
  const handleClose = () => {
    setAddEventOpen(false);
  };
  const handleOpen = () => {
    setAddEventOpen(true);
  };

  const getGoogleAuthorizedEmail = async () => {
    let email = await getSignedInUserEmail(gapi);
    if (email) {
      setSignedIn(true);
      setgoogleAuthedEmail(email);
      getCalendarEvents(gapi, (res) => {
        setEvents(res?.items);
        setTodayEvents(
          res?.items.filter((item) => {
            return (
              new Date(item?.start?.dateTime).getDate() ===
                new Date().getDate() &&
              new Date(item?.start?.dateTime).getMonth() ===
                new Date().getMonth() &&
              new Date(item?.start?.dateTime).getFullYear() ===
                new Date().getFullYear()
            );
          })
        );
      });
    }
  };
  const getAuthToGoogle = async () => {
    let successfull = await signInToGoogle(gapi);
    if (successfull) {
      getGoogleAuthorizedEmail();
    }
  };
  useEffect(() => {
    initClient(gapi, (success) => {
      if (success) {
        getGoogleAuthorizedEmail();
      }
    });
  }, []);

  return (
    <MainLayout>
      {signedin ? (
        events?.length > 0 && googleAuthedEmail ? (
          <div className='calendar-box'>
            <Box className='edit-events'>
              <List
                subheader="Today's Google Events"
                sx={{
                  fontFamily: 'Lato',
                  fontWeight: 700,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <ListItem>
                  <Button
                    fullWidth
                    variant='contained'
                    // onClick={handleOpen}
                    onClick={() => {
                      window.open(
                        'https://calendar.google.com/calendar/u/0/r/create'
                      );
                    }}
                    sx={{ backgroundColor: 'black' }}
                  >
                    Add Event
                  </Button>
                </ListItem>
                <div className='appointments-list'>
                  {todayEvents?.length > 0 ? (
                    todayEvents?.map((eventData, i) => {
                      return (
                        <Box
                          key={i}
                          sx={{
                            height: 65,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            backgroundColor:
                              i % 2 !== 0
                                ? 'var(--clr-theme-white)'
                                : 'var(--clr-theme-purple-50)',
                            paddingLeft: '5px',
                          }}
                        >
                          <Box
                            sx={{
                              height: '100%',
                              paddingRight: '8px',
                              width: '24%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Typography
                              color='secondary'
                              fontSize={25}
                              fontWeight='bold'
                            >
                              {new Date(eventData?.start?.dateTime).getHours() +
                                ':' +
                                (new Date(
                                  eventData?.start?.dateTime
                                ).getMinutes() < 10
                                  ? '0' +
                                    new Date(
                                      eventData?.start?.dateTime
                                    ).getMinutes()
                                  : new Date(
                                      eventData?.start?.dateTime
                                    ).getMinutes())}
                            </Typography>
                          </Box>
                          <Box className='events-TitleContent'>
                            <h6 className='long-and-truncated'>
                              {eventData?.summary}
                            </h6>
                          </Box>
                          <Box
                            sx={{
                              height: '100%',
                              width: '18%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {eventData?.hangoutLink ? (
                              <DuoOutlined color='success' fontSize='large' />
                            ) : (
                              <DuoOutlined color='primary' fontSize='large' />
                            )}
                          </Box>
                        </Box>
                      );
                    })
                  ) : (
                    <Typography>No Meetings Available</Typography>
                  )}
                </div>
              </List>
            </Box>
            <FullCalendar
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                listPlugin,
                bootstrap5Plugin,
              ]}
              themeSystem='bootstrap5'
              initialView='dayGridMonth'
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
              }}
              header
              navLinks={true}
              dayMaxEvents={true} // allow "more" link when too many events
              views={{
                listWeek: {
                  dayMaxEventRows: 2, // adjust to 6 only for timeGridWeek/timeGridDay
                },
              }}
              events={events?.map((event, i) => {
                return {
                  title: event.summary,
                  start: new Date(event.start.dateTime),
                  end: new Date(event.end.dateTime),
                  url: event.htmlLink,
                  extendedProps: { ...event },
                  className: event.conferenceData
                    ? event.hangoutLink
                      ? 'meet-link'
                      : 'zoom-link'
                    : 'no-link',
                };
              })}
              eventClick={(e) => {
                if (e.event.url) {
                  e.jsEvent.preventDefault();
                  window.open(e.event.url, '_blank');
                }
                return false;
              }}
            />
            <Dialog
              className='dialogAddevent'
              open={addEventOpen}
              onClose={handleClose}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 4,
                padding: '4px',
              }}
            >
              <DialogTitle
                id='alert-dialog-title'
                sx={{
                  width: '100%',
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'var(--clr-theme-purple-50)',
                }}
              >
                <IconButton
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <Close />
                </IconButton>
                <Box>
                  <Typography variant='h6'>Calender Event Creation</Typography>
                </Box>
              </DialogTitle>
              <DialogContent className='dialog-content'>
                <AddEvent
                  close={setAddEventOpen}
                  reloadEvents={getGoogleAuthorizedEmail}
                />
              </DialogContent>
            </Dialog>
          </div>
        ) : (
          <div>
            <div>
              Please check your google permissions for appointments access.
            </div>
            <Button onClick={getAuthToGoogle}>
              Connect to Google From Browser
            </Button>
          </div>
        )
      ) : (
        <Button onClick={getAuthToGoogle}>
          Connect to Google From Browser
        </Button>
      )}
      <Box sx={{ backgroundColor: 'var(--clr-theme-white)' }}></Box>
    </MainLayout>
  );
};

export default Appointments;
