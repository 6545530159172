export const findPermissionDifferences = (
  localPermissions,
  userPermissions
) => {
  const differences = {};
  Object.keys(userPermissions).forEach((key) => {
    if (userPermissions[key] !== localPermissions[key]) {
      differences[key] = userPermissions[key];
    }
  });
  return differences;
};
