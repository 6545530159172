import React, { useEffect, useState, useContext } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import {
  Box,
  Typography,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  ListItemAvatar,
  List,
  Avatar,
  ListItemText,
  ListItemIcon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  tooltipClasses,
  styled,
  Skeleton,
} from '@mui/material';
import {
  PlayCircle,
  Update,
  VisibilityOffOutlined,
  VisibilityOutlined,
  Delete,
} from '@mui/icons-material';
import { FaCalendarAlt } from 'react-icons/fa';
import PlayVideo from '../../../components/PlayVideo/index';
import AuthContext from '../../../contexts/AuthContext';
import { UIContext } from '../../../contexts/UIProvider';
import { FirestoreDB } from '../../../utils/firebase/firebase';
import './ViewAllVideos.css';
import usePatientsForDoctor from '../../../hooks/usePatientsForDoctor';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { ACMContext } from '../../../contexts/ACMProvider';
import useAllPatientsVideos from '../../../hooks/useAllPatientVideos';
import useOnePatientVideos from '../../../hooks/useOnePatientVideos';

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: 'bold',
    padding: '10px',
  },
}));

// Patient login, view all videos button from dashboard page
const ViewAllVideos = ({ typeOfUser }) => {
  const { ACM } = useContext(ACMContext);
  const { bottomDrawer, setBottomDrawer } = useContext(UIContext);

  const [selectedPatient, setSelectedPatient] = useState('All');
  const [videoList, setVideoList] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [filterOption, setFilterOption] = useState('All');

  const { user } = useContext(AuthContext);

  const handleSelectPatient = (value) => {
    setSelectedPatient(value);
  };

  const handleDateChange = (range) => {
    setDateRange(range || [null, null]);
  };

  const handleFilterChange = (event) => {
    setFilterOption(event.target.value);
  };

  const {
    data: patientData = [],
    isLoading: isLoading,
    error: errorPatientData,
  } = usePatientsForDoctor(user?.uid);

  const {
    data: currentPatientVideos,
    isLoading: loadingfetchOnePatientAllVideos,
    error: errorPatient,
  } = useOnePatientVideos(user?.uid);

  const {
    data: allPatientsAllVideos,
    isLoading: loadingfetchAllPatientsAllVideos,
    error: errorDoctor,
  } = useAllPatientsVideos(user?.uid);

  useEffect(() => {
    if (ACM[typeOfUser]?.isPatient || ACM[typeOfUser]?.isStudent) {
      setVideoList(
        currentPatientVideos?.data
          .map((v) => {
            return {
              ...v,
              user: { ...user, id: currentPatientVideos?.id },
            };
          })
          .sort((a, b) => {
            return (
              b?.rawVideoData.time?.seconds - a?.rawVideoData.time?.seconds
            );
          })
      );
    }
  }, [currentPatientVideos]);

  useEffect(() => {
    if (ACM[typeOfUser]?.isDoctor) {
      const groupedData = allPatientsAllVideos?.data.map((video) => {
        const curPatient = patientData?.find(
          (x) => x.patientId === video?.patientId
        );
        return {
          // user: { ...curPatient?.patientInfo, id: curPatient.patientId },
          videos: video?.videos.map((x) => {
            return {
              ...x,
              user: { ...curPatient?.patientInfo, id: curPatient.patientId },
            };
          }),
        };
      });

      setVideoList(
        groupedData
          ?.flatMap((x) => x.videos)
          ?.sort((a, b) => {
            return (
              b?.rawVideoData.time?.seconds - a?.rawVideoData.time?.seconds
            );
          })
      );
    }
  }, [allPatientsAllVideos]);

  const PopUp = ({ videoId, patientId, videoFileName }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <>
        <Tooltip title='delete'>
          <Button onClick={handleClickOpen}>
            <Delete sx={{ color: 'red' }} />
          </Button>
        </Tooltip>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title' sx={{ fontWeight: 'bold' }}>
            {`Are you sure you want to delete "${videoFileName}"?`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              This video will be deleted permanentely. You can"t undo this
              action.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='contained' className='btn'>
              Cancel
            </Button>
            <Button
              className='btn'
              onClick={() => {
                softDeleteVideo(videoId, patientId);
                handleClose();
                // getMyVideos();
              }}
              variant='outlined'
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const loadingList = Array(10)
    .fill(null)
    .map((_, index) => (
      <ListItem
        key={index}
        className='flexBox flexCenter listItemBox'
        style={{
          width: '88%',
          border: '1px solid white',
          boxShadow: '0 0 5px var(--clr-theme-purple)',
          padding: '16px',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          marginBottom: '6px',
        }}
      >
        <ListItemAvatar>
          <Skeleton
            variant='rectangular'
            width={40}
            height={40}
            sx={{
              borderRadius: '10%',
              backgroundColor: '#e0e0e0',
            }}
          />
        </ListItemAvatar>

        <Skeleton
          variant='rectangular'
          width='85%'
          height={15}
          animation='pulse'
          sx={{
            borderRadius: '4px',
          }}
        />
      </ListItem>
    ));

  const softDeleteVideo = async (videoId, patientId) => {
    try {
      const videoLogRef = doc(
        FirestoreDB,
        'videoLogs',
        patientId,
        'patientNotes',
        videoId
      );
      const res = await updateDoc(videoLogRef, { isDeleted: true });

      // await getMyVideos();
    } catch (e) {
      console.log(e);
    }
  };

  const handleVideoOpen = (videoDetails, patientID) => {
    if (!patientID) {
      patientID = videoDetails?.user?.id || videoDetails?.user?.uid;
    }
    setBottomDrawer({
      ...bottomDrawer,
      show: true,
      title: videoDetails?.rawVideoData?.fileName,
      patientName:
        videoDetails?.patient?.fullname || videoDetails?.user?.fullname,
      patientPhoto:
        videoDetails?.patient?.photoURL || videoDetails?.user?.photoURL,
      content: (
        <PlayVideo
          videoList={videoList?.map((video) => {
            if (video.user) {
              return video;
            } else {
              return {
                ...video,
                user: user,
              };
            }
          })}
          // .flatMap((x) => x.videos)}
          handleVideoOpen={handleVideoOpen}
          typeOfUser={typeOfUser}
          videoDetails={videoDetails}
          patientID={videoDetails?.user?.id}
        />
      ),
    });
  };

  const filterVideosByDateRange = (videoList, dateRange) => {
    if (!dateRange[0] || !dateRange[1]) return videoList;
    return videoList.filter(
      (video) =>
        video.rawVideoData.time.seconds * 1000 >= dateRange[0] &&
        video.rawVideoData.time.seconds * 1000 <= dateRange[1]
    );
  };

  const filterVideosByReviewStatus = (
    videoList,
    filterOption,
    selectedPatient
  ) => {
    const filteredByPatient =
      selectedPatient === 'All'
        ? videoList
        : videoList.filter((video) => video.user.id === selectedPatient);

    if (filterOption === 'All') return filteredByPatient;

    return filteredByPatient.filter(
      (video) =>
        (filterOption === 'Reviewed' && video?.docNotesDocId !== '') ||
        (filterOption === 'Pending' && video?.docNotesDocId === '')
    );
  };

  const filteredVideoList = filterVideosByReviewStatus(
    filterVideosByDateRange(videoList, dateRange),
    filterOption,
    selectedPatient
  );

  const capitalizeWords = (name) => {
    return name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const truncateName = (name, limit = 10) => {
    const capitalized = capitalizeWords(name);
    if (capitalized?.length > limit) {
      return `${capitalized.slice(0, limit)}...`;
    }
    return capitalized;
  };

  return (
    <>
      <Box
        className='flexBox fullWidth flexWrap video-filters'
        sx={{ justifyContent: 'flex-end', gap: 1, padding: 3 }}
      >
        {ACM[typeOfUser]?.isDoctor && (
          <FormControl sx={{ minWidth: '18%' }}>
            <InputLabel>Select Patient</InputLabel>
            <Select
              MenuProps={MenuProps}
              sx={{ width: 215 }}
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={selectedPatient}
              label='Select Patient'
              onChange={(event) => {
                handleSelectPatient(event.target.value);
              }}
            >
              <MenuItem value={'All'}>{'All Patients'}</MenuItem>
              {patientData?.map((patient) => {
                return (
                  <MenuItem key={patient.patientId} value={patient?.patientId}>
                    <BootstrapTooltip
                      title={
                        patient?.patientInfo?.parentName
                          ? `${patient.patientInfo.fullname}(${patient.patientInfo.parentName})`
                          : patient?.patientInfo.fullname
                      }
                    >
                      {patient?.patientInfo?.parentName
                        ? `${truncateName(
                            patient.patientInfo.fullname
                          )} (${truncateName(patient.patientInfo.parentName)})`
                        : `${truncateName(patient.patientInfo.fullname)}`}
                    </BootstrapTooltip>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        <FormControl sx={{ minWidth: '18%' }}>
          <InputLabel id='video-type-label'>Video Type</InputLabel>
          <Select
            MenuProps={MenuProps}
            sx={{ width: 215 }}
            labelId='video-type-label'
            id='demo-simple-select'
            value={filterOption}
            label='Video Type'
            onChange={handleFilterChange}
          >
            <MenuItem value={'All'}>All</MenuItem>
            <MenuItem value={'Pending'}>Pending</MenuItem>
            <MenuItem value={'Reviewed'}>Reviewed</MenuItem>
          </Select>
        </FormControl>
        <DateRangePicker
          value={dateRange}
          onChange={handleDateChange}
          format='MM/dd/yyyy'
          maxDetail='month'
          calendarIcon={<FaCalendarAlt />}
          dayPlaceholder={'dd'}
          monthPlaceholder={'mm'}
          yearPlaceholder={'yyyy'}
          rangeDivider={' to '}
        />
      </Box>

      {filteredVideoList?.length > 0 ? (
        <List
          className='allVideosBox'
          sx={{
            display: { sm: 'flex', md: 'flex', xs: 'flex' },
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <React.Fragment>
            {filteredVideoList.map((video, i) => (
              <React.Fragment key={i}>
                {selectedPatient === 'All' ||
                video.user.id === selectedPatient ? (
                  <ListItem
                    key={i}
                    className={`${
                      video?.docNotesDocId == '' ? 'notviewed' : 'viewed'
                    } flexBox flexCenter listItemBox`}
                    gap={3}
                    sx={{ flexWrap: 'wrap' }}
                  >
                    <ListItemAvatar>
                      <Avatar variant='rounded' src={video.user.photoURL} />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography className='username' variant='subtitle'>
                        {/* {video.user.fullname
                          ? `${video.user.fullname.split(/\s+/)
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                          .join(" ")}` : ''} */}
                        {video.rawVideoData.fileName
                          ? `${video.rawVideoData.fileName
                              .split(/\s+/)
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.slice(1).toLowerCase()
                              )
                              .join(' ')}`
                          : `${video.user.fullname
                              .split(/\s+/)
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.slice(1).toLowerCase()
                              )
                              .join(' ')}`}
                      </Typography>
                    </ListItemText>

                    <Box
                      className='flexBox flexCenter'
                      gap={3}
                      sx={{ flexWrap: 'wrap' }}
                    >
                      <ListItemText
                        sx={{
                          width: { sm: '60px', md: '90px', xs: '60px' },
                          display: { md: 'flex', sm: 'flex', sx: 'flex' },
                          flexDirection: {
                            md: 'row',
                            sm: 'column',
                            xs: 'column',
                          },
                          alignItems: 'center',
                          mt: 1,
                        }}
                      >
                        <Typography variant='caption'>
                          <Update sx={{ mr: 1 }} color='success' />
                          {video.rawVideoData.duration.toFixed(2)}
                        </Typography>
                      </ListItemText>
                      <ListItemText
                        sx={{
                          width: { sm: '60px', md: '90px', xs: '60px' },
                          display: { md: 'flex', sm: 'flex', sx: 'flex' },
                          flexDirection: 'column',
                        }}
                      >
                        <Typography variant='body2' className='caption'>
                          {new Date(
                            video?.rawVideoData.time?.seconds * 1000
                          ).toLocaleDateString()}
                        </Typography>
                      </ListItemText>
                      <ListItemIcon
                        sx={{
                          minWidth: { md: '85px', sm: '85px', xs: '50px' },
                          display: { md: 'flex', sm: 'flex', sx: 'flex' },
                          flexDirection: 'row',
                          alignItems: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {video?.docNotesDocId == '' ? (
                          <VisibilityOffOutlined
                            fontSize='small'
                            color='error'
                          />
                        ) : (
                          <VisibilityOutlined
                            fontSize='small'
                            color='success'
                          />
                        )}

                        {video?.docNotesDocId == '' && (
                          <>
                            <PopUp
                              videoFileName={
                                video?.fileName
                                  ? video?.fileName
                                  : video?.rawVideoData?.fileName
                              }
                              videoId={video?.videoId}
                              patientId={video?.user?.id}
                            />
                          </>
                        )}
                      </ListItemIcon>{' '}
                    </Box>
                    <Box
                      className='flexBox flexCenter'
                      sx={{ flexWrap: 'wrap' }}
                    >
                      <Button
                        className='playButton'
                        onClick={() => {
                          handleVideoOpen(video, video.user.id);
                        }}
                      >
                        <PlayCircle />
                      </Button>
                      <Button
                        className='detailsButton'
                        variant='outlined'
                        sx={{
                          border: '1px solid rgba(0, 0, 0, 0.20)',
                          borderRadius: '2.5rem',
                          color: '#000',
                          textTransform: 'capitalize',
                          ':hover': {
                            border: '1px solid rgba(0, 0, 0, 0.20)',
                            bgcolor: '#e2e3e5',
                          },
                        }}
                        onClick={() => {
                          handleVideoOpen(video, video.user.id);
                        }}
                      >
                        Details
                      </Button>
                    </Box>
                  </ListItem>
                ) : null}
              </React.Fragment>
            ))}
          </React.Fragment>
        </List>
      ) : (
        <Box>
          {(ACM[typeOfUser]?.isDoctor && loadingfetchAllPatientsAllVideos) ||
          (ACM[typeOfUser]?.isPatient && loadingfetchOnePatientAllVideos) ? (
            <Box className='flexBox flexCenter' sx={{ flexWrap: 'wrap' }}>
              {loadingList}
            </Box>
          ) : (
            <Box>
              <Typography
                variant='h4'
                color='secondary'
                sx={{
                  fontFamily: 'unset',
                  letterSpacing: '0em',
                  textAlign: 'center',
                }}
              >
                No new videos found
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default ViewAllVideos;
