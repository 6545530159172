import { useQuery } from '@tanstack/react-query';
import { fetchAllOrganizationAdmins } from '../api';

const useOrganizationData = (userId, typeOfUser) => {
  return useQuery({
    queryKey: ['organizationAdmins', userId, typeOfUser],
    queryFn: async () => {
      const fetchedData = await fetchAllOrganizationAdmins(userId, typeOfUser);
      return (
        fetchedData?.organizationAdmins
          ?.filter((admin) => admin !== null && admin !== undefined)
          .map((admin) => ({
            id: admin.id || '',
            name: admin.docData?.fullname || '',
            email: admin.docData?.email || '',
            schoolName: admin.docData?.schoolName || '',
            organizationName: admin.docData?.organizationName || '',
            organizationId: admin.docData?.organizationId || '',
            photoURL: admin.docData?.photoURL || null,
          })) || []
      );
    },
    staleTime: 60000, // 1 min
    cacheTime: 300000, // 5 min
  });
};

export default useOrganizationData;
