import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Divider,
  Box,
  TextField,
  List,
  ListItemButton,
  ListItemText,
  Button,
  ListItem,
  ListItemAvatar,
  CircularProgress,
  CardHeader,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AppBar,
  Toolbar,
  Stack,
  tooltipClasses,
  styled,
  Tooltip,
} from '@mui/material';
import { Send, Close } from '@mui/icons-material';
import AuthContext from '../../contexts/AuthContext';
import Reminders from '../../components/Reminders';
import { fetchDoctorAndPatientMessages, fetchGroupMessages } from '../../api';
import { stableSort } from '../../utils/helper';
import {
  Timestamp,
  addDoc,
  collection,
  onSnapshot,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { FirestoreDB } from '../../utils/firebase/firebase';
import { toast } from 'react-toastify';
import { MdEdit } from 'react-icons/md';
import { LogAnalyticsEvent } from '../../utils/firebase/firebase';
import { ANALYTICS_KEYS } from '../../constants/AnalyticKeys';
import { ACMContext } from '../../contexts/ACMProvider';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: 'bold',
    padding: '10px',
  },
}));

// here chat id will represents the group chat id or slected user id
const Conversation = ({
  chatId,
  chatName = '',
  chatPhotoURL,
  isGroupChat,
  onChatNameUpdate,
  navigateProfile = () => {},
  handleReminder = () => {},
  chatUserInfo,
  unreadCounts,
  unreadCountsForGroup,
}) => {
  const { ACM } = useContext(ACMContext);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState('');
  const [inputError, setInputError] = useState('');
  const [members, setMembers] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editedChatName, setEditedChatName] = useState(chatName);
  // const [originalChatName, setOriginalChatName] = useState(chatName);

  const scroll = useRef(null);

  const { user, typeOfUser, setOpenRightPanel, setPanelContent } =
    useContext(AuthContext);

  useEffect(() => {
    setEditedChatName(chatName);
  }, [chatName]);

  const handleOpenEditDialog = () => {
    setEditedChatName(chatName);

    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveEdit = async () => {
    try {
      const groupChatRef = doc(FirestoreDB, 'groupChats', chatId);
      await updateDoc(groupChatRef, { groupName: editedChatName });

      setEditedChatName(editedChatName);

      setOpenEditDialog(false);

      setOpenRightPanel(false);
      setPanelContent(null);
      if (typeof onChatNameUpdate === 'function') {
        onChatNameUpdate(editedChatName);
      }

      toast.success('Group name updated successfully');
    } catch (error) {
      console.error('Error updating group chat name:', error);
      toast.error('Failed to update group chat name. Please try again later.');
    }
  };
  const handleCancelEdit = () => {
    // Close the edit dialog
    setOpenEditDialog(false);
  };

  const fetchMembers = () => {
    const memeber = collection(FirestoreDB, 'groupChats', chatId, 'members');
    const unsubscribe = onSnapshot(memeber, (groupSnapshot) => {
      const memberList = groupSnapshot.docs.map((doc) => doc.data());
      setMembers(memberList);
    });

    return unsubscribe;
  };
  useEffect(() => {
    const unsubscribe = chatId && fetchMembers();
    return () => chatId && unsubscribe();
  }, [chatId]);

  const capitalizeWords = (name) => {
    return name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const truncateName = (name, limit = 10) => {
    const capitalized = capitalizeWords(name);
    if (capitalized.length > limit) {
      return `${capitalized.slice(0, limit)}...`;
    }
    return capitalized;
  };

  const viewGroup = () => {
    setOpenRightPanel(true);
    setPanelContent(
      <div
        className='fullWidth fullHeight flexBox flexColumn'
        style={{ gap: 4 }}
      >
        <Stack spacing={2} sx={{ backgroundColor: '#9291f033', p: 5 }}>
          <Avatar
            alt='Remy Sharp'
            src='https://cdn-icons-png.flaticon.com/512/6387/6387947.png'
            sx={{
              width: 156,
              height: 156,
              alignSelf: 'center',
              border: '5px solid white',
            }}
          />
          <Box display='flex' alignItems='center' justifyContent='center'>
            <Typography
              variant='h4'
              sx={{ fontFamily: 'Lato' }}
              textAlign='center'
            >
              {chatName
                .split(' ')
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(' ')}
            </Typography>
            <Button
              onClick={handleOpenEditDialog}
              sx={{ ml: 1, mt: 1, color: 'black' }}
            >
              <MdEdit size={24} />
            </Button>
          </Box>
        </Stack>
        <Typography variant='h6' sx={{ fontFamily: 'Lato' }}>
          Members
        </Typography>
        <Card className='fullWidth fullHeight' sx={{ overflowY: 'auto' }}>
          <List>
            {members?.map((member) => (
              <ListItem
                key={member?.id}
                sx={{ border: '1px solid lightgray', mt: 1 }}
                secondaryAction={
                  <Chip
                    label={member?.typeOfUser}
                    color='success'
                    variant='outlined'
                  />
                }
              >
                <ListItemButton>
                  <ListItemAvatar>
                    <Avatar
                      src={
                        member?.photoURL ||
                        'https://png.pngtree.com/png-clipart/20210915/ourmid/pngtree-user-avatar-placeholder-black-png-image_3918427.jpg'
                      }
                    />
                  </ListItemAvatar>
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    width='100%'
                  >
                    <BootstrapTooltip
                      title={
                        member?.parentName
                          ? `${member.name} (${member.parentName})`
                          : member?.name
                      }
                    >
                      <ListItemText
                        primary={
                          member?.parentName
                            ? `${truncateName(member.name)} (${truncateName(
                                member.parentName
                              )})`
                            : truncateName(member?.name)
                        }
                        sx={{ cursor: 'pointer' }} // Add pointer cursor for clarity
                      />
                    </BootstrapTooltip>
                  </Box>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Card>
      </div>
    );
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
    setInputError('');
  };

  const fetchMessages = async (showLoader = true) => {
    if (showLoader) {
      setLoading(true); // Set loading to true before fetching data
    }
    try {
      let fetchedMessages = [];
      if ((ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) && !isGroupChat) {
        fetchedMessages = await fetchDoctorAndPatientMessages(
          user?.uid,
          chatId,
          user?.uid
        );
      } else if ((ACM[typeOfUser]?.isPatient || ACM[typeOfUser]?.isStudent) && !isGroupChat) {
        fetchedMessages = await fetchDoctorAndPatientMessages(
          user?.uid,
          chatId,
          user?.uid
        );
      } else if (isGroupChat) {
        fetchedMessages = await fetchGroupMessages(user?.uid, chatId);
      }
      if (fetchedMessages?.length) {
        // Optimize sorting by using the stableSort function
        const sortedMessages = stableSort(fetchedMessages, (a, b) => {
          const dateA = b.createdDate?._seconds || b.createdDate?.seconds;
          const dateB = a.createdDate?._seconds || a.createdDate?.seconds;
          return dateB - dateA;
        });
        setMessages(sortedMessages);
        return;
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setLoading(false); // Set loading to false after data fetching (whether successful or not)
    }
  };

  useEffect(() => {
    if (scroll.current) {
      scroll.current.scrollTop = scroll.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    setMessages([]);
    fetchMessages();
  }, [chatId, unreadCounts, unreadCountsForGroup]);

  const sendMessage = async (e) => {
    e.preventDefault();

    if (!input.trim()) {
      setInputError('Message cannot be empty');
      return;
    }

    let notificationSource = '';
    let collectionPath = '';
    let message = {};
    let params = {};
    let groupId = ''; // Track group ID for analytics in group chats

    const sentMessageChat = ACM[typeOfUser]?.isDoctor
      ? ANALYTICS_KEYS.MESSAGE.MESSAGEBYDOCTOR
      : ANALYTICS_KEYS.MESSAGE.MESSAGEBYPATIENT;

    const sentMessageGroup = ACM[typeOfUser]?.isDoctor
      ? ANALYTICS_KEYS.MESSAGE.GROUPMESSAGEBYDOCTOR
      : ANALYTICS_KEYS.MESSAGE.GROUPMESSAGEBYPATIENT;

    // Reset the input field and error state
    setInput('');
    setInputError('');
    try {
      // if (!isGroupChat) {
      //   notificationSource = ACM[typeOfUser]?.isDoctor
      //     ? 'doctorMessage'
      //     : 'patientMessage';
      //   params = {
      //     title: ACM[typeOfUser]?.isDoctor
      //       ? 'Healthcare Professional has sent you a message'
      //       : 'Patient has sent you a message',
      //     description: input,
      //     notifyTo: ACM[typeOfUser]?.isDoctor
      //       ? chatUserInfo?.patientInfo?.email
      //       : chatUserInfo?.email,
      //     sentBy: user?.email,
      //     userName: user?.displayName,
      //     userPhotoURL: user?.photoURL,
      //   };
      //   collectionPath = ACM[typeOfUser]?.isDoctor
      //     ? `notifications/${chatId}/messages`
      //     : `notifications/${chatUserInfo?.doctorId}/messages`;

      //   message = {
      //     infoMessage: btoa(JSON.stringify(params)),
      //     notificationSource,
      //     userId: user?.uid,
      //     isEncrypted: false,
      //     readStatus: false,
      //     createdDate: Timestamp.now(),
      //   };
      // }
      if (!isGroupChat) {
        if (ACM[typeOfUser]?.isDoctor) {
          // Doctor messages
          notificationSource = 'doctorMessage';
          params = {
            title: 'Healthcare Professional has sent you a message',
            description: input,
            notifyTo: chatUserInfo?.patientInfo?.email,
            sentBy: user?.email,
            userName: user?.displayName,
            userPhotoURL: user?.photoURL,
          };
          collectionPath = `notifications/${chatId}/messages`;
        } else if (ACM[typeOfUser]?.isTeacher) {
          // Teacher messages
          notificationSource = 'teacherMessage';
          params = {
            title: 'Teacher has sent you a message',
            description: input,
            notifyTo: chatUserInfo?.email, // Notify the student
            sentBy: user?.email,
            userName: user?.displayName,
            userPhotoURL: user?.photoURL,
          };
          collectionPath = `notifications/${chatId}/messages`;
        } else if (ACM[typeOfUser]?.isPatient) {
          // Patient messages
          notificationSource = 'patientMessage';
          params = {
            title: 'Patient has sent you a message',
            description: input,
            notifyTo: chatUserInfo?.doctorId
              ? chatUserInfo?.doctorInfo?.email // Notify the doctor if available
              : chatUserInfo?.email, // Generic fallback
            sentBy: user?.email,
            userName: user?.displayName,
            userPhotoURL: user?.photoURL,
          };
          collectionPath = `notifications/${
            chatUserInfo?.doctorId || chatId
          }/messages`;
        } else if (ACM[typeOfUser]?.isStudent) {
          // Patient messages
          notificationSource = 'studentMessage';
          params = {
            title: 'Student has sent you a message',
            description: input,
            notifyTo: chatUserInfo?.id
              ? chatUserInfo?.email // Notify the doctor if available
              : chatUserInfo?.email, // Generic fallback
            sentBy: user?.email,
            userName: user?.displayName,
            userPhotoURL: user?.photoURL,
          };
          collectionPath = `notifications/${chatId}/messages`;
          }

        // Create the message object
        message = {
          infoMessage: btoa(JSON.stringify(params)), // Base64 encode the params
          notificationSource,
          userId: user?.uid,
          isEncrypted: false,
          readStatus: false,
          createdDate: Timestamp.now(),
        };
       
      } else {
        notificationSource = 'groupMessage';
        params = {
          title: ACM[typeOfUser]?.isPatient
            ? 'Patient has sent you a message in group'
            : 'Healthcare Professional has sent you a message in group',
          description: input,
          sentBy: user?.email,
          userName: user?.fullname,
          userPhotoURL: user?.photoURL,
        };

        const readStatus = members
          ?.filter((member) => member.id !== user?.uid)
          .map((member) => ({
            id: member.id,
            status: false,
          }));

        collectionPath = `groupChats/${chatId}/groupMessages`;
        message = {
          infoMessage: btoa(JSON.stringify(params)),
          notificationSource,
          userId: user?.uid,
          isEncrypted: false,
          readStatus,
          userName: user?.displayName,
          createdDate: Timestamp.now(),
        };
        groupId = chatId; // Track group ID for analytics
      }

      // Show success toast optimistically
      toast.success('Message sent successfully', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      const res = await addDoc(
        collection(FirestoreDB, collectionPath),
        message
      );

      setMessages((prevMessages) => [
        ...prevMessages,
        { ...message, id: res.id, ...params },
      ]);

      await fetchMessages(false);

      // Log the analytics event for individual chats
      if (typeof LogAnalyticsEvent === 'function') {
        try {
          LogAnalyticsEvent(sentMessageChat, {
            notificationSource,
            isGroupChat,
            groupId: isGroupChat ? groupId : null,
            userId: user?.uid,
          });

          // Log the analytics event for group chats (if applicable)
          if (isGroupChat) {
            LogAnalyticsEvent(sentMessageGroup, {
              groupId,
              userId: user?.uid,
            });
          }
        } catch (err) {
          console.error('Error logging analytics event:', err);
        }
      } else {
        console.warn('LogAnalyticsEvent function is not defined.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message');
    }

    // Reset the input field and error state
    setInput('');
    setInputError('');
  };

  return (
    // <div>hi</div>
    <Card sx={{ height: '89.5vh' }}>
      <CardHeader
        avatar={
          <Avatar
            src={chatPhotoURL}
            alt='Chat Photo'
            onClick={() => navigateProfile(chatId)}
            sx={{
              '&:hover': {
                boxShadow: '0px 6px 10px  #9291f0',
              },
            }}
          />
        }
        title={chatName
          .split(' ')
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(' ')}
        subheader={
          ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher &&
          !isGroupChat && (
            <React.Fragment>
              <Typography
                sx={{
                  display: 'inline',
                  cursor: 'pointer',
                  color: 'text.primary',
                  '&:hover': {
                    color: '#9291f0',
                    textShadow: '0px 0px 2px #9291f0',
                  },
                }}
                component='span'
                variant='body2'
                onClick={() => navigateProfile(chatId)}
              >
                View profile
              </Typography>
            </React.Fragment>
          )
        }
        action={
          <>
            {ACM[typeOfUser]?.isDoctor && !isGroupChat ? (
              <>
                <Button
                  sx={{
                    backgroundColor: 'var(--clr-theme-purple)!important',
                    color: 'white',
                    textTransform: 'capitalize',
                    width: '240px',
                  }}
                  onClick={() => setOpen(true)}
                  // goToPatient
                >
                  Set Reminder
                </Button>
                <Dialog
                  onClose={() => setOpen(false)}
                  aria-labelledby='customized-dialog-title'
                  open={open}
                  maxWidth='lg'
                >
                  <AppBar
                    sx={{
                      position: 'relative',
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    }}
                  >
                    <Toolbar>
                      <Typography
                        sx={{
                          flex: 1,
                          color: 'black',
                          fontSize: '1.8rem',
                          fontFamily: 'Lato',
                        }}
                        variant='h6'
                        component='div'
                      >
                        Reminders
                      </Typography>
                      <Button
                        autoFocus
                        color='inherit'
                        onClick={() => setOpen(false)}
                      >
                        <Close sx={{ color: 'black' }} />
                      </Button>
                    </Toolbar>
                  </AppBar>
                  <DialogContent sx={{ width: '42vw' }}>
                    <Reminders currentPatient={chatId} />
                  </DialogContent>
                </Dialog>
              </>
            ) : (
              isGroupChat && (
                <Button
                  variant='outlined'
                  onClick={viewGroup}
                  sx={{ textTransform: 'capitalize' }}
                >
                  View Group
                </Button>
              )
            )}
          </>
        }
      />
      <Divider sx={{ border: '1px solid lightgray' }} />
      {loading ? (
        <CircularProgress
          sx={{
            position: 'absolute',
            left: '70%',
            top: '50%',

            color: 'var(--clr-theme-purple)',
          }}
        />
      ) : (
        <CardContent>
          <Box
            sx={{
              height: '79vh',
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }} ref={scroll}>
              {messages?.map((message, index) => {
                return (
                  <Chatconversation
                    key={index}
                    message={message}
                    typeOfUser={typeOfUser}
                    user={user}
                  />
                );
              })}
            </Box>
            <form onSubmit={sendMessage}>
              <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <TextField
                      size='small'
                      fullWidth
                      placeholder='Type a message...'
                      variant='outlined'
                      value={input}
                      onChange={handleInputChange}
                      error={!!inputError}
                      helperText={inputError}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      aria-label='send button'
                      type='submit'
                      variant='contained'
                      sx={{
                        backgroundColor: 'var(--clr-theme-purple) !important',
                      }}
                      endIcon={<Send />}
                    ></Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
        </CardContent>
      )}

      {/* Edit Dialog */}
      <Dialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        maxWidth='sm' // Change this value as needed, options are 'xs', 'sm', 'md', 'lg', 'xl'
        fullWidth={true}
        sx={{ zIndex: 10000 }}
      >
        <DialogTitle
          sx={{ textAlign: 'center', color: '#9291f0', fontWeight: 'bold' }}
          variant='h5'
        >
          Edit group name
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            sx={{ margin: '5px 0px' }}
            value={editedChatName}
            onChange={(e) => setEditedChatName(e.target.value)}
            label='Group name'
            variant='outlined'
          />
        </DialogContent>
        <DialogActions sx={{ paddingRight: 3 }}>
          <Button
            onClick={handleCancelEdit}
            style={{ color: '#9291f0', width: '20%' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveEdit}
            variant='contained'
            style={{ backgroundColor: '#9291f0', width: '20%' }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default Conversation;

const Chatconversation = ({ message, typeOfUser, user }) => {
  const { ACM } = useContext(ACMContext);
  if (!message.description) {
    return null;
  }

  // Determine if the message is from the doctor or the patient
  const isDoctorMessage = message?.notificationSource === 'doctorMessage';
  const isPatientMessage = message?.notificationSource === 'patientMessage';

  // Determine the alignment of the message content
  // const contentAlignment = isDoctorMessage ? "flex-end" : "flex-start";
  const isCurrentUserMessage =
    (ACM[typeOfUser]?.isDoctor && message.sentBy === user.email) ||
    (ACM[typeOfUser]?.isPatient && message.sentBy === user.email)|| 
    (ACM[typeOfUser]?.isTeacher && message.sentBy === user.email) ||
    (ACM[typeOfUser]?.isStudent && message.sentBy === user.email);

  // Determine the alignment of the message content
  const contentAlignment = isCurrentUserMessage ? 'flex-end' : 'flex-start';

  // Convert createdDate to a human-readable format
  const formattedDate = new Date(
    message?.createdDate?.seconds * 1000 ||
      message?.createdDate?._seconds * 1000
  ).toLocaleString();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isCurrentUserMessage ? 'row-reverse' : 'row',
        alignItems: contentAlignment,
        padding: '10px',

        marginBottom: 5,
      }}
    >
      <img
        src={
          message?.userPhotoURL ||
          'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
        }
        alt='User Avatar'
        style={{ width: '30px', height: '30px', borderRadius: '50%' }}
      />
      <div
        style={{
          marginLeft: '10px',
          width: '70%',
          backgroundColor: 'var(--clr-theme-purple-20)',
          padding: '10px',
          borderRadius: isDoctorMessage
            ? '20px 5px 20px 20px'
            : '20px 20px 20px 5px',
        }}
      >
        <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>
          {message?.userName
            ?.split(/\s+/)
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(' ') || 'NA'}
        </p>
        <p style={{ color: '#454545', margin: '0' }}>{message?.description}</p>
        <p
          style={{
            fontSize: '12px',
            color: '#999',
            margin: '0',
            marginTop: '5px',
          }}
        >
          {formattedDate}
        </p>
      </div>
    </div>
  );
};
