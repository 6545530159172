import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Chip,
  InputLabel,
  FormControl,
  Select,
  Grid,
  Radio,
} from '@mui/material';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import {
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  LineChart,
  Line,
  Label,
  Legend,
} from 'recharts';
import AuthContext from '../../contexts/AuthContext';
import {
  ALL_MONTHS,
  getDateByPastMonths,
  getPastMonthNames,
} from '../../utils/helper';

import { getAllVideosFeedbackData, getPatientVideos } from '../../api';
import './Statisticss.css';
import { ACMContext } from '../../contexts/ACMProvider';
import useAllPatientsVideos from '../../hooks/useAllPatientVideos';
import useOnePatientVideos from '../../hooks/useOnePatientVideos';

const DURATION_MONTHS = 6;
const colors = scaleOrdinal(schemeCategory10).range();

const getPath = (x, y, width, height) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${
    y + height / 3
  }
    ${x + width / 2}, ${y}
    C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
    x + width
  }, ${y + height}
    Z`;
};

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;
  return <path d={getPath(x, y, width, height)} stroke='none' fill={fill} />;
};

const SymptomRatingChart = ({ patientID, studentID }) => {
  const { user, typeOfUser } = useContext(AuthContext);
  const { ACM } = useContext(ACMContext);

  const [data, setData] = useState([]);
  const [symptomData, setSymptomData] = useState([]);
  const [showSymptomsDropdown, setShowSymptomsDropdown] = useState(false);
  const [selectedGraphType, setSelectedGraphType] = useState('rating');
  const [selectedDuration, setSelectedDuration] = useState('6months');
  const [symptomsList, setSymptomsList] = useState('');
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [totalVideos, setTotalVideos] = useState(0);
  const [showBehaviorsDropdown, setShowBehaviorsDropdown] = useState(false);
  const [behaviorsList, setBehaviorsList] = useState([]);
  const [selectedBehaviors, setSelectedBehaviors] = useState([]);
  const currentMonths = getPastMonthNames(DURATION_MONTHS);

  const totalVideosUploadedByPatients = async () => {
    let videosCount = 0;
    const promises = [];

    try {
      // Assuming your data structure is stored in some variable, replace it with your actual data variable
      const patientsData = allPatientsAllVideos?.data;
      patientsData?.forEach((patient) => {
        const videos = patient.videos || [];
        videosCount += videos.length;
      });

      await Promise.all(promises);

      setTotalVideos(videosCount);
    } catch (error) {
      console.error('Error counting videos:', error);
    }
  };

  const TimeFilter = ({ selectedDuration, onDurationChange }) => {
    return (
      <TextField
        select
        label='Select Time Duration'
        value={selectedDuration}
        onChange={(event) => onDurationChange(event.target.value)}
        sx={{ width: 250 }}
        size='small'
      >
        {(selectedGraphType === 'rating' ||
          selectedGraphType === 'symptom' ||
          selectedGraphType === 'videoUploads') && (
          <MenuItem value='6months'>6 Months</MenuItem>
        )}
        <MenuItem value='1month'>1 Month</MenuItem>
        <MenuItem value='1week'>1 Week</MenuItem>
      </TextField>
    );
  };

  const GraphTypeFilter = ({ selectedGraphType, onGraphTypeChange }) => {
    return (
      <TextField
        select
        label='Select Graph Type'
        value={selectedGraphType}
        onChange={(event) => onGraphTypeChange(event.target.value)}
        sx={{ width: 250 }}
        size='small'
      >
        <MenuItem value='rating'>Rating</MenuItem>
        <MenuItem value='symptom'>Symptom</MenuItem>
        {(ACM[typeOfUser]?.isPatient || ACM[typeOfUser]?.isStudent) && (
        <MenuItem value='videoUploads'>Average Videos</MenuItem>
        )}
        {/* {typeOfUser === "DOCTOR" && ( */}
        {(ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) && (
          <MenuItem value='frequency'>Frequency</MenuItem>
        )}
      </TextField>
    );
  };

  const SymptomsFilter = ({ selectedSymptoms, handleSymptomChange }) => {
    return (
      <FormControl sx={{ width: 250 }} size='small'>
        <InputLabel>Select Symptoms</InputLabel>
        <Select
          label='Select Symptoms'
          MenuProps={{
            PaperProps: { style: { maxHeight: 300 }, autoWidth: false },
          }}
          multiple
          value={selectedSymptoms}
          onChange={handleSymptomChange}
          renderValue={(selected) => (
            <div>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </div>
          )}
        >
          {symptomsList &&
            symptomsList?.map((symptom) => (
              <MenuItem key={symptom} value={symptom}>
                <Radio
                  checked={selectedSymptoms.includes(symptom)}
                  color='primary'
                />
                {symptom}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  };

  const BehavioursFilter = React.memo(
    ({ selectedBehaviors, handleSelectChange, behaviorsList }) => (
      <FormControl sx={{ width: 250 }} size='small'>
        <InputLabel>Select Behaviors</InputLabel>
        <Select
          label='Select Behaviors'
          MenuProps={{
            PaperProps: { style: { maxHeight: 300 }, autoWidth: false },
          }}
          multiple
          value={selectedBehaviors}
          onChange={handleSelectChange}
          renderValue={(selected) => (
            <div>
              {selected?.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </div>
          )}
        >
          {behaviorsList?.map((behavior) => (
            <MenuItem key={behavior} value={behavior}>
              <Radio
                checked={selectedBehaviors?.includes(behavior)}
                color='primary'
              />
              {behavior}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  );

  const getStatisticsByDate = async (id, duration) => {
    try {
      const feedbackData = await getAllVideosFeedbackData(id);

      if (feedbackData) {
        const ratingArray = [];
        const symptomsArray = [];
        const symptomsListArr = [];
        const symptomsMap = new Map();

        feedbackData?.forEach((item) => {
          const docId = item.docId;
          const time = item.docData.time?._seconds || 0;

          if (item.docData.treatments) {
            item?.docData?.treatments?.forEach((treatment) => {
              const currentTimeStamp = new Date(time * 1000);
              const timeDifference = Date.now() - currentTimeStamp.getTime();

              if (timeDifference <= duration) {
                ratingArray.push({
                  videoID: `${docId} V${ratingArray.length}`,
                  date: currentTimeStamp.toLocaleDateString(),
                  rating: treatment.rating,
                });

                const symptom = treatment.symptom;
                if (selectedSymptoms.includes(symptom)) {
                  symptomsArray.push({
                    videoID: `${docId} V${symptomsArray.length}`,
                    date: currentTimeStamp.toLocaleDateString(),
                    symptom: symptom,
                    rating: treatment.rating,
                  });
                }

                symptomsListArr.push(symptom);
              }
            });
          }
        });

        const result = [];
        ratingArray.reduce(function (res, value) {
          if (!res[value.date]) {
            res[value.date] = {
              date: value.date,
              rating: 0,
            };
            result.push(res[value.date]);
          }
          res[value.date].rating +=
            value.rating /
            ratingArray.filter((x) => x.date === value.date).length;
          return res;
        }, {});

        symptomsArray?.forEach((entry) => {
          const existingSymptom = symptomsMap.get(entry.date);

          if (existingSymptom) {
            existingSymptom[entry.symptom] = entry.rating;
          } else {
            symptomsMap.set(entry.date, { [entry.symptom]: entry.rating });
          }
        });

        const aggregatedSymptomData = Array.from(symptomsMap).map(
          ([date, symptoms]) => ({
            date: date,
            ...symptoms,
          })
        );

        setSymptomData(aggregatedSymptomData);
        setSymptomsList([...new Set(symptomsListArr)]);
        setData(result);
      }
    } catch (error) {
      console.error('Error fetching statistics by date:', error);
    }
  };

  useEffect(() => {
    const durationInMillis = getDurationInMillis(selectedDuration);

    // if (typeOfUser === "DOCTOR") {
    if (ACM[typeOfUser]?.isDoctor) {
      if (
        patientID &&
        (patientID === '1week' || selectedDuration === '1month')
      ) {
        // Filter data by selected symptoms
        const filteredData = symptomData.filter((entry) =>
          selectedSymptoms.includes(entry.symptom)
        );
        getStatisticsByDate(patientID, durationInMillis, filteredData);
      } else if (patientID && selectedDuration === '6months') {
        // Filter data by selected symptoms
        const filteredData = symptomData.filter((entry) =>
          selectedSymptoms.includes(entry.symptom)
        );
        get6monthStatistics(patientID, filteredData);
      }
    } else {
      if (ACM[typeOfUser]?.isTeacher) {
        if (
          studentID &&
          (studentID === '1week' || selectedDuration === '1month')
        ) {
          // Filter data by selected symptoms
          const filteredData = symptomData.filter((entry) =>
            selectedSymptoms.includes(entry.symptom)
          );
          getStatisticsByDate(studentID, durationInMillis, filteredData);
        } else if (studentID && selectedDuration === '6months') {
          // Filter data by selected symptoms
          const filteredData = symptomData.filter((entry) =>
            selectedSymptoms.includes(entry.symptom)
          );
          get6monthStatistics(studentID, filteredData);
        }
      } 
    }
  }, [typeOfUser, patientID, studentID, selectedDuration, user, selectedSymptoms]);

  const {
    data: allPatientsAllVideos,
    isLoading: loadingfetchAllPatientsAllVideos,
    error: errorDoctor,
  } = useAllPatientsVideos(user?.uid);

  useEffect(() => {
    // if (typeOfUser === "DOCTOR") {
    if (ACM[typeOfUser]?.isDoctor) {
      totalVideosUploadedByPatients();
    }
  }, [typeOfUser, allPatientsAllVideos]);

  const handleTimeFilterChange = (selectedDuration) => {
    setSelectedDuration(selectedDuration);
  };

  const handleGraphTypeChange = (selectedGraphType) => {
    setSelectedGraphType(selectedGraphType);
    if (selectedGraphType === 'symptom') {
      setShowSymptomsDropdown(true);
      setSelectedBehaviors([]);
    } else {
      setShowSymptomsDropdown(false);
      setSelectedSymptoms([]);
    }
    if (selectedGraphType === 'frequency') {
      setShowBehaviorsDropdown(true);
      setSelectedSymptoms([]);
    } else {
      setShowBehaviorsDropdown(false);
      setSelectedBehaviors([]);
    }
  };

  const handleSelectChange = (event) => {
    if (selectedGraphType === 'symptom') {
      setSelectedSymptoms(event.target.value);
    }
    if (selectedGraphType === 'frequency') {
      setSelectedBehaviors(event.target.value);
    }
  };

  const get6monthStatistics = async (id) => {
    try {
      const result = await getAllVideosFeedbackData(id);

      const ratingArray = [];
      result?.forEach((item) => {
        item?.docData?.treatments?.forEach((treatment) => {
          const currentTimeStamp = item.docData.time._seconds * 1000;
          if (currentTimeStamp > getDateByPastMonths(6)) {
            ratingArray.push({
              videoID: item.docId,
              month: ALL_MONTHS[new Date(currentTimeStamp).getMonth()],
              rating: treatment.rating,
            });
          }
        });
      });

      const resultData = ratingArray.reduce(function (res, value) {
        if (!res[value.month]) {
          res[value.month] = {
            month: value.month,
            rating: 0,
          };
        }
        res[value.month].rating +=
          value.rating /
          ratingArray.filter((x) => x.month === value.month).length;
        return res;
      }, {});

      const newData = currentMonths.map((x) => ({
        month: x,
        average_rating: Math.round(resultData[x]?.rating || 0),
      }));

      const isAllZero = newData.every((item) => item.average_rating === 0);

      const finalData = isAllZero ? null : newData;

      setData(finalData);
    } catch (error) {
      console.error('Error fetching 6-month statistics:', error);
    }
  };

  const getDurationInMillis = (duration) => {
    switch (duration) {
      case '1week':
        return 7 * 24 * 60 * 60 * 1000;
      case '1month':
        return 30 * 24 * 60 * 60 * 1000;
      case '6months':
        return 6 * 30 * 24 * 60 * 60 * 1000;
      default:
        return 6 * 30 * 24 * 60 * 60 * 1000;
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box className='chartContainerP'>
            <div className='alignFiltersP'>
              <GraphTypeFilter
                selectedGraphType={selectedGraphType}
                onGraphTypeChange={handleGraphTypeChange}
              />
              <TimeFilter
                selectedDuration={selectedDuration}
                onDurationChange={handleTimeFilterChange}
              />
              {showSymptomsDropdown && (
                <SymptomsFilter
                  selectedSymptoms={selectedSymptoms}
                  handleSymptomChange={handleSelectChange}
                  symptomsList={symptomsList}
                />
              )}
              {showBehaviorsDropdown && (
                <BehavioursFilter
                  selectedBehaviors={selectedBehaviors}
                  handleSelectChange={handleSelectChange}
                  behaviorsList={behaviorsList}
                />
              )}
            </div>
            <div className='adjustChartP'>
              {selectedGraphType === 'rating' && (
                <RatingLineChart
                  data={data}
                  selectedDuration={selectedDuration}
                />
              )}
              {selectedGraphType === 'frequency' && showBehaviorsDropdown && (
                <BehaviourStats
                  selectedPatientId={patientID || studentID}
                  getDurationInMillis={getDurationInMillis}
                  user={user}
                  typeOfUser={typeOfUser}
                  behaviorsList={behaviorsList}
                  setBehaviorsList={setBehaviorsList}
                  selectedDuration={selectedDuration}
                  selectedBehaviors={selectedBehaviors}
                />
              )}
              {selectedGraphType === 'symptom' && showSymptomsDropdown && (
                <SymptomRatingLineChart
                  data={symptomData}
                  selectedSymptoms={selectedSymptoms}
                  selectedDuration={selectedDuration}
                />
              )}

              {/* {typeOfUser === "DOCTOR" && */}
              {(ACM[typeOfUser]?.isPatient || ACM[typeOfUser]?.isStudent) &&
                selectedGraphType === 'videoUploads' && (
                  <VideoUploadStats
                    // data={data}
                    patientID={patientID}
                    studentID={studentID}
                    getDurationInMillis={getDurationInMillis}
                    selectedDuration={selectedDuration}
                  />
                )}
            </div>
          </Box>
        </Grid>
      </Grid>
      {/* {isLoading && <Progress size={"5rem"} />} */}
    </Box>
  );
};

export default SymptomRatingChart;

const RatingLineChart = ({ data, selectedDuration }) => {
  const sortedData = data?.slice().sort((a, b) => {
    if (selectedDuration === '1week' || selectedDuration === '1month') {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    }
    return 0;
  });
  if (!data || data.length === 0) {
    return (
      <div className='responsive flexBox'>
        <h4 className='noData'>No data available...</h4>
      </div>
    );
  }
  return (
    <ResponsiveContainer width='100%' height={'100%'}>
      <LineChart
        data={sortedData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey={selectedDuration === '6months' ? 'month' : 'date'}
          tick={{ fontSize: 15 }}
          stroke='#4913bd'
        >
          <Label value='Time' offset={0} dy={15} />
        </XAxis>
        <YAxis tick={{ fontSize: 15 }} stroke='#4913bd'>
          <Label
            value='Average Rating'
            angle={-90}
            offset={0}
            position='insideLeft'
            style={{ textAnchor: 'middle' }}
          />
        </YAxis>
        <Tooltip
          labelStyle={{ color: 'white' }}
          separator=' : '
          itemStyle={{ color: 'whitesmoke' }}
          wrapperStyle={{ outline: 'none' }}
          contentStyle={{
            borderRadius: 10,
            background: '#4913bd',
          }}
        />{' '}
        <Line
          dataKey={selectedDuration === '6months' ? 'average_rating' : 'rating'}
          type='monotone'
          fill='#8884D8'
          shape={<TriangleBar />}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
          ))}
        </Line>
      </LineChart>
    </ResponsiveContainer>
  );
};

const SymptomRatingLineChart = ({
  data,
  selectedSymptoms,
  selectedDuration,
}) => {
  if (!data || data.length === 0) {
    return (
      <div className='responsive flexBox'>
        <h4 className='noData'>No data available...</h4>
      </div>
    );
  }

  const sortedData = data.slice().sort((a, b) => {
    if (selectedDuration === '1week' || selectedDuration === '1month') {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    }
    return 0;
  });

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const colors = selectedSymptoms.map(() => getRandomColor());

  return (
    <ResponsiveContainer width='100%' height={'100%'}>
      <LineChart
        data={sortedData}
        margin={{
          top: 20,
          right: 20,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='date'>
          <Label value='Time' offset={0} dy={15} position='insideBottom' />
        </XAxis>
        <YAxis type='number' domain={[0, 3]}>
          <Label
            value='Rating'
            angle={-90}
            offset={0}
            position='insideLeft'
            style={{ textAnchor: 'middle' }}
          />
        </YAxis>
        <Tooltip />
        <Legend
          wrapperStyle={{
            paddingTop: '20px',
            textAlign: 'center',
            marginLeft: '25px',
          }}
        />
        {selectedSymptoms.map((symptom, index) => (
          <Line
            key={symptom}
            type='monotone'
            dataKey={symptom}
            name={symptom}
            stroke={colors[index]}
            // activeDot={{ r: 5 }}
            connectNulls
            dot={{
              stroke: `${colors[index]}`,
              strokeWidth: 2,
              fill: `${colors[index]}`,
            }}
            strokeWidth={2}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

const VideoUploadStats = ({
  data,
  patientID,
  studentID,
  selectedDuration,
  getDurationInMillis,
}) => {
  const { ACM } = useContext(ACMContext);
  const [videoList, setVideoList] = useState([]);
  const { user, typeOfUser } = useContext(AuthContext);
  const [monthCount, setMonthCount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const currentMonths = getPastMonthNames(DURATION_MONTHS);

  const getVideoUploadStats = async (id, duration) => {
    setIsLoading(true);
    try {
      const videos = await getPatientVideos(id, 'all');
      if (videos) {
        const uploadArray = [];

        videos?.forEach((video) => {
          const time = video.rawVideoData.time?.seconds || 0;
          const date = new Date(time * 1000).toLocaleDateString() || '-';

          const currentTimeStamp = new Date(time * 1000);
          const timeDifference = Date.now() - currentTimeStamp.getTime();

          if (timeDifference <= duration) {
            uploadArray.push({
              videoID: video?.videoId,
              date: currentTimeStamp.toLocaleDateString(),
              uploadCount: 1,
            });
          }
        });
        const result = [];
        uploadArray?.forEach((entry) => {
          const existingDateIndex = result.findIndex(
            (item) => item.date === entry.date
          );
          if (existingDateIndex !== -1) {
            result[existingDateIndex].uploadCount += entry.uploadCount / 2;
          } else {
            result.push({
              date: entry.date,
              uploadCount: Math.round(entry.uploadCount / 2),
            });
          }
        });

        setMonthCount(result);
      }
    } catch (error) {
      console.log('Error fetching statistics for video uploads:', error);
    }
  };

  const get6MonthVideoUploadStatistics = async (id) => {
    try {
      const videos = await getPatientVideos(id, 'all');
      const uploadArray = [];

      videos?.forEach((video) => {
        const uploadDate = video.rawVideoData?.time.seconds * 1000;
        if (uploadDate > getDateByPastMonths(6)) {
          uploadArray?.push({
            videoID: video?.videoId,
            month: ALL_MONTHS[new Date(uploadDate).getMonth()],
            uploadCount: 1,
          });
        }
      });

      const resultData = currentMonths?.map((month) => {
        const totalUploadsForMonth = uploadArray.reduce((sum, video) => {
          return video.month === month ? sum + video.uploadCount : sum;
        }, 0);
        return { month, uploadCount: Math.round(totalUploadsForMonth / 4) };
      });

      const isAllZero = resultData.every((item) => item.average_rating === 0);

      const finalData = isAllZero ? null : resultData;

      setMonthCount(finalData);
    } catch (error) {
      console.error('Error fetching 6-month video upload statistics:', error);
      return null;
    }
  };

  const {
    data: currentPatientVideos,
    isLoading: loadingfetchOnePatientAllVideos,
    error: errorPatient,
  } = useOnePatientVideos(user?.uid);

  useEffect(() => {
    const durationInMillis = getDurationInMillis(selectedDuration);
    // if (typeOfUser === "PATIENT") {
    if (ACM[typeOfUser]?.isPatient) {
      if (selectedDuration === '1week' || selectedDuration === '1month') {
        const durationInMillis = getDurationInMillis(selectedDuration);
        getVideoUploadStats(user?.uid, durationInMillis);
      } else if (selectedDuration === '6months') {
        get6MonthVideoUploadStatistics(user?.uid, selectedDuration);
      }
    }
    // if (typeOfUser === "DOCTOR") {
    if (ACM[typeOfUser]?.isDoctor) {
      if (selectedDuration === '1week' || selectedDuration === '1month') {
        const durationInMillis = getDurationInMillis(selectedDuration);
        getVideoUploadStats(patientID, durationInMillis);
      } else if (selectedDuration === '6months') {
        get6MonthVideoUploadStatistics(patientID, selectedDuration);
      }
    }
    if (ACM[typeOfUser]?.isTeacher) {
      if (selectedDuration === '1week' || selectedDuration === '1month') {
        const durationInMillis = getDurationInMillis(selectedDuration);
        getVideoUploadStats(studentID, durationInMillis);
      } else if (selectedDuration === '6months') {
        get6MonthVideoUploadStatistics(studentID, selectedDuration);
      }
    }
  }, [typeOfUser, selectedDuration, user]);

  // Only for patient login
  useEffect(() => {
    if (currentPatientVideos?.data?.length > 0) {
      setVideoList(currentPatientVideos.data);
    }
  }, [currentPatientVideos]);
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <LineChart
        data={monthCount}
        margin={{ top: 20, right: 20, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          dataKey={selectedDuration === '6months' ? 'month' : 'date'}
          tick={{ fontSize: 15 }}
          stroke='#4913bd'
        >
          <Label value='Time' offset={0} dy={15} />
        </XAxis>
        <YAxis tick={{ fontSize: 15 }} stroke='#4913bd'>
          <Label
            value='Average video uploads'
            angle={-90}
            offset={0}
            position='insideLeft'
            style={{ textAnchor: 'middle' }}
          />
        </YAxis>
        <Tooltip
          labelStyle={{ color: 'white' }}
          separator=' : '
          itemStyle={{ color: 'whitesmoke' }}
          wrapperStyle={{ outline: 'none' }}
          contentStyle={{ borderRadius: 10, background: '#4913bd' }}
        />
        <Line
          dataKey={'uploadCount'}
          type='monotone'
          fill='#8884D8'
          shape={<TriangleBar />}
        >
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
          ))}
        </Line>
      </LineChart>
    </ResponsiveContainer>
  );
};

const BehaviourStats = ({
  selectedPatientId,
  getDurationInMillis,
  selectedDuration,
  user,
  typeOfUser,
  behaviorsList,
  selectedBehaviors,
  setBehaviorsList,
}) => {
  const { ACM } = useContext(ACMContext);
  const [behaviorData, setBehaviorData] = useState([]);
  const [data, setData] = useState([]);
  const currentMonths = getPastMonthNames(DURATION_MONTHS);

  const sortedData = data.slice().sort((a, b) => {
    if (selectedDuration === '1week' || selectedDuration === '1month') {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    }
    return 0;
  });

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    const durationInMillis = getDurationInMillis(selectedDuration);

    // if (typeOfUser === "DOCTOR") {
    if (ACM[typeOfUser]?.isDoctor || ACM[typeOfUser]?.isTeacher) {
      if (
        selectedPatientId &&
        (selectedDuration === '1week' || selectedDuration === '1month')
      ) {
        const filteredData = behaviorData.filter((entry) =>
          selectedBehaviors.includes(entry.behaviour)
        );
        getAverageFrequencyCount(
          selectedPatientId,
          durationInMillis,
          filteredData
        );
      }
    }
  }, [typeOfUser, selectedDuration, user, selectedBehaviors]);

  const getAverageFrequencyCount = async (id, duration, filteredData) => {
    try {
      const feedbackData = await getAllVideosFeedbackData(id);
      if (feedbackData) {
        const frequencyCountArray = [];
        const behaviorsArray = [];
        const behaviorsListArr = [];
        const behaviorsMap = new Map();

        feedbackData?.forEach((item) => {
          const docId = item.docId;
          const time = item.docData.time?._seconds || 0;
          const date = new Date(time * 1000).toLocaleDateString() || '-';

          if (item.docData.behaviours) {
            item?.docData?.behaviours?.forEach((behavior) => {
              const currentTimeStamp = new Date(time * 1000);
              const timeDifference = Date.now() - currentTimeStamp.getTime();

              if (timeDifference <= duration) {
                behaviorsArray.push({
                  videoID: `${docId} V${behaviorsArray.length}`,
                  date: currentTimeStamp.toLocaleDateString(),
                  behavior: behavior.behaviour,
                  frequencyCount: behavior.behaviour_rating,
                });
                const behaviorName = behavior.behaviour;
                if (selectedBehaviors.includes(behaviorName)) {
                  behaviorsArray.push({
                    videoID: `${docId} V${behaviorsArray.length}`,
                    date: currentTimeStamp.toLocaleDateString(),
                    behavior: behaviorName,
                    frequencyCount: behavior.behaviour_rating,
                  });
                }

                behaviorsListArr.push(behaviorName);
              }
            });
          }
        });

        const result = [];
        frequencyCountArray.reduce(function (res, value) {
          if (!res[value.date]) {
            res[value.date] = {
              date: value.date,
              frequencyCount: value.frequencyCount,
            };
            result.push(res[value.date]);
          }
          res[value.date].frequencyCount +=
            value.frequencyCount /
            frequencyCountArray.filter((x) => x.date === value.date).length;
          return res;
        }, {});

        behaviorsArray?.forEach((entry) => {
          const existingBehavior = behaviorsMap.get(entry.date);
          if (existingBehavior) {
            existingBehavior[entry.behavior] = entry.frequencyCount;
          } else {
            behaviorsMap.set(entry.date, {
              [entry.behavior]: entry.frequencyCount,
            });
          }
        });

        const aggregatedBehaviorData = Array.from(behaviorsMap).map(
          ([date, behaviors]) => ({
            date: date,
            ...behaviors,
          })
        );

        setBehaviorData(aggregatedBehaviorData);
        setBehaviorsList([...new Set(behaviorsListArr)]);
        setData(aggregatedBehaviorData);
      }
    } catch (error) {
      console.error(
        'Error fetching behavior Average Behaviors count by date:',
        error
      );
    }
  };

  const colors = useMemo(
    () => selectedBehaviors?.map(() => getRandomColor()),
    [selectedBehaviors]
  );

  return (
    <>
      <ResponsiveContainer width='100%' height={'100%'}>
        {selectedBehaviors && selectedBehaviors?.length > 0 ? (
          <LineChart
            data={sortedData}
            margin={{
              top: 30,
              right: 20,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='date'>
              <Label value='Time' offset={0} dy={15} position='insideBottom' />
            </XAxis>
            <YAxis type='number' domain={[0, 3]}>
              <Label
                value='Frequency Count'
                angle={-90}
                offset={0}
                position='insideLeft'
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>
            <Tooltip />
            <Legend
              wrapperStyle={{
                paddingTop: '20px',
                textAlign: 'center',
                marginLeft: '25px',
              }}
            />
            {selectedBehaviors?.map((behavior, index) => (
              <Line
                key={behavior}
                type='monotone'
                dataKey={behavior}
                name={behavior}
                stroke={colors[index]}
                // activeDot={{ r: 5 }}
                connectNulls
                dot={{
                  stroke: `${colors[index]}`,
                  strokeWidth: 2,
                  fill: `${colors[index]}`,
                }}
                strokeWidth={2}
              />
            ))}
          </LineChart>
        ) : (
          <div className='responsive flexBox'>
            <h4 className='noData'>No data available...</h4>
          </div>
        )}
      </ResponsiveContainer>
    </>
  );
};
