import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { DataContext } from '../contexts/DataProvider';

const useOnePatientData = (patientUid) => {
  const { fetchOnePatientData } = useContext(DataContext);
  return useQuery({
    queryKey: ['onePatientData', patientUid],
    queryFn: () => fetchOnePatientData(patientUid),
    enabled: !!patientUid,
    staleTime: 60000, // 1 min
    cacheTime: 300000, // 5 min
  });
};

export default useOnePatientData;
