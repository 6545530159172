import React, { useContext, useEffect, useState } from 'react';
import MainLayout from '../../../hoc/MainLayout';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import AuthContext from '../../../contexts/AuthContext';
import { getSchoolAdminsAndSchoolsForOrgAdmin } from '../../../api';
import { ACMContext } from '../../../contexts/ACMProvider';
const OrganizationSchools = () => {
  const { ACM } = useContext(ACMContext);
  const [fetchedData, setFetchedData] = useState([]);
  const { typeOfUser, user } = useContext(AuthContext);
  const getOrgAdminData = async () => {
    if (ACM[typeOfUser]?.isOrganizationAdmin) {
      try {
        const fetchedOrgAdminData = await getSchoolAdminsAndSchoolsForOrgAdmin(
          user?.uid,
          'ORGANIZATIONADMIN'
        );
        setFetchedData(fetchedOrgAdminData);
      } catch (err) {
        console.log('error fetching org Admin data:', err);
      }
    }
  };

  useEffect(() => {
    if (ACM[typeOfUser]?.isOrganizationAdmin) {
      getOrgAdminData();
    }
  }, [user, typeOfUser]);
  return (
    <MainLayout>
      <Box sx={{ padding: '20px' }}>
        <Typography variant='h4' gutterBottom>
          Schools Details
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>
                  Organization Name
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  School Name
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fetchedData?.schoolsList?.length > 0 ? (
                fetchedData?.schoolsList?.map((schools, index) => (
                  <TableRow key={schools.info?.id || index}>
                    <TableCell>{schools?.info?.organizationName}</TableCell>
                    <TableCell>{schools?.info?.schoolName}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align='center'>
                    No Organization schools data.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </MainLayout>
  );
};
export default OrganizationSchools;
