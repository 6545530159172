import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";

const Goals = ({ behaviour, isLoading, behavioursName, updateGoals }) => {
  const goalsList = [
    { goal: "goal1", description: "test 1" },
    { goal: "goal2", description: "test 2" },
  ];

  const [goalDescription, setGoalDescription] = useState("");
  const [baselineDescription, setBaselineDescription] = useState("");
  const [selectedGoalsList, setSelectedGoalsList] = useState(goalsList);
  const [isCustomBehaviour, setIsCustomBehaviour] = useState(false);

  // const handleGoalChange = (event) => {
  //   const newGoal = event.target.value;
  //   setSelectedGoal(newGoal);
  // };
  console.log(behaviour, "goalsbehavio");
  const behaviourHandler = (e) => {
    console.log(e.target.value, "abcd");
    if (e.target.value !== "other") {
      updateGoals(e.target.value, "behaviour", behaviour.id);
      setIsCustomBehaviour(false);
    } else {
      setIsCustomBehaviour(true);
    }
  };

  const handleAddBox = () => {
    if (goalDescription && baselineDescription) {
      console.log("Adding Goal:", { goalDescription, baselineDescription });
      const newGoal = {
        goal: goalDescription,
        description: baselineDescription,
      };
      setSelectedGoalsList([...selectedGoalsList, newGoal]);
      setGoalDescription(""); // Clearing the goalDescription field
      setBaselineDescription(""); // Clearing the baselineDescription field
      // Refresh the data (if necessary)
      updateGoals(behaviour?.behaviour, "Behaviour", behaviour.id);
    }
  };

  const handleEdit = (index) => {
    const newList = [...selectedGoalsList];
    newList[index].editable = true;
    setSelectedGoalsList(newList);
  };

  const handleSave = (index) => {
    const newList = [...selectedGoalsList];
    newList[index].editable = false;
    setSelectedGoalsList(newList);
  };

  const handleEditChange = (event, index, field) => {
    const newList = [...selectedGoalsList];
    newList[index][field] = event.target.value;
    setSelectedGoalsList(newList);
  };

  useEffect(() => {
    setSelectedGoalsList(selectedGoalsList);
  }, [selectedGoalsList]);

  if (isLoading)
    return (
      <CircularProgress
        sx={{ position: "absolute", left: "81%", top: "30%" }}
      />
    );

  return (
    <Card className="treatmentBox" sx={{ border: "1px solid lightgray", p: 1 }}>
      <CardHeader subheader={"Goals"} />

      <CardContent>
        <div
          className="cardContent"
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 5,
          }}
        >
          <Box className="Select">
            <Box>
              <div className="input1">Select goal: </div>
            </Box>
            <Box className="input2">
              <InputLabel
                label="select goals"
                id="demo-simple-select-label"
                style={{
                  position: "absolute",
                  marginLeft: "17px",
                  marginTop: "17px",
                }}
              ></InputLabel>
              <Select
                label="select goals"
                value={isCustomBehaviour ? "other" : behaviour?.behaviour}
                onChange={behaviourHandler}
                style={{
                  backgroundColor: "transparent",
                  width: "300px",
                }}
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
              >
                {behavioursName?.length > 0 &&
                  behavioursName?.map((item, i) => {
                    return (
                      <MenuItem key={i} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </Box>
          </Box>

          <Box className="GoalsBox">
            <Box className="Cardheading" sx={{ textDecoration: "underline" }}>
              Goal
            </Box>
            <TextField
              label="Description for goals"
              multiline
              value={goalDescription}
              onChange={(e) => setGoalDescription(e.target.value)}
              fullWidth
              sx={{ mb: 1 }}
              className="goalsContainer"
            />
            <Box className="Cardheading" sx={{ textDecoration: "underline" }}>
              Baseline
            </Box>
            <TextField
              label="Description for Baseline"
              multiline
              value={baselineDescription}
              onChange={(e) => setBaselineDescription(e.target.value)}
              fullWidth
              sx={{ mb: 1 }}
              className="goalsContainer"
            />
          </Box>
          {selectedGoalsList.map((item, index) => (
            <div key={index} style={{ width: "100%" }} className="GoalsBox">
              <Box
                className="Cardheading"
                sx={{ textDecoration: "underline" }}
              >{`Goal#${index + 1}`}</Box>
              <TextField
                value={item.goal}
                multiline
                fullWidth
                sx={{ mb: 1 }}
                disabled={!item.editable}
                onChange={(e) => handleEditChange(e, index, "goal")}
                className="goalsContainer"
              />
              <Box className="Cardheading" sx={{ textDecoration: "underline" }}>
                Baseline
              </Box>
              <TextField
                value={item.description}
                multiline
                fullWidth
                sx={{ mb: 1 }}
                disabled={!item.editable}
                onChange={(e) => handleEditChange(e, index, "description")}
                className="goalsContainer"
              />
              {item.editable ? (
                <Button onClick={() => handleSave(index)}>Save</Button>
              ) : (
                <Button onClick={() => handleEdit(index)}>Edit</Button>
              )}
            </div>
          ))}
        </div>
        <Box
          sx={{
            float: "right",
            border: "1px solid gray",
            marginTop: "5px",
            borderRadius: "20px",
            width: "fit-content",
            padding: "7px",
          }}
        >
          <Box
            onClick={handleAddBox}
            disabled={!goalDescription || !baselineDescription}
          >
            Add Goal
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Goals;
