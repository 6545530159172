import { PlayArrow } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  tooltipClasses,
  Tooltip,
  Skeleton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { doc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState, useContext } from 'react';
import PlayVideo from '../../components/PlayVideo';
import { UIContext } from '../../contexts/UIProvider';
import { FirestoreDB } from '../../utils/firebase/firebase';
import DeleteModel from '../PatientOverview/DeleteModel';
import { ACM } from '../../constants/UserRoles';
import useOnePatientVideos from '../../hooks/useOnePatientVideos';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-calendar/dist/Calendar.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: 'bold',
    padding: '10px',
  },
}));
const VideosListStudent = ({ user, typeOfUser }) => {
  const { setBottomDrawer } = useContext(UIContext);
  const [videoList, setVideoList] = useState([]);
  const [pendingVideos, setPendingVideos] = useState([]);
  const [reviewedVideos, setReviewedVideos] = useState([]);
  const [filteredPendingVideoList, setFilteredPendingVideoList] = useState([]);
  const [filteredReviewedVideoList, setFilteredReviewedVideoList] = useState(
    []
  );
  console.log("user for stdnt login:",user)
  const [expanded, setExpanded] = React.useState(null);
  const [loading, setLoading] = useState(true);
  const {
    data: currentPatientVideos,
    isLoading: loadingfetchOnePatientAllVideos,
    refetch: refetchOnePatientVideos,
  } = useOnePatientVideos(user?.uid);
  // Only for Student login
  useEffect(() => {
    if (currentPatientVideos?.data?.length > 0) {
      const pVideos = currentPatientVideos.data
        .filter((video) => !video.docNotesDocId)
        .sort(
          (a, b) => b.rawVideoData?.time.seconds - a.rawVideoData?.time.seconds
        );
      setPendingVideos(pVideos);
      setVideoList(currentPatientVideos.data);
    }
    setLoading(false);
  }, [currentPatientVideos]);
  const showToastMessage = (status) => {
    if (status === 'success') {
      toast.success('The video has been deleted successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error('Failed to delete the video. Please try again.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  const handleVideoOpen = (videoDetails, patientID, source) => {
    const filterList =
      source === 'pending' && ACM[typeOfUser]?.isDoctor
        ? filteredPendingVideoList
        : filteredReviewedVideoList;
    let videos = [];
    if (ACM[typeOfUser]?.isDoctor) {
      if (source === 'pending') {
        videos = pendingVideos;
      } else {
        videos = reviewedVideos;
      }
    } else {
      source = 'pending';
      videos = pendingVideos;
    }
    setBottomDrawer({
      show: true,
      title: videoDetails?.rawVideoData?.fileName,
      patientName: videoDetails?.user?.fullname || user?.fullname,
      patientPhoto: videoDetails?.user?.photoURL
        ? videoDetails?.user?.photoURL
        : '',
      content:
        videoDetails && videoDetails.videoId ? (
          <PlayVideo
            videoList={
              // typeOfUser === "DOCTOR"
              ACM[typeOfUser]?.isDoctor
                ? filterList
                    .filter((patient) => patient?.user.id === patientID)[0]
                    ?.videos?.sort((a, b) => {
                      return (
                        b.rawVideoData?.time.seconds -
                        a.rawVideoData?.time.seconds
                      );
                    })
                    .map((video) => {
                      return {
                        ...video,
                        user: filterList.filter(
                          (patient) => patient?.user.id === patientID
                        )[0]?.user,
                      };
                    })
                : videos
                    .sort((a, b) => {
                      return (
                        b.rawVideoData?.time.seconds -
                        a.rawVideoData?.time.seconds
                      );
                    })
                    .map((video) => {
                      return {
                        ...video,
                        user: user,
                      };
                    })
            }
            handleVideoOpen={handleVideoOpen}
            videoDetails={videoDetails}
            patientID={patientID || videoDetails?.user?.uid}
            typeOfUser={typeOfUser}
            videoSource={source}
          />
        ) : (
          <div>Loading or error message for undefined videoDetails</div>
        ),
    });
  };
  const softDeleteVideo = async (videoId, patientId) => {
    try {
      const videoLogRef = doc(
        FirestoreDB,
        'videoLogs',
        patientId,
        'patientNotes',
        videoId
      );
      const res = await updateDoc(videoLogRef, { isDeleted: true });
      showToastMessage('success');
      refetchOnePatientVideos();
    } catch (e) {
      showToastMessage('fail');
      console.log(e);
    }
  };
  const capitalizeWords = (name) => {
    return name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  const truncateName = (name, limit = 10) => {
    const capitalized = capitalizeWords(name);
    if (capitalized?.length > limit) {
      return `${capitalized.slice(0, limit)}...`;
    }
    return capitalized;
  };
  const loadingList = Array(4)
    .fill(null)
    .map((_, index) => (
      <ListItem
        key={index}
        className='flexBox flexCenter listItemBox'
        style={{
          width: '97%',
          border: '1px solid white',
          boxShadow: '0 0 5px var(--clr-theme-purple)',
          padding: '16px',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          marginBottom: '6px',
        }}
      >
        <ListItemAvatar>
          <Skeleton
            variant='rectangular'
            width={40}
            height={40}
            sx={{
              borderRadius: '10%',
              backgroundColor: '#e0e0e0',
            }}
          />
        </ListItemAvatar>
        <Skeleton
          variant='rectangular'
          width='85%'
          height={15}
          animation='pulse'
          sx={{
            borderRadius: '4px',
          }}
        />
      </ListItem>
    ));
  return (
    <Box>
      <Box
        className='flexBox flexColumn flexWrap'
        sx={{
          alignItems: 'start',
          gap: 2,
        }}
      >
        <Box>
          <Typography
            variant='h5'
            sx={{
              fontFamily: 'Lato',
              fontWeight: 700,
              color: 'var(--clr-theme-purple)',
            }}
          >
            Videos
          </Typography>
        </Box>
      </Box>
      {ACM[typeOfUser]?.patPendingVideos && (
        <Box>
          {pendingVideos.length > 0 ? (
            <List className='newVideosList videoList'>
              {pendingVideos.map((video, i) => {
                return (
                  <ListItem key={i} className='flexBox flexCenter listItemBox'>
                    <ListItemAvatar>
                      <Avatar
                        variant='rounded'
                        alt={`Profile picture of ${user?.fullname}`}
                        src={user?.photoURL}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography className='username' variant='caption'>
                          {video?.rawVideoData?.fileName
                            ? `${video?.rawVideoData?.fileName
                                .split(/\s+/)
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() +
                                    word.slice(1).toLowerCase()
                                )
                                .join(' ')}`
                            : ''}
                        </Typography>
                      }
                    />
                    <Box className='flexBox flexCenter'>
                      <ListItemText
                        className='daysAgo'
                        primary={
                          <Typography variant='caption'>
                            {`${Math.floor(
                              (new Date() -
                                new Date(
                                  video?.rawVideoData?.time?.seconds * 1000
                                )) /
                                (1000 * 3600 * 24)
                            )} days ago`}
                          </Typography>
                        }
                      />
                      <Button
                        onClick={() => {
                          handleVideoOpen(video, user?.uid);
                        }}
                      >
                        <PlayArrow />
                      </Button>
                      <DeleteModel
                        videoFileName={video.rawVideoData?.fileName}
                        videoId={video.rawVideoData?.id}
                        patientId={user?.uid}
                        handleDelete={() => {
                          softDeleteVideo(video?.rawVideoData.id, user?.uid);
                        }}
                      />
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <Box>
              <div>
                <Typography
                  color='secondary'
                  sx={{
                    fontFamily: 'unset',
                    letterSpacing: '0em',
                    fontSize: '16px',
                  }}
                >
                  {loadingfetchOnePatientAllVideos || loading
                    ? loadingList
                    : 'No videos to review.'}
                </Typography>
              </div>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
export default VideosListStudent;