import * as MuiIcons from '@mui/icons-material';
import { MdFiberNew } from 'react-icons/md';

// Common menu item properties
const commonMenuProps = {
  Settings: {
    key: 'Settings',
    name: 'Settings',
    icon: <MuiIcons.SettingsOutlined />,
    path: '/settings',
  },
  Home: (path = '/') => ({
    key: 'Home',
    name: 'My Dashboard',
    icon: <MuiIcons.HomeOutlined />,
    path,
  }),
};

// Define consistent styling
const peopleIconStyle = { color: 'var(--clr-theme-white)' };

export const LeftMenu = [
  commonMenuProps.Home(),
  {
    key: 'Patient List',
    name: 'Patient List',
    icon: <MuiIcons.PeopleOutlined sx={{ color: 'var(--clr-theme-gray)' }} />,
    collapsible: true,
  },
  {
    key: 'Appointments',
    name: 'Appointments',
    icon: <MuiIcons.CalendarMonthOutlined />,
    path: '/appointments',
  },
  {
    key: 'Statistics',
    name: 'Statistics',
    icon: <MuiIcons.QueryStatsOutlined />,
    path: '/statistics',
  },
  {
    key: 'HistoricalData',
    name: 'Historical Data',
    icon: <MuiIcons.MenuBookOutlined />,
    path: '/historical',
  },
  {
    key: 'Messaging',
    name: 'Messaging',
    icon: <MuiIcons.Message />,
    additionalIcon: <MdFiberNew />,
    path: '/message',
  },
  commonMenuProps.Settings,
];

export const SchoolAdminLeftMenu = [
  commonMenuProps.Home('/schoolAdmin'),
  {
    key: 'Teacher List',
    name: 'Teacher List',
    icon: <MuiIcons.PeopleOutlined sx={peopleIconStyle} />,
    collapsible: true,
    path: '/teachers',
  },
  {
    key: 'Student List',
    name: 'Student List',
    icon: <MuiIcons.PeopleOutlined sx={peopleIconStyle} />,
    collapsible: true,
    path: '/students',
  },
  commonMenuProps.Settings,
];

export const AdminLeftMenu = [
  {
    key: 'Home',
    name: 'Dashboard',
    icon: <MuiIcons.HomeOutlined />,
    path: '/admin',
  },
  {
    key: 'Schools',
    name: 'Schools',
    icon: <MuiIcons.AddHomeWorkOutlined />,
    path: '/admin/schools',
  },
  {
    key: 'Organization Admin',
    name: 'Organization Admin',
    icon: <MuiIcons.ManageAccountsOutlined />,
    path: '/admin/organization',
  },
  {
    key: 'School Admin',
    name: 'School Admin',
    icon: <MuiIcons.AccountBoxOutlined />,
    path: '/admin/schoolsAdmin',
  },
  {
    key: 'Teachers',
    name: 'Teachers',
    icon: <MuiIcons.BusinessCenterOutlined />,
    path: '/admin/teachers',
  },
  {
    key: 'Students',
    name: 'Students',
    icon: <MuiIcons.SchoolOutlined />,
    path: '/admin/students',
  },
  {
    key: 'Permissions',
    name: 'Permissions',
    icon: <MuiIcons.Lock />,
    path: '/admin/permissions',
  },
  {
    key: 'Terms and Policies',
    name: 'Terms and Policies',
    icon: <MuiIcons.GavelOutlined />,
    path: '/admin/terms',
  },
];

export const TeacherLeftMenu = [
  {
    key: 'Home',
    name: 'Dashboard',
    icon: <MuiIcons.HomeOutlined />,
    path: '/teacher',
  },
  {
    key: 'Student List',
    name: 'Student List',
    icon: <MuiIcons.PeopleOutlined sx={{ color: 'black' }} />,
    collapsible: true,
  },
  {
    key: 'Statistics',
    name: 'Statistics',
    icon: <MuiIcons.QueryStatsOutlined />,
    path: '/statistics',
  },
  {
    key: 'Goals',
    name: 'Goals',
    icon: <MuiIcons.TrackChangesOutlined />,
    path: '/viewgoals',
  },
  {
    key: 'HistoricalData',
    name: 'Historical Data',
    icon: <MuiIcons.MenuBookOutlined />,
    path: '/historical',
  },
  {
    key: 'Messaging',
    name: 'Messaging',
    icon: <MuiIcons.Message />,
    additionalIcon: <MdFiberNew />,
    path: '/message',
  },
  commonMenuProps.Settings,
];

export const LeftMenu_BottomSection = [
  {
    key: 'Privacy',
    name: 'Privacy Statement',
    icon: <MuiIcons.PrivacyTip fontSize='small' />,
    path: '/Privacy',
  },
  {
    key: 'Termcondition',
    name: 'Terms and Conditions',
    icon: <MuiIcons.BrandingWatermark fontSize='small' />,
    path: '/Termcondition',
  },
  {
    key: 'Contact',
    name: 'Contact Information',
    icon: <MuiIcons.ContactPhone fontSize='small' />,
    path: '/Contact',
  },
];

export const StudentLeftMenu = [commonMenuProps.Home()];

export const OrgAdminLeftMenu = [
  {
    key: 'Home',
    name: 'Dashboard',
    icon: <MuiIcons.HomeOutlined />,
    path: '/organizationAdmin',
  },
  {
    key: 'School List',
    name: 'School List',
    icon: <MuiIcons.SchoolOutlined />,
    path: 'organizationAdmin/schools',
  },
  {
    key: 'School Admins',
    name: 'School Admins',
    icon: <MuiIcons.SchoolOutlined />,
    path: 'organizationAdmin/schoolAdmins',
  },
  commonMenuProps.Settings,
];

export const DRAWER_WIDTH = 297;
