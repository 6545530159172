import React, { useContext, useEffect, useRef, useState } from 'react';
import MainLayout from '../../hoc/MainLayout';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Avatar,
  Box,
  TextField,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  CardHeader,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import { Search, Groups, Chat, Groups2 } from '@mui/icons-material';
import './Messaging.css'; // Import the external CSS file
import AuthContext from '../../contexts/AuthContext';
import usePatientsForDoctor from '../../hooks/usePatientsForDoctor';
import { UIContext } from '../../contexts/UIProvider';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FirestoreDB } from '../../utils/firebase/firebase';
import {
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  query,
  where,
  writeBatch,
} from 'firebase/firestore';
import UnreadMessageCounts from './UnreadCount';
import { orderBy } from 'lodash';
import Conversations from './Conversation';
import Progress from '../../components/Progress';
import { styled } from '@mui/material/styles';
import { ACMContext } from '../../contexts/ACMProvider';
import useStudentsForOneTeacher from '../../hooks/useStudentsForOneTeacher';
import { fetchingTeachersForStudents } from '../../api';
import { convertLength } from '@mui/material/styles/cssUtils';
import useTeachersForOneStudent from '../../hooks/useTeachersForOneStudent';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: 'bold',
    padding: '10px',
  },
}));

const Message = () => {
  const { ACM } = useContext(ACMContext);
  const countsObjRef = useRef({}); // Using ref to store unread counts

  const [messages, setMessages] = useState([]);
  const { user, typeOfUser } = useContext(AuthContext);

  const { selectedPatientId, selectPatient, setIsNewMessage } =
    useContext(UIContext);
  const [currentPatient, setCurrentPatient] = useState(null);
  const [currentStudent, setCurrentStudent] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [currentDoctor, setCurrentDoctor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [unreadCounts, setUnreadCounts] = useState([]);
  const [unreadCountsForGroup, setUnreadCountsForGroup] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [groups, setGroups] = useState([]);
   const [selectedTab, setSelectedTab] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentTeacher, setCurrentTeacher] = useState(null);
  const [fetchedTeachers, setFetchedTeachers] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const scroll = useRef(null);
  const locationPatientId = location?.state?.patientId;
  const locationDoctorId = location?.state?.doctorId;
  const locationStudentId = location?.state?.studentId;
  const locationTeacherId = location?.state?.teacherId;

  const {
    data: patientData = [],
    isLoading: isLoading,
    error: errorPatientData,
  } = usePatientsForDoctor(user?.uid);

  const {
    data: studentData = [],
    isLoading: isStudentDataLoading,
    error: errorStudentData,
  } = useStudentsForOneTeacher(user?.uid, typeOfUser);

  const {
    data: teacherData = [],
    isLoading: isTeacherDataLoading,
    error: errorTeacherData,
  } = useTeachersForOneStudent(user?.uid, typeOfUser);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const fetchGroups = () => {
    if (!user?.uid) {
      // If user?.uid is undefined, show loading message and return a no-op function
      setLoading(true);
      return () => {};
    }

    const groupCollection = collection(FirestoreDB, 'groupChats');
    const q = query(
      groupCollection,
      where('members', 'array-contains', user?.uid)
    );

    const unsubscribe = onSnapshot(q, (groupSnapshot) => {
      const groupList = groupSnapshot.docs.map((doc) => ({
        groupId: doc.id,
        ...doc.data(),
      }));
      setGroups(groupList);
      setLoading(false);
    });

    return unsubscribe; // Return the unsubscribe function
  };

  useEffect(() => {
    const unsubscribe = fetchGroups();
    return () => unsubscribe(); // Clean up the listener when the component unmounts
  }, [user?.uid]);

  const handleClickDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setErrorMessage('');
    resetStates();
  };

  const handlePatientCheckboxChange = (member) => {
    // member = { id, name, photoURL, typeOfUser: "DOCTOR" || "PATIENT" };
    const isChecked = selectedPatients.find((item) => item?.id === member?.id);
    if (isChecked) {
      setSelectedPatients(
        selectedPatients.filter((item) => item?.id !== member?.id)
      );
    } else {
      setSelectedPatients([...selectedPatients, member]);
    }
    setErrorMessage('');
  };

  const createGroup = async (creator = '', members = []) => {
      try {
      const groupRef = collection(FirestoreDB, 'groupChats');
      const res = await addDoc(groupRef, {
        createdBy: creator?.id,
        members: [...members.map((member) => member.id), creator?.id],
        groupName: groupName,
        createdAt: new Date(),
      });
      const batch = writeBatch(FirestoreDB);
      [...members, creator].forEach((member) => {
        const memberRef = doc(
          FirestoreDB,
          'groupChats',
          res.id,
          'members',
          member.id
        );
        batch.set(memberRef, member);
      });
      await batch.commit();
      toast.success('Group created successfully', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error) {
      console.error('Error creating group:', error);
    }
  };

  const handleCreateGroup = () => {
    if (!groupName) {
      setErrorMessage('Group name is required');
      return;
    }
    if (selectedPatients.length === 0) {
      setErrorMessage('At least one patient must be selected');
      return;
    }
    createGroup(
      {
        id: user.uid,
        name: user?.fullname,
        parentName: user?.parentName || '',
        photoURL: user?.photoURL,
        typeOfUser,
      },
      selectedPatients
    );
    handleDialogClose();
    resetStates();
  };

  const resetStates = () => {
    setSelectedPatients([]);
    setGroupName('');
  };

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
    if (errorMessage && e.target.value) {
      setErrorMessage(''); // Clear the error message when a valid group name is entered
    }
  };

  const changeReadStatusToDB = async (uid) => {
    const messageRef = collection(
      FirestoreDB,
      'notifications',
      user?.uid,
      'messages'
    );

    const querySnapshot = await getDocs(
      query(
        messageRef,
        orderBy('createdDate', 'desc'),
        where('userId', '==', uid),
        limit(20)
      )
    );

    const batch = writeBatch(FirestoreDB);
    querySnapshot.forEach((doc) => {
      batch.update(doc.ref, { readStatus: true });
    });
    await batch.commit();
  };

  const changeReadStatusForGroup = async (groupId) => {
    try {
      const snapshot = await getDocs(
        query(
          collection(FirestoreDB, 'groupChats', groupId, 'groupMessages'),
          where('readStatus', 'array-contains', {
            id: user?.uid,
            status: false,
          }),
          where('notificationSource', '==', 'groupMessage')
        )
      );

      const batch = writeBatch(FirestoreDB);
      snapshot.forEach((doc) => {
        const messageData = doc.data();
        const updatedReadStatus = messageData.readStatus.map((statusObj) => {
          if (statusObj.id === user?.uid) {
            return { ...statusObj, status: true }; // Mark as read
          }
          return statusObj;
        });
        batch.update(doc.ref, { readStatus: updatedReadStatus });
      });

      await batch.commit();
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  const showPatientConversation = async (patient) => {
    if (!patient?.patientId) {
      console.error('Invalid patient object:', patient);
      return;
    }
    setCurrentPatient(patient);
    setSelectedChat(patient);
    await changeReadStatusToDB(patient?.patientId);
    setUnreadCounts((prevCounts) => [
      ...prevCounts.filter((count) => count?.patientId !== patient?.patientId),
      { patientId: patient?.patientId, count: 0 },
    ]);
  };

  const showStudentConversation = async (student) => {
    setCurrentStudent(student);
    setSelectedChat(student);
    await changeReadStatusToDB(student?.id);
    setUnreadCounts((prevCounts) => [
      ...prevCounts.filter((count) => count.studentId !== student?.id),
      { patientId: student.id, count: 0 },
    ]);
  };

  const showDoctorConversation = async (doctor) => {
    setCurrentDoctor(doctor);
    setSelectedChat(doctor);
    await changeReadStatusToDB(doctor?.doctorId);
    setUnreadCounts((prevCounts) => [
      ...prevCounts.filter((count) => count.doctorId !== doctor?.doctorId),
      { doctorId: doctor.doctorId, count: 0 },
    ]);
  };
  const showTeacherConversation = async (teacher) => {
    setCurrentTeacher(teacher);
    setSelectedChat(teacher);
    // try {
    //   await changeReadStatusToDB(teacher?.id);
    // } catch (error) {
    //   console.error('Error updating read status:', error);
    // }
    await changeReadStatusToDB(teacher?.id);
    setUnreadCounts((prevCounts) => [
      ...prevCounts.filter((count) => count.teacherId!== teacher?.id),
      { doctorId: teacher?.id, count: 0 },
    ]);
  };

  const showGroupConversation = async (group) => {
    setSelectedChat(group); // Set selected chat
    await changeReadStatusForGroup(group.groupId); // Automatically mark messages as read
    setUnreadCountsForGroup((prevCounts) =>
      prevCounts.map((countObj) =>
        countObj.groupId === group.groupId
          ? { ...countObj, count: 0 }
          : countObj
      )
    );

    setIsNewMessage((prevCounts) => {
      if (!prevCounts || !Array.isArray(prevCounts)) {
        return false; // No new messages if prevCounts is undefined or not an array
      }
      const updatedCounts = prevCounts.map((countObj) =>
        countObj.groupId === group.groupId
          ? { ...countObj, count: 0 }
          : countObj
      );
      return updatedCounts.some((countObj) => countObj.count > 0); // Check if any groups still have unread messages
    });
  };
  const goToPatient = (id) => {
    selectPatient(id);

    navigate(`/patient/${id}`, {
      state: {
        patientData: {
          ...patientData?.find((patient) => patient?.patientId === id)
            ?.patientInfo,
          id,
        },
      },
    });
  };

  // const goToStudent = (id) => {
  //   selectPatient(id);
  //   navigate(`/student/${id}`, {
  //     state: {
  //       studentData: {
  //         ...studentData?.find((student) => student?.id === id)
  //         ?.id,
  //       },
  //     },
  //   });
  // };

  useEffect(() => {
    const unsubscribes = [];
    const fetchGroupUnreadCounts = () => {
      try {
        if (groups) {
          for (const group of groups) {
            const readStatusQuery = { id: user?.uid, status: false };
            const unsubscribe = onSnapshot(
              query(
                collection(
                  FirestoreDB,
                  'groupChats',
                  group?.groupId,
                  'groupMessages'
                ),
                where('readStatus', 'array-contains', readStatusQuery),
                where('notificationSource', '==', 'groupMessage')
              ),
              (snapshot) => {
                const count = snapshot.size;
                countsObjRef.current[group.groupId] = count;

                const counts = Object.entries(countsObjRef.current).map(
                  ([id, count]) => ({
                    groupId: id,
                    count,
                  })
                );

                const hasNewMessages = counts.some((item) => item.count > 0);
                setIsNewMessage(hasNewMessages);
                setUnreadCountsForGroup(counts); // Update state after each snapshot

                if (selectedChat?.groupId === group?.groupId && count > 0) {
                  markGroupMessagesAsRead(group?.groupId);
                }
              }
            );

            unsubscribes.push(unsubscribe);
          }
        }
      } catch (error) {
        console.error('Error fetching unread message counts:', error);
      }
    };

    // Call markGroupMessagesAsRead when a new chat is selected/opened
    const markGroupMessagesAsRead = async (groupId) => {
      try {
        if (!groupId) return;

        const readStatusQuery = { id: user?.uid, status: false };
        const unreadMessagesQuery = query(
          collection(FirestoreDB, 'groupChats', groupId, 'groupMessages'),
          where('readStatus', 'array-contains', readStatusQuery)
        );

        const snapshot = await getDocs(unreadMessagesQuery);

        if (!snapshot.empty) {
          const batch = writeBatch(FirestoreDB);
          snapshot.forEach((docSnapshot) => {
            const messageData = docSnapshot.data();
            const updatedReadStatus = messageData.readStatus.map(
              (statusObj) => {
                if (statusObj.id === user?.uid) {
                  return { ...statusObj, status: true }; // Mark messages as read
                }
                return statusObj;
              }
            );

            batch.update(docSnapshot.ref, { readStatus: updatedReadStatus });
          });

          await batch.commit();

          // Reset unread count for the group immediately after marking as read
          countsObjRef.current[groupId] = 0;
          setUnreadCountsForGroup((prevCounts) =>
            prevCounts.map((countObj) =>
              countObj.groupId === groupId
                ? { ...countObj, count: 0 }
                : countObj
            )
          );
        }
      } catch (error) {
        console.error('Error marking group messages as read:', error);
      }
    };

    // Automatically fetch counts on groups change
    fetchGroupUnreadCounts();

    // Automatically mark selected chat as read
    if (selectedChat) {
      const groupId = selectedChat.groupId; // Ensure you get the groupId from selectedChat
      markGroupMessagesAsRead(groupId); // Pass the groupId to mark messages as read
    }

    // Cleanup subscriptions on component unmount
    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [groups, selectedChat, user?.uid]);

  useEffect(() => {
    if (currentPatient || currentDoctor || currentStudent || currentTeacher) {
      // user && fetchMessages();
    }

    if (
      ACM[typeOfUser]?.isDoctor &&
      patientData.length > 0 &&
      !currentPatient
    ) {
      const patientInfo = locationPatientId
        ? patientData.find(
            (patient) => patient?.patientId === locationPatientId
          )
        : patientData[0];
      setSelectedChat(patientInfo);
      showPatientConversation(patientInfo); // Choose the first patient by default
    }
    if (ACM[typeOfUser]?.isPatient && !currentDoctor) {
      const doctorInfo = locationDoctorId
        ? user?.doctorDetails.find(
            (doctor) => doctor?.doctorId === locationDoctorId
          )
        : user.doctorDetails[0];
      setSelectedChat(doctorInfo); // Choose the first doctor by default

      showDoctorConversation(doctorInfo);
    }
    if (ACM[typeOfUser]?.isStudent && !currentTeacher) {
      const teacherInfo = locationTeacherId
        ? teacherData.find((teacher) => teacher?.uid === locationTeacherId)
        : teacherData[0];
        setSelectedChat(teacherInfo);
      showTeacherConversation(teacherInfo);
    }
  }, [
    user,
    currentPatient,
    patientData,
    currentDoctor,
    locationPatientId,
    locationDoctorId,
    typeOfUser,
    ,
    currentStudent,
    studentData,
    currentTeacher,
    locationTeacherId,
    locationStudentId,
  ]);

  useEffect(() => {
    if (
      ACM[typeOfUser]?.isTeacher &&
      studentData.length > 0 &&
      !currentStudent
    ) {
      const studentInfo = locationStudentId
        ? studentData.find((student) => student?.id === locationPatientId)
        : studentData[0];
      setSelectedChat(studentInfo);
      showPatientConversation(studentInfo); // Choose the first patient by default
    }
  }, [user, currentStudent, patientData, locationStudentId, typeOfUser]);

  const filteredPatients = patientData?.filter((patient) =>
    patient?.patientInfo?.fullname
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const filteredStudents = studentData?.filter((student) =>
    student?.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const patientsWithUnread = filteredPatients?.filter((item) => {
    // First, filter patients with unread counts
    return unreadCounts.some(
      (countItem) =>
        countItem.patientId === item.patientId && countItem.count > 0
    );
  });

  const studentWithUnread = filteredStudents?.filter((item) => {
    // First, filter patients with unread counts
    return unreadCounts.some(
      (countItem) => countItem.id === item.id && countItem.count > 0
    );
  });
 
  const patientsWithoutUnread = filteredPatients?.filter((item) => {
    // Then, filter patients without unread counts
    return !unreadCounts.some(
      (countItem) =>
        countItem.patientId === item.patientId && countItem.count > 0
    );
  });

  const studnetWithoutUnread = filteredStudents?.filter((item) => {
    // Then, filter patients without unread counts
    return !unreadCounts.some(
      (countItem) => countItem.id === item.id && countItem.count > 0
    );
  });

  const sortedFilteredPatients = patientsWithUnread?.concat(
    patientsWithoutUnread
  );

  const sortedFilteredStudnet = studentWithUnread?.concat(studnetWithoutUnread);

  const filteredDoctors = user?.doctorDetails?.filter((doctor) =>
    doctor?.fullname?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // First, filter doctors with unread countssetUnreadCountssetUnreadCounts
  const doctorsWithUnread = filteredDoctors?.filter((doc) => {
    return unreadCounts.some(
      (countItem) => countItem.doctorId === doc.doctorId && countItem.count > 0
    );
  });

  // Then, filter doctors without unread counts
  const doctorsWithoutUnread = filteredDoctors?.filter((doc) => {
    return !unreadCounts.some(
      (countItem) => countItem.doctorId === doc.doctorId && countItem.count > 0
    );
  });

  // Concatenate the two filtered arrays, with doctors having unread counts first
  const sortedFilteredDoctors = doctorsWithUnread?.concat(doctorsWithoutUnread);

  useEffect(() => {
    if (scroll.current) {
      scroll.current.scrollTop = scroll.current.scrollHeight;
    }
  }, [messages]);

  if (loading) {
    return <Progress />;
  }

  const chatWithUnreadCount = unreadCounts.filter(
    (item) => item.count > 0
  ).length;
  const groupChatWithUnreadCount = unreadCountsForGroup.filter(
    (item) => item.count > 0
  ).length;

  const capitalizeWords = (name) => {
    return name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const truncateName = (name, limit = 10) => {
    const capitalized = capitalizeWords(name);
    if (capitalized?.length > limit) {
      return `${capitalized.slice(0, limit)}...`;
    }
    return capitalized;
  };

  const filteredTeachers = teacherData?.filter((teacher) =>
    teacher?.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const teacherWithUnread = filteredTeachers?.filter((item) => {
    // First, filter patients with unread counts
    return unreadCounts.some(
      (countItem) => countItem.id === item.id && countItem.count > 0
    );
  });
  const teacherWithoutUnread = filteredTeachers?.filter((item) => {
    // Then, filter patients without unread counts
    return !unreadCounts.some(
      (countItem) => countItem.id === item.patientId && countItem.count > 0
    );
  });
  const sortedFilteredTeacher = teacherWithUnread?.concat(teacherWithoutUnread);
 
  return (
    <MainLayout>
      <UnreadMessageCounts
        unreadCounts={unreadCounts}
        setUnreadCounts={setUnreadCounts}
        user={user}
        typeOfUser={typeOfUser}
        patientData={patientData}
        studentData={studentData}
        teacherData={teacherData}
        selectedChat={selectedChat}
      />

      <Box className='fullWidth fullHeight messaging'>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Card
              className='flexBox flexColumn'
              sx={{
                height: '89.5vh',
              }}
            >
              <CardHeader
                sx={{ p: 0 }}
                title={
                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    centered
                    textColor='secondary'
                    indicatorColor='secondary'

                    // className="tabs"
                  >
                    <Tab
                      label={
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <Chat />
                          <Typography
                            sx={{ marginLeft: 1, textTransform: 'capitalize' }}
                          >
                            Chats
                          </Typography>
                          {chatWithUnreadCount > 0 && (
                            <Chip
                              label={chatWithUnreadCount}
                              size='small'
                              sx={{
                                backgroundColor: 'var(--clr-theme-purple)',
                                color: 'var(--clr-theme-white)',
                              }}
                            />
                          )}
                        </Box>
                      }
                      iconPosition='start'
                      sx={{ flexGrow: 1, textTransform: 'capitalize' }}
                    />

                    <Tab
                      label={
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <Groups2 />
                          <Typography
                            sx={{ marginLeft: 1, textTransform: 'capitalize' }}
                          >
                            Group Chats
                          </Typography>
                          {groupChatWithUnreadCount > 0 && (
                            <Chip
                              label={groupChatWithUnreadCount}
                              size='small'
                              sx={{
                                backgroundColor: 'var(--clr-theme-purple)',
                                color: 'var(--clr-theme-white)',
                              }}
                            />
                          )}
                        </Box>
                      }
                      iconPosition='start'
                      // icon={<Groups2 />}
                      sx={{ flexGrow: 1, textTransform: 'capitalize' }}
                    />
                  </Tabs>
                }
              />

              <CardContent>
                {selectedTab === 0 && (
                  <>
                    <Box>
                      <TextField
                        placeholder={
                          // typeOfUser === "DOCTOR"
                          ACM[typeOfUser]?.isDoctor ||
                          ACM[typeOfUser]?.isTeacher
                            ? 'Search patient'
                            : ACM[typeOfUser]?.isTeacher
                            ? 'Search student'
                            : ACM[typeOfUser]?.isStudent
                            ? 'Search Teacher'
                            : 'Search healthcare provider'
                        }
                        fullWidth
                        sx={{ borderRadius: '10px' }}
                        value={searchQuery}
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          setErrorMessage('');
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment>
                              <IconButton aria-label='Search'>
                                <Search />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <List
                      component='div'
                      disablePadding
                      sx={{
                        overflowY: 'auto',
                        flexGrow: 1,
                        mt: 1,
                      }}
                    >
                      {ACM[typeOfUser]?.isTeacher
                        ? // Logic for teachers
                          sortedFilteredStudnet?.map((student, i) => (
                            <ListItemButton
                              sx={{
                                borderBottom: '1px solid lightgray',
                                height: 75,
                              }}
                              key={i}
                              onClick={() => showStudentConversation(student)}
                            >
                              <ListItemIcon>
                                {student.icon ||
                                  (student?.photoURL && (
                                    <Avatar
                                      alt={`Profile picture of ${student?.name}`}
                                      src={student?.photoURL}
                                    />
                                  )) || <Avatar />}
                              </ListItemIcon>
                              <ListItemText
                                primary={truncateName(student?.name)}
                              />
                              {unreadCounts &&
                                unreadCounts.map((countItem) => {
                                  if (
                                    countItem?.studentId === student?.id
                                  ) {
                                    return (
                                      countItem?.count > 0 && (
                                        <Chip
                                          label={countItem.count}
                                          color='secondary'
                                        />
                                      )
                                    );
                                  }
                                  return null;
                                })}
                            </ListItemButton>
                          ))
                        : ACM[typeOfUser]?.isDoctor
                        ? // Logic for doctors
                          sortedFilteredPatients?.map((patient, i) => (
                            <ListItemButton
                              sx={{
                                borderBottom: '1px solid lightgray',
                                height: 75,
                              }}
                              key={i}
                              onClick={() => showPatientConversation(patient)}
                            >
                              <ListItemIcon>
                                {patient.icon ||
                                  (patient?.patientInfo?.photoURL && (
                                    <Avatar
                                      alt={`Profile picture of ${patient?.patientInfo?.fullname}`}
                                      src={patient?.patientInfo?.photoURL}
                                    />
                                  )) || <Avatar />}
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  patient?.patientInfo?.parentName
                                    ? `${truncateName(
                                        patient.patientInfo.fullname
                                      )} (${truncateName(
                                        patient.patientInfo.parentName
                                      )})`
                                    : truncateName(patient.patientInfo.fullname)
                                }
                              />
                              {unreadCounts &&
                                unreadCounts.map((countItem) => {
                                  if (
                                    countItem.patientId === patient.patientId
                                  ) {
                                    return (
                                      countItem.count > 0 && (
                                        <Chip
                                          label={countItem.count}
                                          sx={{
                                            backgroundColor:
                                              'var(--clr-theme-purple)',
                                            color: 'var(--clr-theme-white)',
                                          }}
                                        />
                                      )
                                    );
                                  }
                                  return null;
                                })}
                            </ListItemButton>
                          ))
                        : 
                        ACM[typeOfUser]?.isStudent
                        ? 
                        sortedFilteredTeacher?.map((teacher, i) => (
                          <ListItemButton
                            sx={{
                              borderBottom: '1px solid lightgray',
                              height: 75,
                            }}
                            key={i}
                            onClick={() => showTeacherConversation(teacher)}
                          >
                            <ListItemIcon>
                              {teacher.icon ||
                                (teacher?.photoURL && (
                                  <Avatar
                                    alt={`Profile picture of ${teacher?.name}`}
                                    src={teacher?.photoURL}
                                  />
                                )) || <Avatar />}
                            </ListItemIcon>
                            <ListItemText
                              primary={truncateName(teacher?.name)}
                              // secondary={getChatName(teacher?.id)} // Shows chat name below teacher's name
                            />
                            {unreadCounts &&
                              unreadCounts.map((countItem) => {
                                if (countItem?.teacherId === teacher?.id) {
                                  return (
                                    countItem?.count > 0 && (
                                      <Chip label={countItem.count} color='secondary' />
                                    )
                                  );
                                }
                                return null;
                              })}
                          </ListItemButton>
                        ))
                        
                        : // Logic for patients
                          sortedFilteredDoctors?.map((doctor, i) => (
                            <ListItemButton
                              sx={{
                                borderBottom: '1px solid lightgray',
                                height: 75,
                              }}
                              key={i}
                              onClick={() => showDoctorConversation(doctor)}
                            >
                              <ListItemIcon>
                                {doctor.icon ||
                                  (doctor?.photoURL && (
                                    <Avatar
                                      alt={`Profile picture of ${doctor?.fullname}`}
                                      src={doctor?.photoURL}
                                    />
                                  )) || <Avatar />}
                              </ListItemIcon>
                              <ListItemText
                                primary={truncateName(doctor?.fullname)}
                              />
                              {unreadCounts &&
                                unreadCounts.map((countItem) => {
                                  if (
                                    countItem?.doctorId === doctor?.doctorId
                                  ) {
                                    return (
                                      countItem?.count > 0 && (
                                        <Chip
                                          label={countItem.count}
                                          color='secondary'
                                        />
                                      )
                                    );
                                  }
                                  return null;
                                })}
                            </ListItemButton>
                          ))}
                    </List>
                  </>
                )}
                {selectedTab === 1 && (
                  <>
                    <List>
                      <Button
                        variant='contained'
                        fullWidth
                        sx={{
                          backgroundColor: 'var(--clr-theme-purple)!important',
                          color: 'var(--clr-theme-white)',
                          textTransform: 'capitalize',
                          mb: 1,
                          // border: "1px solid var(--clr-theme-purple)",
                        }}
                        startIcon={<Groups />}
                        onClick={handleClickDialogOpen}
                      >
                        Create Group
                      </Button>
                      {groups?.map((group) => (
                        <>
                          <ListItemButton
                            sx={{
                              borderBottom: '1px solid lightgray',
                              height: 75,
                            }}
                            key={group.groupId}
                            onClick={() => showGroupConversation(group)}
                          >
                            <ListItemIcon>
                              <Groups2 />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                group.groupName
                                  ? group.groupName
                                      .split(' ')
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1).toLowerCase()
                                      )
                                      .join(' ')
                                  : ''
                              }
                              sx={{}}
                            />
                            {unreadCountsForGroup &&
                              unreadCountsForGroup?.map((countItem) => {
                                if (countItem.groupId === group.groupId) {
                                  return (
                                    countItem.count > 0 && (
                                      <Chip
                                        label={countItem.count}
                                        sx={{
                                          backgroundColor:
                                            'var(--clr-theme-purple)',
                                          color: 'var(--clr-theme-white)',
                                        }}
                                      />
                                    )
                                  );
                                }
                                return null;
                              })}
                          </ListItemButton>
                        </>
                      ))}
                    </List>

                    <Dialog
                      open={openDialog}
                      onClose={handleDialogClose}
                      maxWidth='lg'
                    >
                      <DialogTitle>
                        <TextField
                          fullWidth
                          label='Enter group name'
                          variant='outlined'
                          value={groupName}
                          onChange={handleGroupNameChange}
                          // sx={{ mb: 2 }}
                          error={!!errorMessage}
                          helperText={errorMessage}
                        />
                      </DialogTitle>
                      <DialogContent sx={{ width: '42vw' }}>
                        {ACM[typeOfUser]?.isTeacher
                          ? // Logic for teachers
                            studentData?.map((item, i) => (
                              <ListItemButton
                                sx={{
                                  borderBottom: '1px solid lightgray',
                                  height: 75,
                                }}
                                key={i}
                                className={
                                  selectedPatientId === item.id
                                    ? 'selected'
                                    : ''
                                }
                              >
                                <ListItemIcon>
                                  {item.icon ||
                                    (item?.photoURL && (
                                      <Avatar
                                        alt={`Profile picture of ${item?.name}`}
                                        src={item?.photoURL}
                                      />
                                    )) || <Avatar />}
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <BootstrapTooltip
                                      title={
                                        item?.parentName
                                          ? `${item.name} (${item.parentName})`
                                          : item.name
                                      }
                                    >
                                      <span style={{ cursor: 'pointer' }}>
                                        {' '}
                                        {/* Add a span to wrap the text */}
                                        {item?.parentName
                                          ? `${truncateName(
                                              item.name
                                            )} (${truncateName(
                                              item.parentName
                                            )})`
                                          : truncateName(item.name)}
                                      </span>
                                    </BootstrapTooltip>
                                  }
                                />
                                <Button
                                  onClick={() =>
                                    handlePatientCheckboxChange({
                                      id: item.id,
                                      name: item.name,
                                      parentName: item.parentName || '',
                                      photoURL: item?.photoURL,
                                      typeOfUser: 'STUDENT',
                                      // typeOfUser: ACM[typeOfUser]?.isPatient,
                                    })
                                  }
                                  variant={
                                    selectedPatients.find(
                                      (member) => item.id === member?.id
                                    )
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                  sx={{
                                    bgcolor: selectedPatients.find(
                                      (member) => item.id === member?.id
                                    )
                                      ? 'green'
                                      : 'transparent',
                                    color: selectedPatients.find(
                                      (member) => item.id === member?.id
                                    )
                                      ? 'white'
                                      : 'primary.main',
                                    '&:hover': {
                                      bgcolor: selectedPatients.find(
                                        (member) => item.id === member?.id
                                      )
                                        ? 'green'
                                        : 'transparent',
                                      // Set color when hovered (optional)
                                      color: selectedPatients.find(
                                        (member) => item.id === member?.id
                                      )
                                        ? 'white'
                                        : 'primary.main',
                                    },
                                  }}
                                >
                                  {selectedPatients.find(
                                    (member) => item.id === member?.id
                                  )
                                    ? 'Add'
                                    : 'Add'}
                                </Button>
                              </ListItemButton>
                            ))
                          : ACM[typeOfUser]?.isDoctor
                          ? // Logic for doctors
                            patientData?.map((item, i) => (
                              <ListItemButton
                                sx={{
                                  borderBottom: '1px solid lightgray',
                                  height: 75,
                                }}
                                key={i}
                                className={
                                  selectedPatientId === item.patientId
                                    ? 'selected'
                                    : ''
                                }
                              >
                                <ListItemIcon>
                                  {item.icon ||
                                    (item?.patientInfo?.photoURL && (
                                      <Avatar
                                        alt={`Profile picture of ${item?.patientInfo?.fullname}`}
                                        src={item?.patientInfo?.photoURL}
                                      />
                                    )) || <Avatar />}
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <BootstrapTooltip
                                      title={
                                        item?.patientInfo?.parentName
                                          ? `${item.patientInfo.fullname} (${item.patientInfo.parentName})`
                                          : item.patientInfo.fullname
                                      }
                                    >
                                      <span style={{ cursor: 'pointer' }}>
                                        {' '}
                                        {/* Add a span to wrap the text */}
                                        {item?.patientInfo?.parentName
                                          ? `${truncateName(
                                              item.patientInfo.fullname
                                            )} (${truncateName(
                                              item.patientInfo.parentName
                                            )})`
                                          : truncateName(
                                              item.patientInfo.fullname
                                            )}
                                      </span>
                                    </BootstrapTooltip>
                                  }
                                />
                                <Button
                                  onClick={() =>
                                    handlePatientCheckboxChange({
                                      id: item.patientId,
                                      name: item.patientInfo?.fullname,
                                      parentName:
                                        item.patientInfo?.parentName || '',
                                      photoURL: item.patientInfo?.photoURL,
                                      typeOfUser: 'PATIENT',
                                    })
                                  }
                                  variant={
                                    selectedPatients.find(
                                      (member) => item.patientId === member?.id
                                    )
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                  sx={{
                                    bgcolor: selectedPatients.find(
                                      (member) => item.patientId === member?.id
                                    )
                                      ? 'green'
                                      : 'transparent',
                                    color: selectedPatients.find(
                                      (member) => item.patientId === member?.id
                                    )
                                      ? 'white'
                                      : 'primary.main',
                                    '&:hover': {
                                      bgcolor: selectedPatients.find(
                                        (member) =>
                                          item.patientId === member?.id
                                      )
                                        ? 'green'
                                        : 'transparent',
                                      // Set color when hovered (optional)
                                      color: selectedPatients.find(
                                        (member) =>
                                          item.patientId === member?.id
                                      )
                                        ? 'white'
                                        : 'primary.main',
                                    },
                                  }}
                                >
                                  {selectedPatients.find(
                                    (member) => item.patientId === member?.id
                                  )
                                    ? 'Add'
                                    : 'Add'}
                                </Button>
                              </ListItemButton>
                            ))
                          : ACM[typeOfUser]?.isStudent
                          ? // Logic for students

                          sortedFilteredTeacher?.map((doc, i) => {
                            return (
                              <ListItemButton
                                sx={{
                                  borderBottom: '1px solid lightgray',
                                  height: 75,
                                }}
                                key={doc?.id}
                              >
                                <ListItemIcon>
                                  {doc.icon ||
                                    (doc?.photoURL && (
                                      <Avatar
                                        alt={`Profile picture of ${doc?.name}`}
                                        src={doc?.photoURL}
                                      />
                                    )) || <Avatar />}
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    doc?.name
                                      ? `${doc?.name
                                          .split(/\s+/)
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1).toLowerCase()
                                          )
                                          .join(' ')}`
                                      : ''
                                  }
                                />
                                <Button
                                  onClick={() => {
                                    handlePatientCheckboxChange({
                                      id: doc.id,
                                      name: doc?.name,
                                      photoURL: doc?.photoURL,
                                      typeOfUser: 'TEACHER',
                                      // typeOfUser: ACM[typeOfUser]?.isDoctor,
                                    });
                                  }}
                                  variant={
                                    selectedPatients.find(
                                      (member) => doc.id === member?.id
                                    )
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                  sx={{
                                    bgcolor: selectedPatients.find(
                                      (member) => doc.id === member?.id
                                    )
                                      ? 'green'
                                      : 'transparent',
                                    color: selectedPatients.find(
                                      (member) => doc.id === member?.id
                                    )
                                      ? 'white'
                                      : 'primary.main',
                                    '&:hover': {
                                      bgcolor: selectedPatients.find(
                                        (member) => doc.id === member?.id
                                      )
                                        ? 'green'
                                        : 'transparent',
                                      // Set color when hovered (optional)
                                      color: selectedPatients.find(
                                        (member) => doc.id === member?.id
                                      )
                                        ? 'white'
                                        : 'primary.main',
                                    },
                                  }}
                                >
                                  {selectedPatients.find(
                                    (member) => doc.id === member?.id
                                  )
                                    ? 'Add'
                                    : 'Add'}
                                </Button>
                              </ListItemButton>
                            );
                          })
                          : // Logic for patients
                            sortedFilteredDoctors?.map((doc, i) => {
                              return (
                                <ListItemButton
                                  sx={{
                                    borderBottom: '1px solid lightgray',
                                    height: 75,
                                  }}
                                  key={doc?.doctorId}
                                >
                                  <ListItemIcon>
                                    {doc.icon ||
                                      (doc?.photoURL && (
                                        <Avatar
                                          alt={`Profile picture of ${doc?.fullname}`}
                                          src={doc?.photoURL}
                                        />
                                      )) || <Avatar />}
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      doc?.fullname
                                        ? `${doc?.fullname
                                            .split(/\s+/)
                                            .map(
                                              (word) =>
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1).toLowerCase()
                                            )
                                            .join(' ')}`
                                        : ''
                                    }
                                  />
                                  <Button
                                    onClick={() => {
                                      handlePatientCheckboxChange({
                                        id: doc.doctorId,
                                        name: doc?.fullname,
                                        photoURL: doc?.photoURL,
                                        typeOfUser: 'DOCTOR',
                                      });
                                    }}
                                    variant={
                                      selectedPatients.find(
                                        (member) => doc.doctorId === member?.id
                                      )
                                        ? 'contained'
                                        : 'outlined'
                                    }
                                    sx={{
                                      bgcolor: selectedPatients.find(
                                        (member) => doc.doctorId === member?.id
                                      )
                                        ? 'green'
                                        : 'transparent',
                                      color: selectedPatients.find(
                                        (member) => doc.doctorId === member?.id
                                      )
                                        ? 'white'
                                        : 'primary.main',
                                      '&:hover': {
                                        bgcolor: selectedPatients.find(
                                          (member) =>
                                            doc.patientId === member?.id
                                        )
                                          ? 'green'
                                          : 'transparent',
                                        // Set color when hovered (optional)
                                        color: selectedPatients.find(
                                          (member) =>
                                            doc.patientId === member?.id
                                        )
                                          ? 'white'
                                          : 'primary.main',
                                      },
                                    }}
                                  >
                                    {selectedPatients.find(
                                      (member) => doc.doctorId === member?.id
                                    )
                                      ? 'Add'
                                      : 'Add'}
                                  </Button>
                                </ListItemButton>
                              );
                            })}
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleDialogClose}
                          sx={{
                            color: 'var(--clr-theme-purple)!important',
                            textTransform: 'capitalize',
                            fontFamily: 'Lato',
                            fontSize: '1rem',
                          }}
                        >
                          Cancel
                        </Button>

                        <Button
                          onClick={handleCreateGroup}
                          variant='contained'
                          color='primary'
                          sx={{
                            backgroundColor:
                              'var(--clr-theme-purple)!important',
                            textTransform: 'capitalize',
                            fontFamily: 'Lato',
                            fontSize: '1rem',
                          }}
                        >
                          Create Group
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            {/* {typeOfUser === 'TEACHER' ? ( */}
            {(ACM[typeOfUser]?.isTeacher ||ACM[typeOfUser]?.isStudent) ? (
              <Conversations
                onChatNameUpdate={(newName) =>
                  setSelectedChat({ ...selectedChat, groupName: newName })
                }
                unreadCounts={unreadCounts}
                selectedChat={selectedChat}
                unreadCountsForGroup={unreadCountsForGroup}
                key={selectedChat?.id || selectedChat?.groupId || ''}
                chatId={selectedChat?.id || selectedChat?.groupId || ''}
                chatPhotoURL={selectedChat?.photoURL || null}
                chatName={
                  selectedChat?.parentName
                    ? `${selectedChat?.name} (${selectedChat?.parentName})`
                    : selectedChat?.name || selectedChat?.groupName
                }
                isGroupChat={Boolean(selectedChat?.groupName)}
                chatUserInfo={selectedChat}
                // navigateProfile={goToPatient}
              />
            ) : (
              <Conversations
                onChatNameUpdate={(newName) =>
                  setSelectedChat({ ...selectedChat, groupName: newName })
                }
                unreadCounts={unreadCounts}
                selectedChat={selectedChat}
                unreadCountsForGroup={unreadCountsForGroup}
                key={
                  selectedChat?.patientId ||
                  selectedChat?.groupId ||
                  selectedChat?.doctorId
                }
                chatId={
                  selectedChat?.patientId ||
                  selectedChat?.groupId ||
                  selectedChat?.doctorId
                }
                chatPhotoURL={selectedChat?.patientInfo?.photoURL}
                chatName={
                  selectedChat?.patientInfo?.parentName
                    ? `${selectedChat?.patientInfo.fullname
                        .trim()
                        .split(/\s+/)
                        .map(
                          (word) =>
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase()
                        )} `
                    : selectedChat?.patientInfo?.fullname ||
                      selectedChat?.groupName ||
                      selectedChat?.fullname
                }
                isGroupChat={selectedChat?.groupName ? true : false}
                chatUserInfo={selectedChat}
                navigateProfile={goToPatient}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </MainLayout>
  );
};

const Chatconversation = ({ message, typeOfUser, user }) => {
  const { ACM } = useContext(ACMContext);
  if (!message.description) {
    return null;
  }

  // Determine if the message is from the doctor or the patient
  const isDoctorMessage = message?.notificationSource === 'doctorMessage';
  const isPatientMessage = message?.notificationSource === 'patientMessage';

  // Determine the alignment of the message content
  // const contentAlignment = isDoctorMessage ? "flex-end" : "flex-start";
  const isCurrentUserMessage =
    (ACM[typeOfUser]?.isDoctor && message.sentBy === user.email) ||
    (ACM[typeOfUser]?.isPatient && message.sentBy === user.email)||
    (ACM[typeOfUser]?.isTeacher && message.sentBy === user.email) ||
    (ACM[typeOfUser]?.isStudent && message.sentBy === user.email);

  // Determine the alignment of the message content
  const contentAlignment = isCurrentUserMessage ? 'flex-end' : 'flex-start';

  // Convert createdDate to a human-readable format
  const formattedDate = new Date(
    message?.createdDate?.seconds * 1000 ||
      message?.createdDate?._seconds * 1000
  ).toLocaleString();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isCurrentUserMessage ? 'row-reverse' : 'row',
        alignItems: contentAlignment,
        padding: '10px',

        marginBottom: 5,
      }}
    >
      <img
        src={
          message?.userPhotoURL ||
          'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
        }
        alt='User Avatar'
        style={{ width: '30px', height: '30px', borderRadius: '50%' }}
      />
      <div
        style={{
          marginLeft: '10px',
          width: '70%',
          backgroundColor: 'var(--clr-theme-purple-20)',
          padding: '10px',
          borderRadius: isDoctorMessage
            ? '20px 5px 20px 20px'
            : '20px 20px 20px 5px',
        }}
      >
        <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>
          {message?.userName
            .split(/\s+/)
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(' ') || 'NA'}
        </p>
        <p style={{ color: '#454545', margin: '0' }}>{message?.description}</p>
        <p
          style={{
            fontSize: '12px',
            color: '#999',
            margin: '0',
            marginTop: '5px',
          }}
        >
          {formattedDate}
        </p>
      </div>
    </div>
  );
};

export default Message;
