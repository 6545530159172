import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { DataContext } from '../contexts/DataProvider';

const useOnePatientVideos = (patientUid) => {
  const { fetchOnePatientAllVideos } = useContext(DataContext);
  return useQuery({
    queryKey: ['onePatientsVideos', patientUid],
    queryFn: () => fetchOnePatientAllVideos(patientUid),
    enabled: !!patientUid,
    staleTime: 60000, // 1 min 
    cacheTime: 300000, // 5 min
  });
};

export default useOnePatientVideos;
