import { useContext, useEffect } from 'react';
import { FirestoreDB } from '../../utils/firebase/firebase';
import {
  collection,
  query,
  where,
  onSnapshot,
  updateDoc,
  doc,
} from 'firebase/firestore';
import { UIContext } from '../../contexts/UIProvider';
import { ACMContext } from '../../contexts/ACMProvider';

const UnreadMessageCounts = ({
  unreadCounts,
  setUnreadCounts,
  user,
  typeOfUser,
  patientData,
  studentData,
  teacherData,
  selectedChat, // To identify the currently opened conversation
}) => {
  const { ACM } = useContext(ACMContext);
  const { setIsNewMessage } = useContext(UIContext);

  useEffect(() => {
    const unsubscribes = [];
    const countsObj = {};

    const fetchUnreadCounts = () => {
      try {
        const userMessage = ACM[typeOfUser]?.isDoctor
          ? 'patientMessage'
          : ACM[typeOfUser]?.isTeacher
          ? 'studentMessage'
          : ACM[typeOfUser]?.isStudent
          ? 'teacherMessage'
          : 'doctorMessage';

        // Query to fetch unread messages
        const unreadMessagesQuery = query(
          collection(FirestoreDB, 'notifications', user?.uid, 'messages'),
          where('readStatus', '==', false),
          where('notificationSource', '==', userMessage)
        );

        const unsubscribe = onSnapshot(unreadMessagesQuery, (snapshot) => {
          const countsObj = {};
          snapshot.forEach((doc) => {
            const messageData = doc.data();
            const userId = messageData.userId; // ID of the sender

            let matchedUser;
            if (ACM[typeOfUser]?.isDoctor) {
              matchedUser = patientData.find(
                (patient) => patient.patientId === userId
              );
            } else if (ACM[typeOfUser]?.isPatient) {
              matchedUser = user.doctorDetails.find(
                (doctor) => doctor.doctorId === userId
              );
            } else if (ACM[typeOfUser]?.isStudent) {
              matchedUser = teacherData.find(
                (teacher) => teacher.id === userId
              );
            } else if (ACM[typeOfUser]?.isTeacher) {
              matchedUser = studentData.find(
                (student) => student.id === userId
              );
            }

            if (matchedUser) {
              const id = ACM[typeOfUser]?.isDoctor
                ? matchedUser.patientId
                : ACM[typeOfUser]?.isTeacher
                ? matchedUser.doctorId // teacher -> student
                : ACM[typeOfUser]?.isStudent
                ? matchedUser.studentId // student -> teacher
                : matchedUser.doctorId;

              // Increment count for the corresponding user
              countsObj[id] = (countsObj[id] || 0) + 1;
            }
          });

          // Transform countsObj into the format required for unreadCounts
          const counts = Object.entries(countsObj).map(([id, count]) => ({
            [ACM[typeOfUser]?.isDoctor
              ? 'patientId'
              : ACM[typeOfUser]?.isTeacher
              ? 'studentId'
              : ACM[typeOfUser]?.isStudent
              ? 'teacherId'
              : 'doctorId']: id,
            count,
          }));

          const hasNewMessages = counts.some((item) => item.count > 0);
          setIsNewMessage(hasNewMessages);
          setUnreadCounts(counts);
        });

        unsubscribes.push(unsubscribe);
      } catch (error) {
        console.error('Error fetching unread message counts:', error);
      }
    };

    const markMessagesAsRead = async () => {
      if (!selectedChat) return;

      try {
        const userId = ACM[typeOfUser]?.isDoctor
          ? selectedChat.patientId
          : ACM[typeOfUser]?.isTeacher
          ? selectedChat.id
          : ACM[typeOfUser]?.isStudent
          ? selectedChat.id
          : selectedChat.doctorId;

        // Query to fetch unread messages for the selected chat
        const unreadMessagesQuery = query(
          collection(FirestoreDB, 'notifications', user?.uid, 'messages'),
          where('readStatus', '==', false),
          where('userId', '==', userId)// Fetch messages from the selected user based on userId
        );

        const unsubscribe = onSnapshot(unreadMessagesQuery, (snapshot) => {
          snapshot.forEach(async (docSnapshot) => {
            // Update each message's read status to true
            const messageRef = doc(
              FirestoreDB,
              'notifications',
              user?.uid,
              'messages',
              docSnapshot.id
            );
            await updateDoc(messageRef, { readStatus: true });
          });

          // Reset the unread count for this chat to zero
          setUnreadCounts((prevCounts) =>
            prevCounts.map((countObj) =>
              countObj[
                ACM[typeOfUser]?.isDoctor
                  ? 'patientId'
                  : ACM[typeOfUser]?.isTeacher
                  ? 'studentId'
                  : ACM[typeOfUser]?.isStudent
                  ? 'teacherId'
                  : 'doctorId'
              ] === userId
                ? { ...countObj, count: 0 }
                : countObj
            )
          );
        });

        unsubscribes.push(unsubscribe);
      } catch (error) {
        console.error('Error marking messages as read:', error);
      }
    };

    fetchUnreadCounts();
    if (selectedChat) {
      markMessagesAsRead();
    }

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [user, typeOfUser, patientData, teacherData, studentData, selectedChat]);

  return null;
};

export default UnreadMessageCounts;
