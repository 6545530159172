import React, { useContext, useEffect, useState } from 'react';
import MainLayout from '../../hoc/MainLayout';
import AuthContext from '../../contexts/AuthContext';
import { updateSchoolAdmintProfileDetails } from '../../api';
import useOrganizationData from '../../hooks/useOrganizationData';
import useSchoolAdminsData from '../../hooks/useSchoolAdminsData';
import useSchoolsData from '../../hooks/useSchoolsData';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
  TableRow,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import { FirestoreDB } from '../../utils/firebase/firebase';
import { Edit, Delete } from '@mui/icons-material';
const SchoolAdminPage = () => {
  const { user, typeOfUser } = useContext(AuthContext);
  const [editIndex, setEditIndex] = useState(null);
  const [orgName, setOrgName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [schoolAdminIndexDelete, setSchoolAdminIndexDelete] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    school: '',
    schoolId: '',
    status: true,
  });
  const {
    data: schools = [],
    isLoading: isSchoolsLoading,
    error: schoolsError,
    refetch: refetchSchools,
  } = useSchoolsData();

  const {
    data: fetchOrgData = [],
    isLoading: isOrgLoading,
    error: orgError,
    refetch: refetchOrgData,
  } = useOrganizationData(user?.uid, typeOfUser);

  const {
    data: schoolAdmins = [],
    isLoading: isSchoolAdminsLoading,
    error: schoolAdminsError,
    refetch: refetchSchoolAdmins,
  } = useSchoolAdminsData(user?.uid, typeOfUser);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'school') {
      const selectedSchool = schools.find((school) => school.name === value);
      setFormData((prev) => ({
        ...prev,
        school: value,
        schoolId: selectedSchool?.id || '',
      }));
    } else if (name === 'status') {
      setFormData((prev) => ({ ...prev, status: checked }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleEdit = (id) => {
    const schoolAdminsToEdit = schoolAdmins.find((admin) => admin.id === id);

    if (!schoolAdminsToEdit) {
      console.error(`Admin with id ${id} not found.`);
      return;
    }

    setFormData({
      name: schoolAdminsToEdit.name || '',
      email: schoolAdminsToEdit.email || '',
      school: schoolAdminsToEdit.schoolName || '',
      schoolId: schoolAdminsToEdit.schoolId || '',
      // status: schoolAdminsToEdit.status !== undefined ? schoolAdminsToEdit.status : true,
    });

    const index = schoolAdmins.findIndex((admin) => admin.id === id);
    setEditIndex(index);
    setOpenDialog(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedSchool = schools.find(
      (school) => school.schoolName === formData.school
    );

    if (editIndex !== null) {
      const AdminUid = schoolAdmins[editIndex]?.id;

      try {
        const updatedAdmin = {
          fullname: formData.name,
          schoolName: formData.school,
          schoolId: selectedSchool?.schoolId || selectedSchool?.id || '',
          email: formData.email,
          timestamp: new Date(),
        };
        const result = await updateSchoolAdmintProfileDetails(
          btoa(JSON.stringify(updatedAdmin)),
          AdminUid
        );

        if (result) {
          // updateGlobalUser({ ...user, ...formData });

          // setFetchStudent((prevStudents) =>
          //   prevStudents.map((student) =>
          //     student.id === editIndex ? { ...student, ...updatedStudent } : student
          //   )
          // );

          resetStates();
        } else {
          console.error('API returned an error or no result');
        }
      } catch (error) {
        console.error('Error updating student profile:', error);
      }
    } else {
      const params = {
        fromEmail: user?.email,
        inviteType: 'SCHOOLADMIN',
        message: '',
        fullname: formData.name,
        schoolName: formData.school,
        schoolId: selectedSchool?.schoolId || selectedSchool?.id || '',
        timestamp: new Date(),
        toEmail: formData.email,
      };
      try {
        const docRef = await addDoc(
          collection(FirestoreDB, 'invitations'),
          params
        );
        // setSchoolAdmins((prev) => [...prev, formData]);
      } catch (e) {
        console.error('Error adding document: ', e);
      }
      // setSchoolAdmins((prev) => [...prev, formData]);
    }
    await refetchSchoolAdmins();
    setOpenDialog(false);
    if (editIndex === null)
      toast.success('School Admin added successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    else
      toast.success('School Admin updated successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    resetStates();
  };

  const resetStates = () => {
    setFormData({
      name: '',
      email: '',
      school: '',
      schoolId: '',
      // status: true,
    });
  };

  const handleDelete = async (id) => {
    const teacherDocRef = doc(FirestoreDB, 'schoolAdminsList', id);

    await deleteDoc(teacherDocRef);

    await refetchSchoolAdmins();
    toast.success('School Admin deleted successfully.', {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const isFormValid = formData.name && formData.email && formData.school;
  const SkeletonRow = () => {
    return (
      <TableRow>
        <TableCell>
          <Skeleton variant='text' width={150} />
        </TableCell>

        <TableCell>
          <Skeleton variant='text' width={200} />
        </TableCell>

        <TableCell>
          <Skeleton variant='text' width={150} />
        </TableCell>

        <TableCell>
          <Skeleton
            variant='rectangular'
            width={50}
            height={25}
            style={{ marginRight: '10px' }}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <MainLayout>
      <Box sx={{ padding: '20px' }}>
        <Typography variant='h4' gutterBottom>
          School Admins
        </Typography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
          }}
        >
          <Button
            variant='contained'
            color='primary'
            onClick={() => setOpenDialog(true)}
          >
            + Add School Admin
          </Button>
        </div>

        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  School Name
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schoolAdmins.length > 0 ? (
                schoolAdmins.map((admin, index) => (
                  <TableRow key={index}>
                    <TableCell>{admin.name}</TableCell>
                    <TableCell>{admin.email}</TableCell>
                    <TableCell>{admin.schoolName}</TableCell>
                    <TableCell>
                      <Tooltip title='Edit School Admin Information'>
                        <Button onClick={() => handleEdit(admin.id)}>
                          <Edit sx={{ color: 'black' }} />
                        </Button>
                      </Tooltip>
                      <Tooltip title='Delete School Admins'>
                        <Button
                          onClick={() => {
                            setDeleteModal(true);
                            setSchoolAdminIndexDelete(admin.id);
                          }}
                        >
                          <Delete sx={{ color: 'red' }} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : isSchoolAdminsLoading || isSchoolsLoading || isOrgLoading ? (
                [...Array(5)].map((_, index) => <SkeletonRow key={index} />)
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align='center'>
                    No School Admins yet. Add your first school admin above.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>
            {editIndex !== null ? 'Edit Teacher' : 'Add Teacher'}
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Name'
                    name='name'
                    value={formData.name}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Email'
                    name='email'
                    value={formData.email}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    label='School'
                    name='school'
                    value={formData.school}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  >
                    {schools.map((school) => (
                      <MenuItem key={school.id} value={school.schoolName}>
                        {school.schoolName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    label='Organization Name'
                    fullWidth
                    variant='outlined'
                    value={orgName}
                    onChange={(e) => setOrgName(e.target.value)}
                  >
                    {fetchOrgData.map((organisation) => (
                      <MenuItem
                        key={organisation.id}
                        value={organisation.organizationName}
                      >
                        {organisation.organizationName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.status}
                        onChange={handleInputChange}
                        name='status'
                        color='primary'
                        disabled={isAddForm}
                      />
                    }
                    label={formData.status ? 'Active' : 'Inactive'}
                  />
                </Grid> */}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setFormData({
                    name: '',
                    email: '',
                    school: '',
                    schoolId: '',
                    // status: true,
                  });
                  setEditIndex(null);
                  setOpenDialog(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                variant='contained'
                color='primary'
                disabled={!isFormValid}
              >
                {editIndex !== null
                  ? 'Update School Admin'
                  : 'Add School Admin'}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
      <Dialog open={deleteModal} onClose={() => setSchoolAdminIndexDelete(0)}>
        <DialogContent>
          <Typography
            sx={{
              color: 'var(--clr-theme-gray)',
              fontFamily: 'Karla',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            Are you sure you want to delete this school admin?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='success'
            onClick={() => setDeleteModal(false)}
            sx={{
              backgroundColor: '#D3D3D3',
              textTransform: 'capitalize',
              fontFamily: 'Lato',
              fontWeight: 400,
              fontSize: '1.2rem',
              width: '100%',
            }}
          >
            No
          </Button>
          <Button
            variant='contained'
            color='success'
            onClick={() => {
              handleDelete(schoolAdminIndexDelete);
              setDeleteModal(false);
            }}
            sx={{
              backgroundColor: '#9291f0',
              textTransform: 'capitalize',
              fontFamily: 'Lato',
              fontWeight: 400,
              fontSize: '1.2rem',
              width: '100%',
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </MainLayout>
  );
};

export default SchoolAdminPage;
