import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import MainLayout from '../../hoc/MainLayout';
import AuthContext from '../../contexts/AuthContext';
import { fetchingTeachersAndStudentsListForSchoolAdmin } from '../../api';
import { ACMContext } from '../../contexts/ACMProvider';
const AdminTeacher = () => {
  const { ACM } = useContext(ACMContext);
  const { user, typeOfUser } = useContext(AuthContext);
  const [fetchedTeachers, setFetchedTeachers] = useState([]);

  const fetchTeachersData = async () => {
    if (ACM[typeOfUser]?.isSchoolAdmin) {
      try {
        const fetchedData = await fetchingTeachersAndStudentsListForSchoolAdmin(
          user?.uid,
          'SCHOOLADMIN'
        );
        setFetchedTeachers(fetchedData);
      } catch (err) {
        console.log('Error fetching students:', err);
      }
    }
  };

  useEffect(() => {
    if (ACM[typeOfUser]?.isSchoolAdmin) {
      fetchTeachersData();
    }
  }, [user, typeOfUser]);

  return (
    <MainLayout>
      <Box sx={{ padding: '20px' }}>
        <Typography variant='h4' gutterBottom>
          Teachers
        </Typography>

        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>
                  Teacher Name
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  Teacher Email
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  School Name
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fetchedTeachers?.teachersList?.length > 0 ? (
                fetchedTeachers?.teachersList?.map((teacher, index) => (
                  <TableRow key={index}>
                    <TableCell>{teacher?.info?.fullname}</TableCell>
                    <TableCell>{teacher?.info?.email}</TableCell>
                    <TableCell>{teacher?.info?.schoolName}</TableCell>
                    {/* <TableCell>{grade}</TableCell> */}
                    {/* <TableCell>
                      {teacher.status ? 'Active' : 'Inactive'}
                    </TableCell> */}
                    {/* <TableCell>{teacher.specializations.join(', ')}</TableCell> */}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align='center'>
                    No teachers data.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </MainLayout>
  );
};

export default AdminTeacher;
