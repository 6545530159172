import { Box, Tabs, Tab } from '@mui/material';
import React, { useState } from 'react';
import MainLayout from '../../hoc/MainLayout';
import RolePermissions from './RolePermissions';
import UserPermissions from './UserPermissions';
import './Permissions.css';
const AdminPermissions = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <MainLayout>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant='fullWidth'
          aria-label='Permissions Tabs'
          className='custom-tabs'
        >
          <Tab label='User Permissions' className='custom-tab' />
          {/* <Tab label='Role Permissions' className='custom-tab' /> */}
        </Tabs>
        <Box sx={{ p: 3 }}>
          {selectedTab === 0 && <UserPermissions />}
          {/* {selectedTab === 1 && <RolePermissions />} */}
        </Box>
      </Box>
    </MainLayout>
  );
};

export default AdminPermissions;
