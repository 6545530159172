import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  Tooltip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  tooltipClasses,
  styled,
  Box,
} from '@mui/material';
import AuthContext from '../../contexts/AuthContext';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { FaCalendarAlt } from 'react-icons/fa';
import useStudentsForOneTeacher from '../../hooks/useStudentsForOneTeacher';
import './ViewAllVideos/ViewAllVideos.css';
import './Home.css';
import { mockData } from '../TeacherDashboard/enrolledStudentsMockData';
import { PlayCircle } from '@mui/icons-material';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import ViewAllVideos from './ViewAllVideos';

const VideosListTeacher = () => {
  const [selectedStudent, setSelectedStudent] = useState('All');
  const { user, typeOfUser } = useContext(AuthContext);
  const [dateRange, setDateRange] = useState([null, null]);

  const handleDateChange = (range) => {
    setDateRange(range || [null, null]);
  };

  const {
    data: fetchStudentsForOneTeacher = [],
    isLoading: isTeachersLoading,
    error: teachersError,
    refetch: refetchTeachers,
  } = useStudentsForOneTeacher(user?.uid, typeOfUser);

  const enrolledStudents = useMemo(
    () => fetchStudentsForOneTeacher || [],
    [fetchStudentsForOneTeacher]
  );

  const handleSelectStudent = (value) => {
    setSelectedStudent(value);
  };

  const truncateName = (name, limit = 10) => {
    const capitalized = capitalizeWords(name);
    if (capitalized?.length > limit) {
      return `${capitalized.slice(0, limit)}...`;
    }
    return capitalized;
  };

  const capitalizeWords = (name) => {
    return name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  return (
    <>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        flexWrap='wrap'
        sx={{ paddingBottom: 2 }}
      >
        <Typography
          variant='h5'
          sx={{
            fontFamily: 'Lato',
            fontWeight: 700,
            color: 'var(--clr-theme-purple)',
          }}
        >
          New Videos
        </Typography>

        <Box
          display='flex'
          alignItems='center'
          gap={2}
          className='video-filters'
          sx={{ flexWrap: 'wrap' }}
        >
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id='demo-simple-select-label'>
              Select Student
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={selectedStudent}
              label='Select Student'
              onChange={(event) => handleSelectStudent(event.target.value)}
            >
              <MenuItem value='All'>All Students</MenuItem>
              {enrolledStudents.map((student) => (
                <MenuItem key={student.studentId} value={student.studentId}>
                  <Tooltip title={student?.name}>
                    <span>{truncateName(student?.name)}</span>
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <DateRangePicker
            value={dateRange}
            onChange={handleDateChange}
            format='MM/dd/yyyy'
            maxDetail='month'
            calendarIcon={<FaCalendarAlt />}
            dayPlaceholder='dd'
            monthPlaceholder='mm'
            yearPlaceholder='yyyy'
            rangeDivider=' to '
          />
        </Box>
      </Box>

      <TableContainer style={{ marginTop: '20px', maxHeight: '200px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Video Title</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Uploaded On</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {enrolledStudents.length > 0 ? (
              enrolledStudents.map((student, index) => (
                <TableRow key={index}>
                  <TableCell>{student?.name}</TableCell>
                  <TableCell>{student?.goal}</TableCell>
                  <TableCell>{student?.deadline}</TableCell>
                  <TableCell>
                    <Button
                      className='playButton'
                      //     onClick={() => {
                      //       handleVideoOpen(video, video.user.id);
                      //     }}
                    >
                      <PlayCircle />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align='center'>
                  No videos yet.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default VideosListTeacher;
