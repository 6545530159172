import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import FallbackScreen from '../components/FallbackScreen';
import Appointments from '../pages/Appointments';
import PatientOverview from '../pages/PatientOverview';
import Settings from '../pages/Settings';
import Termcondition from '../pages/Termcondition';
import Privacy from '../pages/Privacy';
import PrivateRoute from './PrivateRoute';
import MainPage from '../pages/Updated_UI';
import Statistics from '../pages/Statistics';
import ContactInformation from '../pages/ContactInformation';
import PrivacyLandingpage from '../pages/Updated_UI/PrivacyLandingpage';
import Message from '../pages/Messaging';
import UserGuide from '../pages/Userguide';
import Historical from '../pages/Historical';
import Smartsearch from '../pages/Historical/Smartsearch';
import TermConditionLandingpage from '../pages/Updated_UI/TermconditionLandingpage';
import AdminTermsPoliclies from '../pages/AdminTermsPolicies/AdminTermsPolicies';
import EditPolicy from '../pages/AdminTermsPolicies/EditPolicy';
import SchoolPage from '../pages/Schools';
import TeachersPage from '../pages/Teachers';
import StudentsPage from '../pages/Students';
import SchoolAdminPage from '../pages/SchoolsAdmin';
import Invite from '../pages/Updated_UI/Invite';
import SchoolAdminHome from '../pages/SchoolAdmin';
import AdminTeacher from '../pages/AdminTeacher';
import AdminStudent from '../pages/AdminStudent';
import StudentHomeDashboard from '../pages/StudentDashboard';
import OrganizationPage from '../pages/Organization';
import SchoolAdmins from '../pages/OrganizationAdminDashboad/SchoolAdmins';
import OrganizationSchools from '../pages/OrganizationAdminDashboad/Schools';
import TeacherDashboard from '../pages/TeacherDashboard';
import AdminPermissions from '../pages/AdminPermissions';
import StudentOverview from '../pages/StudentOverview';
import AddGoalDetails from '../pages/AddNewGoals/AddGoals';

import ViewAllGoals from '../pages/TeacherDashboard/Goals/viewAllGoals';

const HomePage = React.lazy(() => import('../pages/Home'));
const AdminHomePage = React.lazy(() => import('../pages/Admin/'));
const ProfilePage = React.lazy(() => import('../pages/Profile'));
const AppRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<FallbackScreen />}>
        <Routes>
          <Route exact path='/main' element={<MainPage />} />
          <Route exact path='/invite/:id' element={<Invite />} />
          <Route exact path='/termandcondition' element={<ProfilePage />} />

          <Route exact path='/profile' element={<ProfilePage />} />
          <Route
            exact
            path='/smartsearch'
            element={
              <PrivateRoute>
                <Smartsearch />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path='/'
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/admin'
            element={
              <PrivateRoute>
                <AdminHomePage />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path='/student'
            element={
              <PrivateRoute>
                <StudentHomeDashboard />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/admin/schools'
            element={
              <PrivateRoute>
                <SchoolPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/admin/organization'
            element={
              <PrivateRoute>
                <OrganizationPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/admin/schoolsAdmin'
            element={
              <PrivateRoute>
                <SchoolAdminPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/admin/teachers'
            element={
              <PrivateRoute>
                <TeachersPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/admin/students'
            element={
              <PrivateRoute>
                <StudentsPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/admin/permissions'
            element={
              <PrivateRoute>
                <AdminPermissions />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/admin/terms'
            element={
              <PrivateRoute>
                <AdminTermsPoliclies />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/admin/editPolicies'
            element={
              <PrivateRoute>
                <EditPolicy />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/appointments'
            element={
              <PrivateRoute>
                <Appointments />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/statistics'
            element={
              <PrivateRoute>
                <Statistics />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/historical'
            element={
              <PrivateRoute>
                <Historical />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/message'
            element={
              <PrivateRoute>
                <Message />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/settings'
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/Termcondition'
            element={
              <PrivateRoute>
                <Termcondition />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/Privacy'
            element={
              <PrivateRoute>
                <Privacy />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/Contact'
            element={
              <PrivateRoute>
                <ContactInformation />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/Userguide'
            element={
              <PrivateRoute>
                <UserGuide />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/patient/:id'
            element={
              <PrivateRoute>
                <PatientOverview />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/student/:id'
            element={
              <PrivateRoute>
                <StudentOverview />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/privacy-policy'
            element={
              <PrivacyLandingpage
              // footerRequired={true}
              />
            }
          />
          <Route
            exact
            path='/schoolAdmin'
            element={
              <PrivateRoute>
                <SchoolAdminHome />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/teachers'
            element={
              <PrivateRoute>
                <AdminTeacher />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path='/viewgoals'
            element={
              <PrivateRoute>
                <ViewAllGoals />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/students'
            element={
              <PrivateRoute>
                <AdminStudent />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/add-goal'
            element={
              <PrivateRoute>
                <AddGoalDetails />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/terms-and-conditions'
            element={<TermConditionLandingpage />}
          />
          <Route
            exact
            path='/organizationAdmin/schools'
            element={
              <PrivateRoute>
                <OrganizationSchools />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path='/organizationAdmin/schoolAdmins'
            element={
              <PrivateRoute>
                <SchoolAdmins />
              </PrivateRoute>
            }
          />

          <Route path='/*' element={<Navigate to='/' replace />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;