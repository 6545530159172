import React, { useContext } from 'react';
import { Box, Typography, Fab } from '@mui/material';
import AuthContext from '../../contexts/AuthContext';

import { ACMContext } from '../../contexts/ACMProvider';
import MainLayout from '../../hoc/MainLayout';
import Appointments from '../Appointments';
import HomeCard from '../../components/HomeCard';
import './TeacherDashboard.css';
import GoalsOverview from './GoalsOverview';
import TeacherStats from './TeacherStats';
import VideosListTeacher from '../Home/VideosListTeacher';
import FullScreenDialog from '../../components/FullScreenDialog';
import ViewAllVideos from '../Home/ViewAllVideos';
import { useNavigate } from 'react-router-dom';
import { UIContext } from '../../contexts/UIProvider';
import '../Home/Home.css';

const TeacherDashboard = () => {
  const { ACM } = useContext(ACMContext);
  const { typeOfUser } = useContext(AuthContext);
  const { updateSelectedMenu, setSelectedMenuKey } = useContext(UIContext);
  const navigate = useNavigate();

  return (
    <MainLayout>
      <Box className='teacherDashboardOverviewContainer'>
        <HomeCard customClass='teacherDashboardOverviewCard'>
          <GoalsOverview />
        </HomeCard>

        <HomeCard
          customClass='teacherDashboardOverviewCard'
          actions={
            <Fab
              className='viewAllButton'
              variant='extended'
              color='primary'
              size='small'
              onClick={() => {
                setSelectedMenuKey('Statistics');
                updateSelectedMenu('Statistics');
                navigate('/statistics');
              }}
            >
              View all
            </Fab>
          }
        >
          <TeacherStats />
        </HomeCard>
        <HomeCard
          customClass='teacherDashboardOverviewCard'
          showAvatar
          actions={
            <FullScreenDialog
              customClass='viewAllButton'
              buttonText={'View All'}
              buttonColor='primary'
              title='All Videos'
              buttonVariant='extended'
            >
              <ViewAllVideos typeOfUser={typeOfUser} />
            </FullScreenDialog>
          }
        >
          <VideosListTeacher />
        </HomeCard>

        {ACM[typeOfUser]?.isDashboardAppointments && (
          <Box className='teacher-appoinments-area'>
            <Box className='teacherDashboardOverviewCard dashboard-appointments'>
              <Box>
                <Typography
                  variant='h5'
                  sx={{
                    fontFamily: 'Lato',
                    fontWeight: 700,
                    color: 'var(--clr-theme-purple)',
                    padding: '15px',
                  }}
                >
                  Calendar
                </Typography>
              </Box>
              <Appointments />
            </Box>
          </Box>
        )}
      </Box>
    </MainLayout>
  );
};

export default TeacherDashboard;