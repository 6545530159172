import { Box, CircularProgress, Typography } from '@mui/material';

import React, { useContext } from 'react';
import ReactPlayer from 'react-player';
import AuthContext from '../../../contexts/AuthContext';

const ProcessVideo = ({
  isPlaying,

  processVideoStatusMessage,
  processVideos,
  setIsProcessedVideoPlaying,
  setIsRawVideoPlaying,
  setIsFullProcessedVideoPlaying,
}) => {
  return (
    <div className='fullHeight'>
      {processVideos ? (
        <ReactPlayer
          className='react-player'
          width={'100%'}
          url={processVideos?.fileDetails?.fullprocessedVideoUrl}
          type={processVideos?.fileDetails?.fileType}
          controls={true}
          playing={isPlaying}
          // onPlay={() => setIsPlaying(true)}
          // onPause={() => setIsPlaying(false)}
          onPlay={() => {
            setIsRawVideoPlaying(false);
            setIsProcessedVideoPlaying(true);
            setIsFullProcessedVideoPlaying(true);
          }}
          onPause={() => setIsFullProcessedVideoPlaying(false)}
        />
      ) : (
        <Box
          className='fullHeight flexBox flexColumn flexCenter'
          sx={{
            gap: 4,
          }}
        >
          <Typography variant='h6' fontFamily={'Lato'}>
            {processVideoStatusMessage}
          </Typography>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default ProcessVideo;
