import React, { createContext, useContext, useState } from 'react';
import {
  fetchPatientTreatments,
  getAllPatientVideos,
  getMyPatientsDetails,
  getPatientVideos,
  patientLogin,
  fetchAllStudents,
  fetchAllTeachers,
  fetchingTeachersAndStudentsListForSchoolAdmin,
  fetchingTeachersForStudents,
  studentLogin,
} from '../api';
import AuthContext from './AuthContext';
export const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [patientData, setPatientData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [currentPatientData, setCurrentPatientData] = useState(null);
  const [currentStudentData, setCurrentStudentData] = useState(null);
  const [currentPatientVideos, setCurrentPatientVideos] = useState(null);
  const [allPatientsAllVideos, setAllPatientsAllVideos] = useState(null);

  const [user, setUser] = useState(null);
  const { typeOfUser } = useContext(AuthContext);
  const [loadingFetchOnePatientData, setLoadingOnePatientData] = useState(true);
  const [loadingFetchOneStudentData, setLoadingOneStudentData] = useState(true);

  // * Only for doctor login
  // For patient this info is already retrieved during login and kept in user state

  const fetchPatientsForDoctor = async (doctorUid) => {
    const result = await getMyPatientsDetails(doctorUid);
    if (result) {
      return result?.patients;
    } else {
      return [];
    }
  };

  // * Both for doctor and patient logins
  const fetchOnePatientData = async (patientUid) => {
    const treatments_diagnosis = await fetchPatientTreatments(patientUid);
    const patientData = await patientLogin(patientUid);
    if (treatments_diagnosis || patientData) {
      return {
        data: treatments_diagnosis,
        id: patientUid,
        user: patientData?.myDetails,
      };
    }
  };

  const fetchOneStudentData = async (studentUid) => {
    let treatments_diagnosis;
    if (typeOfUser === 'TEACHER') {
      treatments_diagnosis = await fetchPatientTreatments(
        studentUid,
        'TEACHER'
      );
    } else {
      treatments_diagnosis = await fetchPatientTreatments(
        studentUid,
        'STUDENT'
      );
    }
    const studentData = await studentLogin(studentUid);
    if (treatments_diagnosis || studentData) {
      return {
        data: treatments_diagnosis,
        id: studentUid,
        user: studentData?.myDetails,
      };
    }
  };

  const fetchOnePatientAllVideos = async (patientUid) => {
    const result = await getPatientVideos(patientUid, 'all');

    if (result) return { data: result, id: patientUid };
    else return { data: [], id: patientUid };
  };

  const fetchAllPatientsAllVideos = async (doctorUid) => {
    const result = await getAllPatientVideos(doctorUid);
    if (result) return { data: result, id: doctorUid };
    else return { data: [], id: doctorUid };
  };

  const resetStates = () => {
    setPatientData([]);
    setCurrentPatientData(null);
    setAllPatientsAllVideos(null);
    setCurrentPatientVideos(null);
  };

  const resetStudentStates = () => {
    setStudentData([]);
    setCurrentStudentData(null);
  };
  const fetchAllTeacher = async (uid) => {
    const result = await fetchAllTeachers(uid);
    if (result) {
      return result?.students;
    } else {
      return [];
    }
  };
  const fetchTeachersAndStudentsListForSchoolAdmin = async (uid) => {
    const result = await fetchingTeachersAndStudentsListForSchoolAdmin(uid);

    if (result) {
      return result?.data;
    } else {
      return [];
    }
  };
  const fetchTeachersForStudent = async (uid) => {
    const result = await fetchingTeachersForStudents(uid);
    if (result) {
      return result?.data;
    } else {
      return [];
    }
  };

  return (
    <DataContext.Provider
      value={{
        patientData,
        studentData,
        fetchPatientsForDoctor,
        currentPatientData,
        currentStudentData,
        fetchOnePatientData,
        fetchOneStudentData,
        currentPatientVideos,
        fetchOnePatientAllVideos,
        allPatientsAllVideos,
        fetchAllPatientsAllVideos,
        setCurrentPatientData,
        setCurrentStudentData,
        setPatientData,
        setStudentData,
        loadingFetchOnePatientData,
        loadingFetchOneStudentData,
        user,
        typeOfUser,
        resetStates,
        resetStudentStates,
        fetchAllTeacher,
        fetchTeachersAndStudentsListForSchoolAdmin,
        fetchTeachersForStudent,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
