import { useQuery } from '@tanstack/react-query';
import { fetchAllSchoolAdmins } from '../api';
const useSchoolAdminsData = (userId, typeOfUser) => {
  return useQuery({
    queryKey: ['schoolAdmins', userId, typeOfUser],
    queryFn: async () => {
      if (typeOfUser !== 'ADMIN') return [];

      const fetchedAdminData = await fetchAllSchoolAdmins(userId, 'ADMIN');
      return (
        fetchedAdminData?.students.map((admin) => ({
          id: admin.id,
          name: admin.docData?.fullname || '',
          email: admin.docData?.email || '',
          schoolName: admin.docData?.schoolName || '',
          photoURL: admin.docData?.photoURL || null,
          organizationName: admin.docData?.organizationName || '',
          organizationId: admin.docData?.organizationId || null,
        })) || []
      );
    },
    staleTime: 60000, // 1 min
    cacheTime: 300000, // 5 min
  });
};

export default useSchoolAdminsData;
