import { useMediaQuery } from '@mui/material';
import React, { createContext, useState, useMemo } from 'react';
import { LeftMenu, LeftMenu_BottomSection } from '../constants/LeftMenu';

export const UIContext = createContext();

const UIProvider = ({ children }) => {
  const LeftMenuItems = [...LeftMenu, ...LeftMenu_BottomSection];

  const [selectedMenuKey, setSelectedMenuKey] = useState(LeftMenuItems[0].key);
  const [selectedPatientId, setSelectedPatientId] = useState(-1);
  const [bottomDrawer, setBottomDrawer] = useState({
    show: false,
    title: '',
    content: null,
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isDrawerExpanded, setIsDrawerExpanded] = useState(() => {
    return JSON.parse(localStorage.getItem('isDrawerExpanded')) || false;
  });
  const [isNewMessage, setIsNewMessage] = useState(false);

  const isSmallScreen = useMediaQuery('(max-width: 800px)');
  const drawerVariant = isSmallScreen
    ? 'temporary'
    : isDrawerExpanded
    ? 'persistent'
    : 'permanent';

  const updateSelectedMenu = (key) => {
    setSelectedMenuKey(key);
    selectPatient(-1, true);
    localStorage.setItem('selectedMenuKey', key);
    localStorage.setItem('selectedPatientId', -1);
  };

  const selectPatient = (id, reset) => {
    setSelectedPatientId(id);
    localStorage.setItem('selectedMenuKey', 'Patient List' || 'Student List');
    localStorage.setItem('selectedPatientId', id);
    !reset && setSelectedMenuKey(-1);
  };

  const toggleDrawerExpanded = () => {
    setIsDrawerExpanded((prev) => {
      const newState = !prev;
      localStorage.setItem('isDrawerExpanded', JSON.stringify(newState));
      return newState;
    });
  };

  const contextValue = useMemo(
    () => ({
      selectedMenuKey,
      setSelectedMenuKey,
      updateSelectedMenu,
      selectedPatientId,
      selectPatient,
      bottomDrawer,
      setBottomDrawer,
      isDrawerOpen,
      setIsDrawerOpen,
      isHovering,
      setIsHovering,
      isDrawerExpanded,
      setIsDrawerExpanded: toggleDrawerExpanded,
      drawerVariant,
      isSmallScreen,
      isNewMessage,
      setIsNewMessage,
    }),
    [
      selectedMenuKey,
      selectedPatientId,
      bottomDrawer,
      isDrawerOpen,
      isHovering,
      isDrawerExpanded,
      drawerVariant,
      isSmallScreen,
      isNewMessage,
    ]
  );

  return (
    <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>
  );
};

export default UIProvider;
