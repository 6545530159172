import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  Tooltip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  tooltipClasses,
  styled,
  Box,
} from '@mui/material';
import AuthContext from '../../contexts/AuthContext';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { FaCalendarAlt } from 'react-icons/fa';
import useStudentsForOneTeacher from '../../hooks/useStudentsForOneTeacher';
import './TeacherDashboard.css';
import '../Home/ViewAllVideos/ViewAllVideos.css';
import { mockData } from './enrolledStudentsMockData';

const TeacherStats = () => {
  const [selectedMetric, setSelectedMetric] = useState('All');
  const handleMetricChange = (value) => {
    setSelectedMetric(value);
  };

  const handleViewAllStats = () => {};
  return (
    <>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        flexWrap='wrap'
        sx={{ paddingBottom: 2 }}
      >
        <Typography
          variant='h5'
          sx={{
            fontFamily: 'Lato',
            fontWeight: 700,
            color: 'var(--clr-theme-purple)',
          }}
        >
          Statistics
        </Typography>

        <Box
          display='flex'
          alignItems='center'
          gap={2}
          className='video-filters'
          sx={{ flexWrap: 'wrap' }}
        >
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id='demo-simple-select-label'>Select Metric</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={selectedMetric}
              label='Select Metric'
              onChange={(event) => handleMetricChange(event.target.value)}
            ></Select>
          </FormControl>
        </Box>
      </Box>
    </>
  );
};

export default TeacherStats;
