// import { ExpandLess, ExpandMore } from '@mui/icons-material';
// import {
//   Avatar,
//   Collapse,
//   List,
//   ListItemButton,
//   ListItemIcon,
//   ListItemText,
//   Tooltip,
//   tooltipClasses,
// } from '@mui/material';
// import { styled } from "@mui/material/styles";
// import React, { useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { UIContext } from '../../contexts/UIProvider';

// import './CollapsibleList.css';
// import { LogAnalyticsEvent } from '../../utils/firebase/firebase';
// import { ANALYTICS_KEYS } from '../../constants/AnalyticKeys';

// const BootstrapTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} arrow classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.arrow}`]: {
//     color: theme.palette.common.black,
//   },
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.black,
//     fontWeight: "bold",
//     padding: "10px",
//   },
// }));

// const CollapsibleList = ({ mainIcon, primaryText, data }) => {
//   const { selectedPatientId, selectPatient } = useContext(UIContext);
//   const [open, setOpen] = React.useState(selectedPatientId !== -1 || false);

//   const navigate = useNavigate();

//   const handleClick = () => {
//     setOpen(!open);
//     LogAnalyticsEvent(ANALYTICS_KEYS.LEFTMENU.SIDEBARMENUCLICK, {
//       page_name: primaryText,
//       is_open: !open,
//     });
//   };

//   const goToPatient = (item) => {
//     selectPatient(item.patientId);

//     navigate(`/patient/${item.patientId}`, {
//       state: {
//         patientData: { ...item?.patientInfo, id: item.patientId },
//       },
//     });

//     LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTSLIST.PROFILEVIEW, {
//       patient_id: item.patientId,
//     });
//   };

//   const capitalizeWords = (name) => {
//     return name
//       ?.split(' ')
//       .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
//       .join(' ');
//   };

//   const truncateName = (name, limit = 10) => {
//     const capitalized = capitalizeWords(name);
//     if (capitalized.length > limit) {
//       return `${capitalized.slice(0, limit)}...`;
//     }
//     return capitalized;
//   };
//   return (
//     <>
//       <ListItemButton onClick={handleClick}>
//         <ListItemIcon sx={{ color: 'var(--clr-theme-purple)' }}>
//           {mainIcon}
//         </ListItemIcon>
//         <ListItemText primary={primaryText} />
//         {open ? <ExpandLess /> : <ExpandMore />}
//       </ListItemButton>
//       <Collapse in={open} timeout='auto' unmountOnExit>
//         <List component='div' disablePadding className='collapsedList'>
//           {data.map((item, i) => (
//             <ListItemButton
//               key={i}
//               sx={{ pl: 4 }}
//               className={
//                 selectedPatientId === item.patientId ? 'selectedPatient' : ''
//               }
//               onClick={() => goToPatient(item)}
//             >
//               <ListItemIcon>
//                 {item.icon ||
//                   (item?.patientInfo?.photoURL && (
//                     <Avatar src={item?.patientInfo?.photoURL} />
//                   )) || <Avatar />}
//               </ListItemIcon>
//                {/* Tooltip to show full name on hover */}
//                <BootstrapTooltip
//                 title={
//                   item?.patientInfo?.parentName
//                     ? `${item.patientInfo.fullname} (${item.patientInfo.parentName})`
//                     : item?.patientInfo?.fullname
//                 }
//                 arrow
//               >
//                 <ListItemText
//                   primary={
//                     item?.patientInfo?.parentName
//                       ? `${truncateName(item.patientInfo.fullname)} (${truncateName(
//                           item.patientInfo.parentName
//                         )})`
//                       : truncateName(item.patientInfo.fullname)
//                   }
//                   sx={{ cursor: 'pointer' }} // Add a pointer cursor to indicate it's hoverable
//                 />
//               </BootstrapTooltip>
//             </ListItemButton>
//           ))}
//         </List>
//       </Collapse>
//     </>
//   );
// };

// export default CollapsibleList;

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Avatar,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useContext } from 'react';

import './CollapsibleList.css';
import { LogAnalyticsEvent } from '../../utils/firebase/firebase';
import { ANALYTICS_KEYS } from '../../constants/AnalyticKeys';
import { UIContext } from '../../contexts/UIProvider';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: 'bold',
    padding: '10px',
  },
}));

const CollapsibleList = ({
  mainIcon,
  primaryText,
  data = [],
  onItemClick,
  isDrawerExpanded,
  isHovering,
  getItemId = (item) => item.id,
  getItemName = (item) => item.name,
  getItemAvatar = (item) => item.photoURL,
  getItemTooltip = (item) => null,
}) => {
  const { selectedPatientId } = useContext(UIContext);
  const [open, setOpen] = React.useState(selectedPatientId !== -1 || false);

  const handleClick = () => {
    setOpen(!open);
    LogAnalyticsEvent(ANALYTICS_KEYS.LEFTMENU.SIDEBARMENUCLICK, {
      page_name: primaryText,
      is_open: !open,
    });
  };

  const truncateName = (name, limit = 10) => {
    // const capitalized = capitalizeWords(name);
    // if (capitalized.length > limit) {
    //   return `${capitalized.slice(0, limit)}...`;
    // }
    // return capitalized;
    if (!name) return '';
    const words = name
      .split(' ')
      .map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
    const truncated = words.join(' ');
    return truncated.length > limit
      ? `${truncated.slice(0, limit)}...`
      : truncated;
  };

  return (
    <>
      <ListItemButton onClick={handleClick} sx={{ width: '100%' }}>
        <ListItemIcon sx={{ color: 'var(--clr-theme-purple)' }}>
          {mainIcon}
        </ListItemIcon>
        {isDrawerExpanded || isHovering ? (
          <ListItemText
            primary={primaryText}
            sx={{ color: 'var(--clr-theme-gray-dark)' }}
          />
        ) : null}
        {open ? (
          <ExpandLess sx={{ color: 'var(--clr-theme-gray-dark)' }} />
        ) : (
          <ExpandMore sx={{ color: 'var(--clr-theme-gray-dark)' }} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List
          component='div'
          disablePadding
          className='collapsedList'
          sx={{
            overflowX: isDrawerExpanded || isHovering ? 'auto' : 'hidden',
            width: isDrawerExpanded || isHovering ? 'auto' : 50,
          }}
        >
          {data.map((item, i) => (
            <ListItemButton
              key={getItemId(item)}
              sx={{
                pl: isDrawerExpanded || isHovering ? 2 : 1.5,
                width: isDrawerExpanded || isHovering ? '100%' : 45,
              }}
              className={
                selectedPatientId === getItemId(item) ? 'selectedPatient' : ''
              }
              onClick={() => onItemClick(item)}
            >
              <ListItemIcon
                sx={{
                  justifyContent:
                    isDrawerExpanded || isHovering ? 'auto' : 'left',
                  width: isDrawerExpanded || isHovering ? 'auto' : 40,
                }}
              >
                <BootstrapTooltip
                  title={getItemTooltip(item) || getItemName(item)}
                  arrow
                >
                  {getItemAvatar(item) ? (
                    <Avatar
                      src={getItemAvatar(item)}
                      sx={{
                        width: isDrawerExpanded || isHovering ? 40 : 24,
                        height: isDrawerExpanded || isHovering ? 40 : 24,
                        justifyContent:
                          isDrawerExpanded || isHovering ? 'auto' : 'center',
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        width: isDrawerExpanded || isHovering ? 40 : 24,
                        height: isDrawerExpanded || isHovering ? 40 : 24,
                        justifyContent:
                          isDrawerExpanded || isHovering ? 'auto' : 'center',
                      }}
                    />
                  )}
                </BootstrapTooltip>
              </ListItemIcon>
              {isDrawerExpanded || isHovering ? (
                <BootstrapTooltip
                  title={getItemTooltip(item) || getItemName(item)}
                  arrow
                >
                  <ListItemText
                    primary={truncateName(getItemName(item))}
                    sx={{ cursor: 'pointer' }}
                  />
                </BootstrapTooltip>
              ) : null}
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default CollapsibleList;
