import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { DataContext } from '../contexts/DataProvider';

const useAllPatientsVideos = (doctorUid) => {
  const { fetchAllPatientsAllVideos } = useContext(DataContext);

  return useQuery({
    queryKey: ['allPatientsVideos', doctorUid],
    queryFn: () => fetchAllPatientsAllVideos(doctorUid),
    enabled: !!doctorUid,
    staleTime: 60000, // 1 min 
    cacheTime: 300000, // 5 min
  });
};

export default useAllPatientsVideos;
