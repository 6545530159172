import {
    Box,
    Typography,
    Card,
    CardContent,
    CardActions,
    Button,
    Dialog,
    DialogContent,
    AppBar,
    Toolbar,
    tooltipClasses,
    styled,
    Tooltip,
  } from '@mui/material';
  import React, { useContext, useEffect, useState } from 'react';
  import { LogAnalyticsEvent } from '../../utils/firebase/firebase';
  import '../PatientOverview/PatientOverview.css';
  import { updatePatientProfileDetails } from '../../api';
  import { DataContext } from '../../contexts/DataProvider';
  import { Chat, Close } from '@mui/icons-material';
  import { useNavigate } from 'react-router-dom';
  import Reminders from '../../components/Reminders/index.js';
  import { ANALYTICS_KEYS } from '../../constants/AnalyticKeys.js';
  import usePatientsForDoctor from '../../hooks/usePatientsForDoctor';
import useStudentsForOneTeacher from '../../hooks/useStudentsForOneTeacher.js';
  const No_Photo_User = 'https://cdn-icons-png.flaticon.com/512/456/456212.png';
  
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontWeight: 'bold',
      padding: '10px',
    },
  }));
  
  const StudentDetails = ({ user, student, currentStudentData }) => {

    // const {
    //   data: patientData = [],
    //   isLoading: isLoading,
    //   error: errorPatientData,
    // } = usePatientsForDoctor(user?.uid);

    const {
        data: studentData = [],
        isLoading: isLoading,
        error: studentError,
        refetch: refetchStudentData,
      } = useStudentsForOneTeacher(user?.uid, 'TEACHER');

    const { setStudentData, setCurrentStudentData } = useContext(DataContext);
  
    const [currentStudent, setCurrentStudent] = useState({
      ...student,
    //   diagnosis: student.diagnosis?.join(', ') || '',
    });

    const [editOpen, setEditOpen] = useState(false);
    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
  
      LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTSLIST.REMINDER, {
        button_name: 'Set Reminder',
        message: 'Navigated to reminders screen',
      });
    };
    const handleClose = () => {
      setOpen(false);
    };
  
    const navigate = useNavigate();
  
    const navigateToChatScreen = async (notificationDetails) => {
      navigate('/message', {
        state: { patientId: currentStudent?.id },
      });
      LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTSLIST.MESSAGE, {
        patient_id: currentStudent?.id,
        message: 'Navigated to chat screen',
      });
    };
  
    const UpdateDoctorChanges = async () => {
      const params = {
        fullname: currentStudent?.fullname || '',
        dob: currentStudent?.dob || null,
      };
  
      const upadtedProfile = { ...currentStudentData?.user, ...params };
  
      setCurrentStudentData((prev) => {
        return {
          ...prev,
          user: upadtedProfile,
        };
      });
  
      await updatePatientInfo(student.id, upadtedProfile);
      const result = await updatePatientProfileDetails(
        btoa(JSON.stringify(params)),
        student.id
      );
      setEditOpen(false);
    };
  
    const updatePatientInfo = async (patientId, updatedProfile) => {
      const index = studentData.findIndex(
        (patient) => patient.patientId === patientId
      );
  
      if (index !== -1) {
        setStudentData((prevPatientData) => {
          const newPatientData = [...prevPatientData];
          newPatientData[index] = {
            ...newPatientData[index],
            patientInfo: {
              ...newPatientData[index].patientInfo,
              ...updatedProfile,
            },
          };
          return newPatientData;
        });
      } else {
        console.error(`Patient with ID ${patientId} not found.`);
      }
    };
  
    const ClearChanges = () => {
      setCurrentStudent(student);
      setEditOpen(false);
    };
  
    useEffect(() => {
      setCurrentStudent({
        ...student,
        diagnosis: student.diagnosis?.join(', ') || '',
      });
  
      setEditOpen(false);
    }, [student]);
  
    const truncateName = (name, maxLength = 10) => {
      return name?.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
    };
  
    return (
      <Card
        className='patientDetailsCard'
        sx={{ justifyContent: 'space-between', boxShadow: 'none' }}
      >
        <CardContent className='imagePosition'>
          <img
            src={currentStudent?.photoURL || No_Photo_User}
            alt='Avatar'
            style={{
              width: '20vh',
              height: '20vh',
              borderRadius: '20px 20px 0px 0px',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundAttachment: 'fixed',
              margin: '0.5rem auto',
            }}
          />
          <div className='container'>
            {!editOpen ? (
              <>
                <Typography
                  variant='h4'
                  sx={{ fontFamily: 'Lato', fontWeight: 700 }}
                >
                  <BootstrapTooltip
                    title={
                      currentStudent.name
                        ? currentStudent.name
                            .split(/\s+/)
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(' ')
                        : ''
                    }
                  >
                    <span>
                      {currentStudent.name
                        ? truncateName(
                            currentStudent.name
                              .split(/\s+/)
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.slice(1).toLowerCase()
                              )
                              .join(' ')
                          )
                        : ''}
                    </span>
                  </BootstrapTooltip>
                </Typography>
              </>
            ) : (
              <></>
            )}
          </div>
        </CardContent>
        <Box>
          {!editOpen ? (
            <CardActions
              className='flexBox flexColumn '
              sx={{ gap: 1, alignItems: 'center' }}
            >
              <Button
                color='primary'
                variant='contained'
                sx={{
                  backgroundColor: 'var(--clr-theme-purple) !important',
                  width: '50%',
                  ml: 1,
                }}
                onClick={navigateToChatScreen}
              >
                Chat <Chat sx={{ ml: 1, fontSize: 16 }} />
              </Button>
              <Button
                sx={{
                  backgroundColor: 'var(--clr-theme-purple) !important',
                  width: '50%',
                  textTransform: 'capitalize',
                  color: 'white',
                }}
                onClick={handleClickOpen}
              >
                Set Reminder
              </Button>
              <Dialog
                onClose={handleClose}
                aria-labelledby='customized-dialog-title'
                open={open}
                maxWidth='lg'
              >
                <AppBar
                  sx={{
                    position: 'relative',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  }}
                >
                  <Toolbar>
                    <Typography
                      sx={{
                        flex: 1,
                        color: 'black',
                        fontSize: '1.8rem',
                        fontFamily: 'Lato',
                      }}
                      variant='h6'
                      component='div'
                    >
                      Reminders
                    </Typography>
                    <Button autoFocus color='inherit' onClick={handleClose}>
                      <Close sx={{ color: 'black' }} />
                    </Button>
                  </Toolbar>
                </AppBar>
                <DialogContent sx={{ width: '42vw' }}>
                  <Reminders patient={student} />
                </DialogContent>
              </Dialog>
            </CardActions>
          ) : (
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                variant='outlined'
                sx={{
                  width: '48%',
                  fontFamily: 'Lato',
                  textTransform: 'capitalize',
                }}
                onClick={ClearChanges}
              >
                Cancel
              </Button>
              <Button
                color='primary'
                variant='contained'
                sx={{
                  backgroundColor: 'var(--clr-theme-purple) !important',
                  width: '48%',
                  fontFamily: 'Lato',
                  textTransform: 'capitalize',
                }}
                onClick={UpdateDoctorChanges}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>
      </Card>
    );
  };
  
  export default StudentDetails;
  