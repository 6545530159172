import { Notifications, Person, Edit } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { HeaderSettings } from '../../constants/HeaderMenu';
import AuthContext from '../../contexts/AuthContext';
import { UIContext } from '../../contexts/UIProvider';
import Notification from '../Notification';

import './Header.css';
import { fetchUserNotificationMessages } from '../../api';
import UpdatedAcceptPolicies from './UpdatedAcceptPolicies';
import UnreadMessageCounts from '../../pages/Messaging/UnreadCount';
import usePatientsForDoctor from '../../hooks/usePatientsForDoctor';
import { FirestoreDB, LogAnalyticsEvent } from '../../utils/firebase/firebase';
import { ANALYTICS_KEYS } from '../../constants/AnalyticKeys';
import { collection, getDocs, onSnapshot } from 'firebase/firestore';
import { ACMContext } from '../../contexts/ACMProvider';

const Header = ({ onclick }) => {
  const { ACM } = useContext(ACMContext);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [notificationCount, setnotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [title, setTitle] = useState('');
  const [unreadCounts, setUnreadCounts] = useState([]);
  const {
    user,
    typeOfUser,
    logout,
    setOpenRightPanel,
    setPanelContent,
    setIsNotificationPanel,
  } = useContext(AuthContext);
  const {
    updateSelectedMenu,
    setSelectedMenuKey,
    selectedMenuKey,
    isDrawerOpen,
    setIsDrawerOpen,
    isSmallScreen,
  } = useContext(UIContext);

  const {
    data: patientData = [],
    isLoading: isLoading,
    error: errorPatientData,
  } = usePatientsForDoctor(user?.uid);
  const [patientsLoaded, setPatientsLoaded] = useState(false);
  const [isUserExists, setIsUserExists] = useState(false);

  const location = useLocation();

  const doctorListCount = async () => {
    const doctorsRef = collection(FirestoreDB, 'doctorsList');
    return onSnapshot(
      doctorsRef,
      (querySnapshot) => {
        const doctorCount = querySnapshot.docs.length;

        // Log a custom event to Firebase Analytics for successfully fetching doctor data
        LogAnalyticsEvent(ANALYTICS_KEYS.COUNT.DOCTORSCOUNT, {
          doctorCount: doctorCount, // The number of doctors fetched
          message: `${doctorCount} doctors successfully fetched`,
        });
      },
      (error) => {
        console.log('Error listening to doctorsList:', error);
        // Log a custom event to Firebase Analytics for an error during fetch
        LogAnalyticsEvent('doctor_list_fetch_error', {
          errorMessage: error.message, // Capture error message
          message: `Error fetching doctor list: ${error.message}`,
        });
      }
    );
  };

  const patientListCount = async () => {
    const patientsRef = collection(FirestoreDB, 'patientsList');
    return onSnapshot(
      patientsRef,
      (querySnapshot) => {
        const patientCount = querySnapshot.docs.length;

        // Log a custom event to Firebase Analytics for successfully fetching patient data
        LogAnalyticsEvent(ANALYTICS_KEYS.COUNT.PATIENTSCOUNT, {
          patientCount: patientCount, // The number of patients fetched
          message: `${patientCount} patients successfully fetched`,
        });
      },
      (error) => {
        console.log('Error listening to patientsList:', error);
        // Log a custom event to Firebase Analytics for an error during fetch
        LogAnalyticsEvent('patient_list_fetch_error', {
          errorMessage: error.message, // Capture error message
          message: `Error fetching patient list: ${error.message}`,
        });
      }
    );
  };

  useEffect(() => {
    const videoUploadCount = () => {
      const videosRef = collection(FirestoreDB, 'videoLogs');

      const unsubscribe = onSnapshot(
        videosRef,
        (querySnapshot) => {
          Promise.resolve()
            .then(() => {
              let totalVideoCount = 0;
              const videoCountPromises = querySnapshot.docs.map((doc) => {
                const userRawVideosRef = collection(doc.ref, 'rawVideos');
                return getDocs(userRawVideosRef).then(
                  (userRawVideosSnapshot) => {
                    totalVideoCount += userRawVideosSnapshot.size;
                  }
                );
              });

              return Promise.all(videoCountPromises).then(() => {
                LogAnalyticsEvent(ANALYTICS_KEYS.COUNT.VIDEOSCOUNT, {
                  totalVideoCount,
                  message: `Successfully fetched ${totalVideoCount} videos across all users.`,
                });
              });
            })
            .catch((error) => {
              console.error('Error fetching videos:', error);

              LogAnalyticsEvent('video_upload_fetch_error', {
                errorMessage: error.message,
                message: `Error fetching video data: ${error.message}`,
              });
            });
        },
        (error) => {
          console.error('Error listening to videoLogs:', error);
          LogAnalyticsEvent('video_upload_listener_error', {
            errorMessage: error.message,
            message: `Error listening to videoLogs: ${error.message}`,
          });
        }
      );

      return unsubscribe;
    };

    const unsubscribe = videoUploadCount();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  useEffect(() => {
    let unsubscribe;

    const documentUploadCount = async () => {
      try {
        const documentsRef = collection(FirestoreDB, 'historicalData');

        unsubscribe = onSnapshot(
          documentsRef,
          async (querySnapshot) => {
            const fetchDocumentCounts = async () => {
              const totalDocumentPromises = querySnapshot.docs.map(
                async (doc) => {
                  const userRawDocumentsRef = collection(
                    doc.ref,
                    'attachments'
                  );
                  const userRawDocumentsSnapshot = await getDocs(
                    userRawDocumentsRef
                  );
                  return userRawDocumentsSnapshot.size;
                }
              );

              const documentCounts = await Promise.all(totalDocumentPromises);
              const totalDocumentCount = documentCounts.reduce(
                (acc, count) => acc + count,
                0
              );

              LogAnalyticsEvent(ANALYTICS_KEYS.COUNT.DOCUMENTSCOUNT, {
                totalDocumentCount,
                message: `Successfully fetched ${totalDocumentCount} documents across all users.`,
              });
            };
            debounce(fetchDocumentCounts, 300)();
          },
          (error) => {
            console.error('Error listening to documentLogs:', error);
            LogAnalyticsEvent('document_upload_listener_error', {
              errorMessage: error.message,
              message: `Error listening to documentLogs: ${error.message}`,
            });
          }
        );
      } catch (error) {
        console.error('Error setting up snapshot listener:', error);
      }
    };

    documentUploadCount();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    doctorListCount();
    patientListCount();
  }, []);

  const formatName = (name) => {
    return name
      .split(/\s+/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  React.useEffect(() => {
    let key = localStorage.getItem('selectedMenuKey') || selectedMenuKey;
    if (
      localStorage.getItem('selectedPatientId') !== '-1' &&
      selectedMenuKey !== 'Home'
    ) {
      const reportTitle = ACM[typeOfUser]?.isDoctor 
    ? 'Patient Report' 
    : 'Student Report';
      setTitle(reportTitle);
    } else if (key === 'Home') {
      const formattedUserName = user?.fullname || user?.displayName || 'User';
      const formattedTitle = formatName(formattedUserName);
      setTitle(`Welcome, ${formattedTitle}`);
    } else if (key === 'Appointments') {
      setTitle('Appointments');
    } else if (key === 'Statistics') {
      setTitle('Statistics');
    } else if (key === 'HistoricalData') {
      setTitle('Historical Data');
    } else if (key === 'Settings') {
      setTitle(`Settings`);
    } else if (key === 'Privacy') {
      setTitle('Privacy Statement');
    } else if (key === 'Termcondition') {
      setTitle('Term and Conditions');
    } else if (key === 'Contact') {
      setTitle('Contact Information');
    } else if (key === 'Userguide') {
      setTitle('User Guide');
    } else if (key === 'Messaging') {
      setTitle('Messaging');
    } else if (key === 'Schools') {
      setTitle('School Management');
    } else if (key === 'Organization Admin') {
      setTitle('Organization Management');
    } else if (key === 'School Admin') {
      setTitle('School Admin Management');
    } else if (key === 'Permissions') {
      setTitle('Permissions');
    } else if (key === 'Teachers') {
      setTitle('Teacher Management');
    } else if (key === 'Students') {
      setTitle('Student Management');
    } else if (key === 'Terms and Policies') {
      setTitle('Terms and Policies');
    }
    else if (selectedMenuKey === "Messaging") {
      setTitle("Messaging");
    }
    else if (selectedMenuKey === "Messaging") {
      setTitle("Messaging");
    }
  }, [selectedMenuKey, user]);

  useEffect(() => {
    if (user) {
      fetchNotifications();
      setIsUserExists(true);
    }
  }, [user]);

  useEffect(() => {
    patientData?.length > 0 && setPatientsLoaded(true);
  }, [patientData]);

  useEffect(() => {
    if (location.pathname === '/') {
      updateSelectedMenu('Home');
    }
  }, [location]);

  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settingClickHandler = (setting) => {
    handleCloseUserMenu();
    if (setting.key === 'profile') {
      navigate('/settings');
      updateSelectedMenu('Settings');
    }
    if (setting.key === 'dashboard') {
      ACM[typeOfUser]?.isAdmin ? navigate('/admin') : navigate('/');
      updateSelectedMenu('Home');
    }
    if (setting.key === 'logout') {
      setSelectedMenuKey('Home');

      const logoutKey =
        // typeOfUser === "DOCTOR"
        ACM[typeOfUser]?.isDoctor
          ? ANALYTICS_KEYS.USERS.DOCTORLOGOUT
          : ANALYTICS_KEYS.USERS.PATIENTLOGOUT;

      const userType = typeOfUser ? typeOfUser.toLowerCase() : 'unknown';

      LogAnalyticsEvent(logoutKey, {
        userUid: user.uid,
        message: `${userType} successfully logged out`,
      });
      logout();
      navigate('/main');
    }
  };

  const fetchNotifications = async () => {
    const messages = await fetchUserNotificationMessages(user?.uid);
       const filteredMessages = messages.filter(
      (message) =>
        message.title !== 'Patient has sent you a message' &&
        message.title !== 'Healthcare Professional has sent you a message'&&
        message.title !== 'Teacher has sent you a message'&&
        message.title !== 'Student has sent you a message'
    );

    const unreadMessages = filteredMessages.filter(
      (message) => !message.readStatus && message.title
    );

    setNotifications(messages);

    setnotificationCount(unreadMessages?.length);
  };

  const handleShowNotifications = () => {
    setPanelContent(
      <Notification
        setnotificationCount={setnotificationCount}
        notifications={notifications}
        fetchNotifications={fetchNotifications}
        setOpenRightPanel={setOpenRightPanel}
      />
    );
    setOpenRightPanel(true);
    setIsNotificationPanel(true);
  };

  const renderUnreadMessageCounts = () => {
    // if (typeOfUser === 'DOCTOR') {
    if (ACM[typeOfUser]?.unreadMessageCountsDoc) {
      if (patientsLoaded && isUserExists) {
        return (
          <UnreadMessageCounts
            unreadCounts={unreadCounts}
            setUnreadCounts={setUnreadCounts}
            user={user}
            typeOfUser={typeOfUser}
            patientData={patientData}
          />
        );
      }
      // } else if (typeOfUser === 'PATIENT') {
    } else if (ACM[typeOfUser]?.unreadMessageCountsPat) {
      if (isUserExists) {
        <UnreadMessageCounts
          unreadCounts={unreadCounts}
          setUnreadCounts={setUnreadCounts}
          user={user}
          typeOfUser={typeOfUser}
        />;
      }
    }
    return null;
  };

  return (
    <>
      {/* {typeOfUser !== "ADMIN" && <UpdatedAcceptPolicies />} */}
      {ACM[typeOfUser]?.canAcceptPolicies && <UpdatedAcceptPolicies />}
      <Toolbar disableGutters className='header'>
        {isSmallScreen && (
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title='Menu'>
              <IconButton onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
                <MenuIcon sx={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant='h5'
            component='div'
            sx={{
              ml: 2,
              flexGrow: 1,
              fontFamily: 'Lato',
              color: 'var(--clr-theme-gray-dark)',
            }}
          >
            {title}
          </Typography>
        </Box>

        <Box className='flexBox' sx={{ flexGrow: 0 }}>
          <Tooltip title='Edit your profile'>
            <IconButton
              component={Link}
              to='/settings'
              onClick={() => {
                updateSelectedMenu('Settings');
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>

          {/* {!typeOfUser === "ADMIN" && ( */}
          {ACM[typeOfUser]?.canViewNotifications && (
            <Tooltip title='Notifications'>
              <IconButton onClick={handleShowNotifications} sx={{ mr: 2 }}>
                <Badge
                  color='secondary'
                  overlap='circular'
                  badgeContent={
                    notificationCount > 0 ? notificationCount : null
                  }
                >
                  <Notifications />
                </Badge>
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title='Open settings'>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar src={user?.photoURL}>
                <Person />
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id='menu-appbar'
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key={'welcome'} onClick={handleCloseUserMenu} disabled>
              <Typography textAlign='center'>
                Welcome,{' '}
                {formatName(
                  user?.fullname ||
                    user?.displayName ||
                    user.organizationAdminName ||
                    'User'
                )}
              </Typography>
            </MenuItem>

            {HeaderSettings.filter(
              (setting) =>
                // !(typeOfUser === "ADMIN" && setting.key === "dashboard")
                !(ACM[typeOfUser]?.isAdmin && setting.key === 'dashboard')
            ).map((setting) => (
              <MenuItem
                key={setting.key}
                onClick={() => settingClickHandler(setting)}
              >
                <Typography textAlign='center'>{setting.text}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
      {renderUnreadMessageCounts()}
    </>
  );
};

export default Header;