import React, { useContext, useEffect, useState } from "react";
import MainLayout from "../../hoc/MainLayout";
import {
  Box,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import DocumentsUpload from "./DocumentsUpload";
import "./Historical.css";
import AssessmentAnalytics from "./AssessmentAnalytics";
import BehaviourGoals from "./BehaviourGoals";
import AuthContext from "../../contexts/AuthContext";
import usePatientsForDoctor from "../../hooks/usePatientsForDoctor";
import { fetchAllAttachmentsByUser } from "../../api";
import useStudentsForOneTeacher from "../../hooks/useStudentsForOneTeacher";
import { ACMContext } from "../../contexts/ACMProvider";

const Historical = () => {
  const { ACM } = useContext(ACMContext);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user, typeOfUser } = useContext(AuthContext);
  const [toggleStates, setToggleStates] = useState({});
  const [selectedPatient, setSelectedPatient] = useState("All");
  const [selectedStudent, setSelectedStudent] = useState("All");
  const [assessmentIndices, setAssessmentIndices] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedDocData, setSelectedDocData] = useState(null);
  const {
    data: patientData = [],
    isLoading: isLoading,
    error: errorPatientData,
  } = usePatientsForDoctor(user?.uid);

  const {
    data: studentData = [],
    isLoading: isStudentDataLoading,
    error: errorStudentData,
  } = useStudentsForOneTeacher(user?.uid, typeOfUser);

  useEffect(() => {
    if (user && typeOfUser === "PATIENT") {
      // Setting the default selected patient if the user is a patient
      setSelectedPatient(user?.uid);
    }
  }, [user, typeOfUser]);

  console.log("selectedPatient:", selectedPatient);

  useEffect(() => {
    if (typeOfUser === "DOCTOR" && selectedPatient !== "All") {
      const patientDocs = documents?.find(
        (doc) => doc?.patientId === (selectedPatient || selectedStudent)
      );
     

      if (patientDocs) {
        const attachmentData = patientDocs?.attachmentData || [];
        // console.log("Attachment Data:", attachmentData);

        // setAssessmentIndices(attachmentData?.map((_, index) => index));
        setAssessmentIndices(
          attachmentData.map(
            (assessment) => assessment?.docData?.info?.fileName
          )
        );
      }
    } else if (ACM[typeOfUser]?.isPatient || ACM[typeOfUser]?.isStudent) {
      // setAssessmentIndices(documents?.map((_, index) => index));
      setAssessmentIndices(
        documents
          ?.map((doc) => {
            return doc?.docData?.info?.fileName;
          })
          .flat() // Flatten the array in case there are nested arrays
      );
    }
  }, [selectedPatient, selectedStudent, documents, typeOfUser, ACM]);

  useEffect(() => {
    if (
      // typeOfUser === "DOCTOR" &&
      ACM[typeOfUser]?.isDoctor ||
      (ACM[typeOfUser]?.isTeacher && selectedIndex !== "" && selectedPatient) ||
      selectedStudent !== "All"
    ) {
      const patientDocs = documents?.find(
        (doc) => doc?.patientId === (selectedPatient || selectedStudent)
      );
      console.log("patientDocs:", patientDocs);
      if (patientDocs) {
        const docData = patientDocs?.attachmentData[selectedIndex];
        setSelectedDocData(docData);
      }
    } else if (ACM[typeOfUser]?.isPatient || ACM[typeOfUser]?.isStudent) {
      setSelectedDocData(documents[selectedIndex]);
    }
  }, [
    selectedIndex,
    selectedStudent,
    selectedPatient,
    documents,
    ACM,
    typeOfUser,
  ]);

  const handleSelectPatient = (event) => {
    ACM[typeOfUser]?.isTeacher
      ? setSelectedStudent(event.target.value)
      : setSelectedPatient(event.target.value);
    setAssessmentIndices([]);
    setSelectedIndex("");
    setSelectedDocData(null);
  };

  // console.log("selectedDocDta: ", selectedDocData);

  const handleSelectIndex = (event) => {
    setSelectedIndex(event.target.value);
  };

 

  const PatientSelect = (
    <FormControl fullWidth>
      <InputLabel>
        {ACM[typeOfUser]?.isTeacher ? "Select Student" : "Select Patient"}
      </InputLabel>
      <Select
        value={ACM[typeOfUser]?.isTeacher ? selectedStudent : selectedPatient}
        label="Select Patient"
        onChange={handleSelectPatient}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        sx={{ backgroundColor: "#fff" }}
      >
        <MenuItem value={"All"}>
          {ACM[typeOfUser]?.isTeacher ? "All Students" : "All Patients"}
        </MenuItem>
        {ACM[typeOfUser]?.isTeacher || ACM[typeOfUser]?.isStudent
          ? studentData?.map((student) => (
              <MenuItem key={student.id} value={student.id}>
                {student?.name
                  ? student?.name
                      .split(/\s+/)
                      .map(
                        (word) =>
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase()
                      )
                      .join(" ")
                  : ""}
              </MenuItem>
            ))
          : patientData?.map((patient) => (
              <MenuItem key={patient.patientId} value={patient.patientId}>
                {patient?.patientInfo?.fullname
                  ? patient?.patientInfo?.fullname
                      .split(/\s+/)
                      .map(
                        (word) =>
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase()
                      )
                      .join(" ")
                  : ""}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );

  const AssessmentIndexSelect = (
    <FormControl fullWidth>
      <InputLabel color="secondary">Select Assessment</InputLabel>
      <Select
        value={selectedIndex}
        label="Select Assessment"
        onChange={handleSelectIndex}
        color="secondary"
      >
        {assessmentIndices && assessmentIndices.length > 0 ? (
          assessmentIndices.map((fileName, index) => (
            <MenuItem sx={{ p: 1.5 }} divider key={index} value={index}>
              {index + 1}. {fileName}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled value="">
            <Typography variant="body2">
              Assessments are not available.
            </Typography>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );

  const fetchDocuments = async () => {
    if (!user) return;
    setLoading(true);
    let fetchedDocuments;
    try {
      if (ACM[typeOfUser].fetchAllAttachmentsByUser) {
        fetchedDocuments = await fetchAllAttachmentsByUser(
          user?.uid,
          typeOfUser
        );

        // Filter out deleted documents based on docData
        fetchedDocuments = fetchedDocuments?.data
          ?.map(
            (patient) => (patient?.docData?.isDeleted ? null : patient) // If document is deleted, remove it
          )
          .filter(Boolean); // Remove nulls from array
        // console.log("fetchedDocuments:", fetchedDocuments);
        // if(ACM[typeOfUser]?.isTeacher || ACM[typeOfUser]?.isStudent){
        //   const filterDocments = fetchDocuments?.data?.map((document) => {
        //     const attachmentData = document?.attachmentData?.map((attachment) => {
        //       if(attachment?.docData?.isPrivateAll) {
        //         if(attachment?.docData?.uploadedId != user.uid) {
        //           return null;
        //         }
        //       }
        //       return attachmentData
        //     })
        //   }).filter(Boolean);
        //   setDocuments(filterDocments);
        // }

        if (ACM[typeOfUser]?.isTeacher || ACM[typeOfUser]?.isStudent) {
          const filterDocuments = fetchDocuments?.data
            ?.map((document) => {
              // Filter attachmentData for each document
              const filteredAttachments = document?.attachmentData
                ?.map((attachment) => {
                  if (attachment?.docData?.isPrivateAll) {
                    // Only include attachments uploaded by the current user
                    if (attachment?.docData?.uploadedId !== user.uid) {
                      return null; // Exclude this attachment
                    }
                  }
                  return attachment; // Include the attachment
                })
                .filter(Boolean); // Remove null values

              // If filteredAttachments is empty, exclude the document
              if (!filteredAttachments || filteredAttachments.length === 0) {
                return null;
              }

              // Return the document with filtered attachments
              return {
                ...document,
                attachmentData: filteredAttachments,
              };
            })
            .filter(Boolean); // Remove null documents

          setDocuments(filterDocuments); // Update state with the filtered documents
        }

        // Now we need to check privacy settings or user-specific visibility for each document
        const filteredDocuments = fetchedDocuments
          ?.map((document) => {
            // Filtering logic based on docData (for example, checking isPrivateAll)
            if (document?.docData?.isPrivateAll) {
              if (document?.docData?.uploadedId !== user.uid) {
                return null; // Only return documents uploaded by the current user if isPrivateAll is true
              }
            }

            return document; // Return the document if it's public or user has access
          })
          .filter(Boolean); // Remove any nulls after filtering

        setDocuments(filteredDocuments);
      } else if (ACM[typeOfUser]?.fetchAllAttachmentsByUser) {
        fetchedDocuments = await fetchAllAttachmentsByUser(
          user?.uid,
          typeOfUser
        );

        // Set documents for this case
        setDocuments(fetchedDocuments?.data);
      }
      const initialToggleStates = fetchedDocuments?.data.reduce((acc, doc) => {
        doc?.attachmentData?.forEach((attachment) => {
          if (attachment?.docData?.isPrivate) {
            acc[attachment.docId] = attachment?.docData?.isPrivate;
          }
        });
        return acc;
      }, {});
      setToggleStates(initialToggleStates);
    } catch (error) {
      console.log("Error fetching documents:", error);
    } finally {
      setLoading(false);
    }
  };

  

  useEffect(() => {
    if (user) {
      fetchDocuments();
    }
  }, [user]);

  return (
    <MainLayout>
      <Box
        className=" fullHeight"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // column for mobile, row for larger screens
          // border: "2px solid",
          flexWrap: { xs: "nowrap", sm: "wrap" },
          gap: 1,
          width: "100%",
        }}
      >
        <Card
          sx={{
            // border: "2px solid green",
            width: { xs: "100%", sm: "68%" }, // 100% for mobile, 60% for larger screens
            height: { xs: "100%", sm: "50%" },
            border: "1px solid lightGray",
            minHeight: "50%",
          }}
        >
          {ACM[typeOfUser]?.historicalScreenAssessment && (
            <AssessmentAnalytics
              documents={documents}
              selectedPatient={selectedPatient}
              selectedStudent={selectedStudent}
              selectedIndex={selectedIndex}
              selectedDocData={selectedDocData}
              PatientSelect={PatientSelect} // Passing PatientSelect as a prop
              AssessmentIndexSelect={AssessmentIndexSelect} // Passing AssessmentIndexSelect as a prop
              typeOfUser={typeOfUser}
            />
          )}
        </Card>
        <Card
          sx={{
            // border: "2px solid red",
            width: { xs: "100%", sm: "30%" }, // 100% for mobile, 40% for larger screens
            height: { xs: "100%", sm: "50%" },
            p: 1,
            overflow: "auto",
            border: "1px solid var(--clr-theme-lgray)",
            minHeight: "50%",
          }}
        >
          {ACM[typeOfUser]?.historicalScreenDocuments && (
            <>
              <Typography
                gutterBottom
                variant="h5"
                sx={{
                  fontFamily: "Lato",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                Documents Uploads
              </Typography>
              <DocumentsUpload selectedDocData={selectedDocData} />
            </>
          )}
        </Card>
        <Card
          sx={{
            // border: "2px solid blue",
            width: { xs: "100%", sm: "68%" }, // 100% for mobile, 60% for larger screens
            height: { xs: "100%", sm: "50%" },
            border: "1px solid var(--clr-theme-lgray)",
            minHeight: "50%",
          }}
        >
          {ACM[typeOfUser]?.historicalScreenBehavior && (
            <BehaviourGoals selectedDocData={selectedDocData} />
          )}
        </Card>

        <Card
          sx={{
            // border: "2px solid purple",
            width: { xs: "100%", sm: "30%" }, // 100% for mobile, 40% for larger screens
            height: { xs: "100%", sm: "50%" },
            border: "1px solid var(--clr-theme-lgray)",
            minHeight: "50%",
          }}
        >
          {ACM[typeOfUser]?.historicalScreenMetrics && (
            <Typography
              gutterBottom
              variant="h5"
              sx={{
                fontFamily: "Lato",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              25 Metrics/Other
              <br /> METRICS
            </Typography>
          )}
        </Card>
      </Box>
    </MainLayout>
  );
};

export default Historical;
