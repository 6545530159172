import { useQuery } from '@tanstack/react-query';
import { fetchingStudentsForTeachers } from '../api';

const useStudentsForOneTeacher = (userId, typeOfUser) => {
  return useQuery({
    queryKey: ['fetchingStudentsForOneTeacher', userId, typeOfUser],
    queryFn: async () => {
      if (typeOfUser !== 'TEACHER') return [];

      const fetchedData = await fetchingStudentsForTeachers(userId);
      return (
        fetchedData?.studentsList.map((teacher) => ({
          id: teacher.uid,
          name: teacher.info.name || teacher.info.fullname || '',
          email: teacher.info.email,
          parentName: teacher.info.parentName,
          schoolName: teacher.info.schoolName || '',
          photoURL: teacher.info?.photoURL || null,
        })) || []
      );
    },
    staleTime: 60000, // 1 min
    cacheTime: 300000, // 5 min
  });
};

export default useStudentsForOneTeacher;
