import { useQuery } from '@tanstack/react-query';
import { fetchingTeachersForStudents } from '../api';
const useTeachersForOneStudent = (userId, typeOfUser) => {
  return useQuery({
    queryKey: ['fetchingTeachersForOneStudent', userId, typeOfUser],
    queryFn: async () => {
      if (typeOfUser !== 'STUDENT') return [];
      const fetchedData = await fetchingTeachersForStudents(userId, 'STUDENT');
       return (
        fetchedData?.map(
            (student) => ({
              id: student.uid,
              name: student.info.name || student.info.fullname || '',
              email: student.info.email,
              parentName: student.info.parentName,
              schoolName: student.info.schoolName || '',
              photoURL: student.info?.photoURL || null,
            })
          ) || []
      );
    },
    staleTime: 60000, // 1 min
    cacheTime: 300000, // 5 min
  });
};
export default useTeachersForOneStudent;