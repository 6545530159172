import React, { useContext, useState } from 'react';
import { deleteUserAccountInfo } from '../../../api';
import { DataContext } from '../../../contexts/DataProvider';
import AuthContext from '../../../contexts/AuthContext';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { LogAnalyticsEvent } from '../../../utils/firebase/firebase';
import { ANALYTICS_KEYS } from '../../../constants/AnalyticKeys';
import { ACMContext } from '../../../contexts/ACMProvider';

const DeleteAccount = () => {
  const { ACM } = useContext(ACMContext);
  const { logout, user, typeOfUser } = useContext(AuthContext);
  const { resetStates } = useContext(DataContext);
  const [openDialog, setOpenDialog] = useState(true);

  const accDelete = ACM[typeOfUser]?.isDoctor
    ? ANALYTICS_KEYS.SETTINGS.DOCTORACCOUNTDELETE
    : ANALYTICS_KEYS.SETTINGS.PATIENTACCOUNTDELETE;

  const deleteAccountHandler = async () => {
    // setIsLoading(true);
    LogAnalyticsEvent(accDelete, {
      userId: user?.uid,
      userType: typeOfUser,
    });
    logout();
    const response = await deleteUserAccountInfo(user?.uid, typeOfUser);
    if (response) {
      resetStates();
    }
    setOpenDialog(false); // Close the dialog after deletion, regardless of the result
  };

  const cancelHandler = () => {
    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' fontFamily={'Lato'}>
          Are you sure you want to delete this account?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description' fontFamily={'Lato'}>
            This account will be deleted permanently. You can't undo this
            action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={cancelHandler}
            color='primary'
            sx={{
              color: 'var(--clr-theme-purple)',
              border: '1px solid var(--clr-theme-purple)',
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={deleteAccountHandler}
            color='error'
            sx={{ backgroundColor: 'var(--clr-theme-purple)' }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeleteAccount;
