import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  MenuItem,
  Typography,
  FormControl,
  Select,
  Grid,
} from '@mui/material';
import MainLayout from '../../hoc/MainLayout';
import { useLocation } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';
import { fetchAllAttachmentsByUser } from '../../api';
import usePatientsForDoctor from '../../hooks/usePatientsForDoctor';
import { ACMContext } from '../../contexts/ACMProvider';

const Smartsearch = () => {
  const { ACM } = useContext(ACMContext);
  const location = useLocation();
  const { documentUrl, documentName, patientId } = location.state || {};

  const { user, typeOfUser } = useContext(AuthContext);
  const { data: patients = [] } = usePatientsForDoctor(user?.uid);
  const [patientData, setPatientData] = useState([]);
  const [selectedPatientId, setSelectedPatientId] = useState('');
  const [selectedDocument, setSelectedDocument] = useState('');
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);

  const [docUrl, setDocumentUrl] = useState(documentUrl);

  useEffect(() => {
    fetchDocuments();
  }, []);

  useEffect(() => {
    if (patients) {
      const dataArray = patients?.map((each) => ({
        ...each?.patientInfo,
        id: each?.patientId,
      }));
      setPatientData(dataArray);
    }
  }, [patients]);

  useEffect(() => {
    if (selectedPatientId) {
      filterDocumentsByPatient(selectedPatientId);
    }
  }, [selectedPatientId, documents]);

  useEffect(() => {
    if (patientId) {
      setSelectedPatientId(patientId);
      // Find the patient object based on patientId
      // const selectedPatient = patientData.find(
      //   (patient) => patient.id === patientId
      // );
      // Set the default patient name
      // setSelectedPatientName(selectedPatient.fullname);
    }

    if (documentName) {
      setSelectedDocument(documentName);
    }
  }, [patientId, documentName]);

  const fetchDocuments = async () => {
    try {
      const fetchedDocuments = await fetchAllAttachmentsByUser(
        user?.uid,
        typeOfUser
      );
      console.log('fetchedDocuments', fetchedDocuments?.data);
      const filteredAttachments = fetchedDocuments?.data?.map((patient) => {
        return {
          ...patient,
          attachmentData: patient?.attachmentData?.filter(
            (attachment) => !attachment?.docData?.isDeleted
          ),
        };
      });

      console.log('filtered', filteredAttachments);
      setDocuments(filteredAttachments);

      if (ACM[typeOfUser]?.isPatient||ACM[typeOfUser]?.isStudent) {
        setFilteredDocuments(fetchedDocuments?.data);
      }
    } catch (error) {
      console.log('Error fetching documents:', error);
    } finally {
    }
  };

  const filterDocumentsByPatient = (patientId) => {
    const patientDocuments = documents
      .flatMap((pat) =>
        pat?.attachmentData?.map((doc) => ({
          ...doc,
          patientId: pat.patientId,
        }))
      )
      .filter((doc) => doc.patientId === patientId);
    setFilteredDocuments(patientDocuments);
  };

  const handleSelectDocumentChange = (event) => {
    const selectedFileName = event.target.value;
    const selectedDoc = filteredDocuments.find(
      (doc) => doc?.docData?.info?.fileName === selectedFileName
    );
    console.log("selectedDoc:",selectedDoc)
    setSelectedDocument(selectedFileName);
    setDocumentUrl(selectedDoc?.docData?.info?.url || '');
  };

  const handleSelectPatientChange = (event) => {
    const patientId = event.target.value;
    setSelectedPatientId(patientId);
    setSelectedDocument('');
    setDocumentUrl('');
    setFilteredDocuments([]);
  };

  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={1}
          direction='row'
          justifyContent={'center'}
          gap={1}
        >
          <Grid
            item
            xs={8}
            sm={8}
            md={12}
            lg={12}
            sx={{
              border: 'none',
              p: 0.5,
              height: '86vh',
              width: '100%',
              maxWidth: '100%',
              backgroundColor: '#D9D9D9',
              borderRadius: 1,
              mb: 1,
            }}
          >
            <Grid>
              {ACM[typeOfUser]?.isDoctor && (
                <FormControl sx={{ width: '45%', m: 0.1, pl: 1 }}>
                  <Select
                    value={selectedPatientId}
                    onChange={handleSelectPatientChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Select Patient' }}
                  >
                    <MenuItem value='' disabled>
                      Select Patient
                    </MenuItem>
                    {patientData.map((patient) => (
                      <MenuItem key={patient.id} value={patient.id}>
                        {patient.fullname
                          ? `${patient.fullname
                              .split(/\s+/)
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.slice(1).toLowerCase()
                              )
                              .join(' ')}`
                          : ''}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <FormControl sx={{ width: '45%', m: 0.1, pl: 1 }}>
                <Select
                  value={selectedDocument}
                  onChange={handleSelectDocumentChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Select Document' }}
                >
                  <MenuItem value='' disabled>
                    Select Document
                  </MenuItem>
                  {selectedPatientId && filteredDocuments.length === 0 ? (
                    <MenuItem value='' disabled>
                      No documents
                    </MenuItem>
                  ) : (
                    filteredDocuments.map((doc) => (
                      <MenuItem
                        key={doc?.docId}
                        value={doc?.docData?.info?.fileName}
                      >
                        {doc?.docData?.info?.fileName}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Box
              className='contentContainer'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '75vh',
                m: 1,
                backgroundColor: 'white',
                padding: 2,
                borderRadius: 2,
              }}
            >
              {docUrl ? (
                <iframe
                  src={docUrl}
                  title='Document Viewer'
                  width='100%'
                  height='100%'
                />
              ) : (
                <Box
                  className='contentContainer'
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '72vh',
                    mr: 1,
                    backgroundColor: 'white',
                    borderRadius: 1,
                    mt: 0.5,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant='h6' color='textSecondary'>
                    Select any document to view
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MainLayout>
  );
};

export default Smartsearch;
