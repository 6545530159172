import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  MenuItem,
  Select,
  TextField,
  Button,
  DialogTitle,
} from '@mui/material';
import { Timestamp, collection, addDoc } from 'firebase/firestore';
import usePatientsForDoctor from '../../hooks/usePatientsForDoctor';
import { FirestoreDB } from '../../utils/firebase/firebase';
import { toast } from 'react-toastify';
import './QuickNotes.css';
import 'react-toastify/dist/ReactToastify.css';

import BrandLogo from '../BrandLogo';
import { Info } from '@mui/icons-material';
import { ACMContext } from '../../contexts/ACMProvider';

const QuickNotesModel = ({ user, typeOfUser }) => {
  const { ACM } = useContext(ACMContext);
  const doctorDetails = user?.doctorDetails || [];
  const [open, setOpen] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [selectedPatient, SetSelectedPatient] = React.useState('');
  const [selectedDoctor, setSelectedDoctor] = React.useState('');
  const [information, setInformation] = useState('');
  const [selectedPatientEmail, setSelectedPatientEmail] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const {
    data: patients = [],
    isLoading: isLoading,
    error: errorPatientData,
  } = usePatientsForDoctor(user?.uid);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const resetStates = () => {
    setInformation('');
    SetSelectedPatient('');
    setOpen(false);
  };

  const getEmailForSelectedPatient = () => {
    if (selectedPatient) {
      const selectedPatientData = patientData.find(
        (patient) => patient.id === selectedPatient
      );
      if (selectedPatientData) {
        setSelectedPatientEmail(selectedPatientData.email);
      }
    }
  };

  const getSelectedDoctorDetails = () => {
    if (selectedDoctor) {
      const selectedDoctorData = doctorDetails.find(
        (doctor) => doctor.doctorId === selectedDoctor
      );
      return selectedDoctorData;
    } else {
      return null;
    }
  };

  const SaveNoteHandler = async () => {
    getEmailForSelectedPatient();
    if (!information.trim()) {
      setValidationMessage('Please fill the input notes.');
      return;
    } else {
      setValidationMessage('');
    }

    // if (typeOfUser === "DOCTOR") {
    if (ACM[typeOfUser]?.isDoctor) {
      //send notification to patient with notes...
      const params = {
        title: 'Healthcare Professional has sent you a message',
        description: information,
        notifyTo: selectedPatientEmail,
        sentBy: user?.email,
        userName: user?.fullname,
        userPhotoURL: user?.photoURL,
      };
      await addDoc(
        collection(FirestoreDB, 'notifications', selectedPatient, 'messages'),
        {
          infoMessage: btoa(JSON.stringify(params)),
          notificationSource: 'doctorMessage',
          userId: user?.uid,
          isEncrypted: false,
          readStatus: false,
          createdDate: Timestamp.now(),
        }
      );
      toast.success('Message sent successfully', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      // } else if (typeOfUser === "PATIENT") {
    } else if (ACM[typeOfUser]?.isPatient) {
      const selectedDoctorData = getSelectedDoctorDetails();
      if (selectedDoctorData) {
        const params = {
          title: 'Patient has sent you a message',
          description: information,
          notifyTo: selectedDoctorData?.email,
          sentBy: user?.email,
          userName: user?.fullname,
          userPhotoURL: user?.photoURL,
        };
        await addDoc(
          collection(
            FirestoreDB,
            'notifications',
            selectedDoctorData?.doctorId,
            'messages'
          ),
          {
            infoMessage: btoa(JSON.stringify(params)),
            notificationSource: 'patientMessage',
            userId: user?.uid,
            isEncrypted: false,
            readStatus: false,
            createdDate: Timestamp.now(),
          }
        );
        toast.success('Message sent successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        toast.error('Please select healthcare professional !', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
    resetStates();
  };

  useEffect(() => {
    // if (typeOfUser === "DOCTOR") {
    if (ACM[typeOfUser]?.isDoctor) {
      if (patients) {
        const dataArray = patients?.map((each) => ({
          ...each?.patientInfo,
          id: each?.patientId,
        }));
        setPatientData(dataArray);
      }
    }
    getEmailForSelectedPatient();
  }, [selectedPatient, user, patients]);

  return (
    <>
      <Box>
        {/* <BootstrapTooltip title="Quick notes" placement="left">
          <Fab size="large" className="quickNotesButton" onClick={handleOpen}>
            <InsertCommentIcon />
          </Fab>
        </BootstrapTooltip> */}
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            textAlign: 'left',
            gap: 10,
          }}
        >
          <Dialog open={open} className='quickNotesDialog'>
            <DialogTitle sx={{ m: 0, p: 2 }}>
              <BrandLogo newline={false} />
            </DialogTitle>
            <DialogContent className='dialogContentBox'>
              <Typography
                gutterBottom
                sx={{ textAlign: 'center', mt: '0.3rem' }}
              >
                {/* {typeOfUser === "DOCTOR" */}
                {ACM[typeOfUser]?.isDoctor
                  ? 'Healthcare Professional quick notes'
                  : 'Patient quick notes'}
              </Typography>
              {/* {typeOfUser === "DOCTOR" && ( */}
              {ACM[typeOfUser]?.isDoctor && (
                <>
                  <Typography gutterBottom>* Select Patient:</Typography>
                  <Select
                    value={selectedPatient}
                    className='fullWidth'
                    onChange={(e) => {
                      SetSelectedPatient(e.target.value);
                    }}
                  >
                    {patientData.length > 0 ? (
                      patientData.map((data, index) => (
                        <MenuItem key={index} value={data.id}>
                          {data.fullname}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No patients available</MenuItem>
                    )}
                  </Select>
                  {patientData.length === 0 && (
                    <Box className='flexBox' gap={0.5} sx={{ pt: 2, pb: 2 }}>
                      <Info
                        sx={{
                          fontSize: '20px',
                          color: 'var(--clr-theme-gray)',
                        }}
                      />
                      <Typography
                        variant='caption'
                        sx={{
                          textAlign: 'start',
                          color: 'var(--clr-theme-gray)',
                        }}
                      >
                        No patients available to select.
                      </Typography>
                    </Box>
                  )}
                </>
              )}
              {/* {typeOfUser === "PATIENT" && ( */}
              {ACM[typeOfUser]?.isPatient && (
                <>
                  <Typography gutterBottom>
                    * Select Healthcare Professional:
                  </Typography>
                  <Select
                    value={selectedDoctor}
                    className='fullWidth'
                    onChange={(e) => {
                      setSelectedDoctor(e.target.value);
                    }}
                  >
                    {doctorDetails?.length > 0 ? (
                      doctorDetails?.map((data, index) => (
                        <MenuItem key={index} value={data.doctorId}>
                          {data.fullname}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>
                        No Healthcare Professional available
                      </MenuItem>
                    )}
                  </Select>
                  {doctorDetails?.length === 0 && (
                    <Box className='flexBox' gap={0.5} sx={{ pt: 2, pb: 2 }}>
                      <Info
                        sx={{
                          fontSize: '20px',
                          color: 'var(--clr-theme-gray)',
                        }}
                      />
                      <Typography
                        variant='caption'
                        sx={{
                          textAlign: 'start',
                          color: 'var(--clr-theme-gray)',
                        }}
                      >
                        No healthcare Professionals available to select.
                      </Typography>
                    </Box>
                  )}
                </>
              )}
              <Typography gutterBottom>Notes:</Typography>
              <TextField
                className='notes fullWidth fullHeight'
                variant='outlined'
                size='medium'
                label='Please input notes...'
                sx={{ my: 0.5 }}
                multiline
                rows={6}
                InputProps={{
                  style: {
                    minHeight: '55px', // Adjust the height as needed
                  },
                }}
                value={information}
                onChange={(e) => {
                  setInformation(e.target.value);
                  setValidationMessage('');
                }}
              />
              {validationMessage && (
                <Typography
                  variant='caption'
                  sx={{ color: 'red', textAlign: 'start', mt: 1 }}
                >
                  {validationMessage}
                </Typography>
              )}
            </DialogContent>
            <DialogActions
              className='fullWidth flexBox flexWrap'
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
                mt: 3,
              }}
            >
              <Button
                variant='outlined'
                onClick={handleClose}
                sx={{
                  width: '30%',
                  fontFamily: 'Lato',
                  textTransform: 'capitalize',
                  color: 'var(--clr-theme-purple)',
                  borderColor: 'var(--clr-theme-purple)',
                }}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                onClick={SaveNoteHandler}
                sx={{
                  width: '30%',
                  backgroundColor: 'var(--clr-theme-purple)!important',
                  color: 'var(--clr-theme-white)',
                  textTransform: 'capitalize',
                }}
              >
                Send
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </>
  );
};

export default QuickNotesModel;
