import React, { useContext, useEffect, useState } from 'react';
import CollapsibleList from '../../CollapsibleList';
import AuthContext from '../../../contexts/AuthContext';
import usePatientsForDoctor from '../../../hooks/usePatientsForDoctor';
import useStudentsForOneTeacher from '../../../hooks/useStudentsForOneTeacher';
import { ACM } from '../../../constants/UserRoles';
import { UIContext } from '../../../contexts/UIProvider';
import { useNavigate } from 'react-router-dom';
import { LogAnalyticsEvent } from '../../../utils/firebase/firebase';
import { ANALYTICS_KEYS } from '../../../constants/AnalyticKeys';

const PatientList = ({ icon, text, isDrawerExpanded, isHovering }) => {
  const { user, typeOfUser } = useContext(AuthContext);

  const {
    data: patients = [],
    isLoading: isLoading,
    error: errorPatientData,
  } = usePatientsForDoctor(user?.uid);

  const {
    data: students = [],
    isLoading: isStudentLoading,
    error: studentError,
  } = useStudentsForOneTeacher(user?.uid, typeOfUser);

  const { selectPatient } = useContext(UIContext);

  const navigate = useNavigate();

  const goToPatient = (item) => {
    selectPatient(item.patientId);

    navigate(`/patient/${item.patientId}`, {
      state: {
        patientData: { ...item?.patientInfo, id: item.patientId },
      },
    });

    LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTSLIST.PROFILEVIEW, {
      patient_id: item.patientId,
    });
  };

  const goToStudent = (item) => {
    selectPatient(item.id);
    navigate(`/student/${item.id}`, {
      state: {
        studentData: item,
      },
    });
  };

  return (
    <>
      {ACM[typeOfUser]?.isDoctor ? (
        // <CollapsibleList mainIcon={icon} primaryText={text} data={patients} />
        <CollapsibleList
          mainIcon={icon}
          primaryText='Patients'
          data={patients}
          selectPatient={selectPatient}
          onItemClick={(item) => goToPatient(item)}
          getItemId={(item) => item.patientId}
          getItemName={(item) => item.patientInfo.fullname}
          getItemAvatar={(item) => item.patientInfo.photoURL}
          getItemTooltip={(item) =>
            item.patientInfo.parentName
              ? `${item.patientInfo.fullname} (${item.patientInfo.parentName})`
              : item.patientInfo.fullname
          }
          isDrawerExpanded={isDrawerExpanded}
          isHovering={isHovering}
        />
      ) : ACM[typeOfUser]?.isTeacher ? (
        // <CollapsibleList mainIcon={icon} primaryText={text} data={students} />
        <CollapsibleList
          mainIcon={icon}
          primaryText='Students'
          selectPatient={selectPatient}
          data={students}
          onItemClick={(item) => goToStudent(item)}
          getItemId={(item) => item.id}
          getItemName={(item) => item.name}
          getItemAvatar={(item) => item.photoURL}
          isDrawerExpanded={isDrawerExpanded}
          isHovering={isHovering}
        />
      ) : null}
    </>
  );
};

export default PatientList;
