import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  tooltipClasses,
  styled,
  Tooltip,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { LogAnalyticsEvent } from '../../utils/firebase/firebase';
import './PatientOverview.css';
import { updatePatientProfileDetails } from '../../api';
import { DataContext } from '../../contexts/DataProvider';
import { Chat, Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Reminders from '../../components/Reminders/index.js';
import { ANALYTICS_KEYS } from '../../constants/AnalyticKeys.js';
import usePatientsForDoctor from '../../hooks/usePatientsForDoctor';
const No_Photo_User = 'https://cdn-icons-png.flaticon.com/512/456/456212.png';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: 'bold',
    padding: '10px',
  },
}));

const PatientDetails = ({ user, patient, currentPatientData }) => {
  const {
    data: patientData = [],
    isLoading: isLoading,
    error: errorPatientData,
  } = usePatientsForDoctor(user?.uid);
  const { setPatientData, setCurrentPatientData } = useContext(DataContext);

  const [currentPatient, setCurrentPatient] = useState({
    ...patient,
    diagnosis: patient.diagnosis?.join(', ') || '',
  });

  const [editOpen, setEditOpen] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);

    LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTSLIST.REMINDER, {
      button_name: 'Set Reminder',
      message: 'Navigated to reminders screen',
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const navigateToChatScreen = async (notificationDetails) => {
    navigate('/message', {
      state: { patientId: currentPatient?.id },
    });
    LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTSLIST.MESSAGE, {
      patient_id: currentPatient?.id,
      message: 'Navigated to chat screen',
    });
  };

  const UpdateDoctorChanges = async () => {
    const params = {
      fullname: currentPatient?.fullname || '',
      dob: currentPatient?.dob || null,
    };

    const upadtedProfile = { ...currentPatientData?.user, ...params };

    setCurrentPatientData((prev) => {
      return {
        ...prev,
        user: upadtedProfile,
      };
    });

    await updatePatientInfo(patient.id, upadtedProfile);
    const result = await updatePatientProfileDetails(
      btoa(JSON.stringify(params)),
      patient.id
    );
    setEditOpen(false);
  };

  const updatePatientInfo = async (patientId, updatedProfile) => {
    const index = patientData.findIndex(
      (patient) => patient.patientId === patientId
    );

    if (index !== -1) {
      setPatientData((prevPatientData) => {
        const newPatientData = [...prevPatientData];
        newPatientData[index] = {
          ...newPatientData[index],
          patientInfo: {
            ...newPatientData[index].patientInfo,
            ...updatedProfile,
          },
        };
        return newPatientData;
      });
    } else {
      console.error(`Patient with ID ${patientId} not found.`);
    }
  };

  const ClearChanges = () => {
    setCurrentPatient(patient);
    setEditOpen(false);
  };

  useEffect(() => {
    setCurrentPatient({
      ...patient,
      diagnosis: patient.diagnosis?.join(', ') || '',
    });

    setEditOpen(false);
  }, [patient]);

  const truncateName = (name, maxLength = 15) => {
    return name?.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
  };

  return (
    <Card
      className='patientDetailsCard'
      sx={{ justifyContent: 'space-between', boxShadow: 'none' }}
    >
      <CardContent className='imagePosition'>
        <img
          src={currentPatient?.photoURL || No_Photo_User}
          alt='Avatar'
          style={{
            width: '15vh',
            height: '15vh',
            borderRadius: '20px 20px 0px 0px',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            margin: '0.5rem auto',
          }}
        />
        <div className='container'>
          {!editOpen ? (
            <>
              <Typography
                variant='h5'
                sx={{ fontFamily: 'Lato', fontWeight: 700 }}
              >
                <BootstrapTooltip
                  title={
                    currentPatient.fullname
                      ? currentPatient.fullname
                          .split(/\s+/)
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(' ')
                      : ''
                  }
                >
                  <span>
                    {currentPatient.fullname
                      ? truncateName(
                          currentPatient.fullname
                            .split(/\s+/)
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(' ')
                        )
                      : ''}
                  </span>
                </BootstrapTooltip>
              </Typography>
            </>
          ) : (
            <></>
          )}
        </div>
      </CardContent>
      <Box>
        {!editOpen ? (
          <CardActions
            className='flexBox flexColumn '
            sx={{ gap: 1, alignItems: 'center' }}
          >
            <Button
              color='primary'
              variant='contained'
              sx={{
                backgroundColor: 'var(--clr-theme-purple) !important',
                width: '50%',
                ml: 1,
              }}
              onClick={navigateToChatScreen}
            >
              Chat <Chat sx={{ ml: 1, fontSize: 16 }} />
            </Button>
            <Button
              sx={{
                backgroundColor: 'var(--clr-theme-purple) !important',
                width: '50%',
                textTransform: 'capitalize',
                color: 'white',
              }}
              onClick={handleClickOpen}
            >
              Set Reminder
            </Button>
            <Dialog
              onClose={handleClose}
              aria-labelledby='customized-dialog-title'
              open={open}
              maxWidth='lg'
            >
              <AppBar
                sx={{
                  position: 'relative',
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                }}
              >
                <Toolbar>
                  <Typography
                    sx={{
                      flex: 1,
                      color: 'black',
                      fontSize: '1.8rem',
                      fontFamily: 'Lato',
                    }}
                    variant='h6'
                    component='div'
                  >
                    Reminders
                  </Typography>
                  <Button autoFocus color='inherit' onClick={handleClose}>
                    <Close sx={{ color: 'black' }} />
                  </Button>
                </Toolbar>
              </AppBar>
              <DialogContent sx={{ width: '42vw' }}>
                <Reminders patient={patient} />
              </DialogContent>
            </Dialog>
          </CardActions>
        ) : (
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              variant='outlined'
              sx={{
                width: '48%',
                fontFamily: 'Lato',
                textTransform: 'capitalize',
              }}
              onClick={ClearChanges}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              sx={{
                backgroundColor: 'var(--clr-theme-purple) !important',
                width: '48%',
                fontFamily: 'Lato',
                textTransform: 'capitalize',
              }}
              onClick={UpdateDoctorChanges}
            >
              Save
            </Button>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default PatientDetails;
