import * as React from "react";
import Box from "@mui/material/Box";
import { Slider, Typography } from "@mui/material";
import "./VideoFeedback.css";

export default function SymptomRating({ id, RatingHandler, funCall }) {
  const [value, setValue] = React.useState(0);
  const [hover, setHover] = React.useState(-1);

  const marks = [
    { value: 0, label: "Low" },
    { value: 1, label: "Mild" },
    { value: 2, label: "Moderate" },
    { value: 3, label: "Severe" },
  ];

  return (
    <Box sx={{ mt: 1.5, ml: 1.3, pr: 2 }}>
      <Slider
        className="custom-slider"
        sx={{
          height: "40%",
          color: "var(--clr-theme-purple)",
          fontSize: "0.678rem",
        }}
        size="large"
        valueLabelDisplay="auto"
        step={1}
        marks={marks}
        min={0}
        max={3}
        onChange={(event, newValue) => {
          setHover(newValue);
          setValue(newValue);
          RatingHandler(newValue, "rating", id, funCall);
        }}
        onChangeCommitted={(event, newHover) => {
          setHover(newHover);
        }}
      />
      {value !== null && (
        <Typography
          variant="subtitle2"
          sx={{
            color: "var(--clr-theme-gray-dark)",
            fontFamily: "Lato",
            pt: 1,
            textAlign: "center",
          }}
          gutterBottom
        >
          Selected Rating: {hover !== -1 ? hover : value}
        </Typography>
      )}
    </Box>
  );
}