import {
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import MainLayout from '../../hoc/MainLayout';
import AuthContext from '../../contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { ACMContext } from '../../contexts/ACMProvider';
import { fetchingTeachersForStudents } from '../../api';
import VideosListStudent from '../Home/VideosListStudent';

const StudentHomeDashboard = () => {
  const [fetchedTeachers, setFetchedTeachers] = useState([]);
  const { user, typeOfUser } = useContext(AuthContext);
  const { ACM } = useContext(ACMContext);

  const fetchTeachersForStudentData = async () => {
    if (ACM[typeOfUser]?.isStudent) {
      try {
        const fetchedData = await fetchingTeachersForStudents(user?.uid);

        console.log(fetchedData,"Fetch dataaaaaaaaaaaa");

        setFetchedTeachers(fetchedData);
      } catch (err) {
        console.log('Error fetching students:', err);
      }
    }
  };

  useEffect(() => {
    if (ACM[typeOfUser]?.isStudent) {
      fetchTeachersForStudentData();
    }
  }, [user, typeOfUser]);

  const schoolCount = new Set(
    fetchedTeachers?.map((teacher) => teacher?.info?.schoolId)
  ).size;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <Card>
          <CardContent>
            <Typography variant='h6'>Total Schools</Typography>
            <Typography variant='h4'>{schoolCount}</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={3}>
        <Card>
          <CardContent>
            <Typography variant='h6'>Active Schools</Typography>
            <Typography variant='h4'>{schoolCount}</Typography>
          </CardContent>
        </Card>
      </Grid>

      <TableContainer
        component={Paper}
        style={{ marginTop: '20px', marginLeft: '25px' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>School Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Teacher Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchedTeachers.length > 0 ? (
              fetchedTeachers.map((teacher, index) => (
                <TableRow key={index}>
                  <TableCell>{teacher?.info.schoolName}</TableCell>
                  <TableCell>{teacher?.info.fullname}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align='center'>
                  No teachers yet.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
     // <>
    // {/* <VideosListStudent/> */}
   
    // </>
  );
};

export default StudentHomeDashboard;
