import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { DataContext } from '../contexts/DataProvider';

const usePatientsForDoctor = (doctorUid) => {
  const { fetchPatientsForDoctor } = useContext(DataContext);
  return useQuery({
    queryKey: ['patientsList', doctorUid],
    queryFn: () => fetchPatientsForDoctor(doctorUid),
    enabled: !!doctorUid,
    staleTime: 60000, // 1 min
    cacheTime: 30000, // 5 min
  });
};

export default usePatientsForDoctor;
