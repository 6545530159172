import { memo, useContext, useEffect, useState, useRef } from 'react';
import { Add, Upgrade } from '@mui/icons-material';
import { Box, Paper } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { collection, doc, setDoc, getDoc } from 'firebase/firestore';
import { FirestoreDB, LogAnalyticsEvent } from '../../utils/firebase/firebase';
import AuthContext from '../../contexts/AuthContext';
import Treatments from './Treatmentm';
import { ToastContainer, toast } from 'react-toastify';
import CustomButton from '../../components/CustomButton';
import { fetchPatientTreatments } from '../../api';
import Progress from '../../components/Progress';
import { ANALYTICS_KEYS } from '../../constants/AnalyticKeys';
import { ACMContext } from '../../contexts/ACMProvider';
import { DataContext } from '../../contexts/DataProvider';

const UpdateTreatments = ({ patientID, refetchCurrentPatientData, studentID, refetchCurrentStudentData }) => {
  const { ACM } = useContext(ACMContext);
  const { setOpenRightPanel, user, typeOfUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [treatments, setTreatments] = useState([]);
  const [treatmentsFromDB, setTreatmentsFromDB] = useState([]);
  const { setCurrentPatientData } = useContext(DataContext);
  const scrollRef = useRef(null);

  const AddNewTreatment = () => {
    const newTreatment = {
      id: uuidv4(),
      therapies: [],
      meds: [],
      treatment_name: '',
      userName: user?.fullname,
      userType: typeOfUser,
      // isDoctorApproved: typeOfUser === "DOCTOR" ? true : false,
      isDoctorApproved: ACM[typeOfUser]?.isDoctorTreatmentApproved
        ? true
        : false,
    };
    setTreatments((prevTreatments) => [newTreatment, ...prevTreatments]);

    LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTSLIST.ADDNEWTREATMENT, {
      treatment_id: newTreatment.id,
      eventName: 'Add new treatments',
    });
  };

  const getTreatments = async () => {
    setIsLoading(true);
    const result = await fetchPatientTreatments(studentID || patientID, typeOfUser);
    setCurrentPatientData((prev) => {
      return {
        ...prev,
        data: result,
      };
    });
    setTreatments(result);
    setTreatmentsFromDB(result);
    setIsLoading(false);
  };

  const updateTreatments = (dataList, type, treatmentID) => {
    const myTreatments = [...treatments];
    if (type === 'Therapy') {
      myTreatments.find((x) => x.id === treatmentID).therapies =
        dataList.filter((item) => {
          return item.therapy_name?.trim().length > 0;
        });
    } else if (type === 'Meds') {
      myTreatments.find((x) => x.id === treatmentID).meds = dataList.filter(
        (item) => {
          return item.drug_name.length > 0;
        }
      );
    } else if ((type = 'treatment')) {
      myTreatments.find((x) => x.id === treatmentID).treatment_name = dataList;
    }

    setTreatments(myTreatments);

    // LogAnalyticsEvent("update_treatment", {
    //   treatment_id: treatmentID,
    //   type_of_update: type,
    // });
  };
  const deleteTreatment = (treatmentID) => {
    setTreatments([...treatments.filter((x) => x.id !== treatmentID)]);

    LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTSLIST.DELETETREATMENT, {
      treatment_id: treatmentID,
      eventName: 'Deleted successfully',
    });
  };

  const changeDoctorApproveStatus = (treatmentID) => {
    const index = treatments.findIndex(
      (treatment) => treatment.id === treatmentID
    );

    if (index !== -1) {
      const updatedTreatments = [...treatments];
      updatedTreatments[index] = {
        ...updatedTreatments[index],
        isDoctorApproved: true,
      };

      setTreatments(updatedTreatments);

      LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTSLIST.APRROVETREATMENT, {
        treatment_id: treatmentID,
        eventName: 'Doctor approved  patient treatments',
      });
    } else {
      console.log(`Treatment with ID ${treatmentID} not found.`);
    }
  };

  const validatingTreatments = () => {
    const tempTreatments = [];
    for (let i = 0; i < treatments.length; i++) {
      if (
        !treatments[i]?.meds ||
        !treatments[i]?.therapies ||
        (treatments[i].meds.length === 0 &&
          treatments[i].therapies.length === 0)
      ) {
        toast.error(
          'Every treatment must have at least one medication or therapy.',
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        return false;
      }
      const meds = [];
      const therapies = [];

      for (let j = 0; j < treatments[i]?.meds?.length; j++) {
        const currentMed = treatments[i]?.meds[j];
        meds.push({
          drug_name: currentMed?.drug_name?.trim() || '',
          dosage: currentMed?.dosage || '',
          sideEffects: currentMed?.sideEffects?.trim() || '',
          tradeName: currentMed?.tradeName?.trim() || '',
          usage: currentMed?.usage?.trim() || '',
        });
      }
      for (let k = 0; k < treatments[i]?.therapies?.length; k++) {
        const currentTherapy = treatments[i]?.therapies[k];
        therapies.push({
          therapy_name: currentTherapy?.therapy_name?.trim() || '',
          hoursPerSession: currentTherapy?.hoursPerSession?.trim() || '',
          costPerHours: currentTherapy?.costPerHours?.trim() || '',
          sessionsPerWeek: currentTherapy?.sessionsPerWeek?.trim() || '',
        });
      }
      tempTreatments.push({
        id: treatments[i]?.id,
        treatment_name:
          typeof treatments[i]?.treatment_name === 'string'
            ? treatments[i].treatment_name.trim()
            : '',
        meds: meds,
        therapies: therapies,
        userName: treatments[i]?.userName,
        userType: treatments[i]?.userType,
        isDoctorApproved: treatments[i]?.isDoctorApproved,
      });
    }

    setTreatments(tempTreatments);
    return true;
  };

  const addNewSymptomToSymptomsCollection = async (otherSymptom) => {
    const symptomsCollectionRef = collection(FirestoreDB, 'symptoms');
    try {
      if (otherSymptom) {
        const symptomDocRef = doc(symptomsCollectionRef, otherSymptom);
        const symptomDocSnapshot = await getDoc(symptomDocRef);
        if (!symptomDocSnapshot.exists()) {
          await setDoc(symptomDocRef, {});
        } else {
        }
      }
    } catch (error) {
      console.error(
        "Error adding new symptom to the 'symptoms' collection:",
        error
      );
    }
  };

  const UpdatetoFirebase = async () => {
    setIsLoading(true);
    if (validatingTreatments()) {
      const targetCollection = typeOfUser === 'TEACHER' || typeOfUser === 'STUDENT'
      ? 'studentsList'
      : 'patientsList';
      try {
        for (let i = 0; i < treatments.length; i++) {
          // const treatmentRef = doc(
          //   FirestoreDB,
          //   'patientsList',
          //   patientID,
          //   'treatments',
          //   treatments[i].id
          // );
          const treatmentRef = doc(
            FirestoreDB,
            targetCollection,
            targetCollection === 'studentsList' ? studentID : patientID,
            'treatments',
            treatments[i].id
          );

          const docSnapshot = await getDoc(treatmentRef);
          if (docSnapshot.exists()) {
            continue;
          }

          await setDoc(treatmentRef, {
            meds: btoa(JSON.stringify(treatments[i].meds || [])),
            therapies: btoa(JSON.stringify(treatments[i].therapies || [])),
            treatment_name: btoa(
              treatments[i].treatment_name || 'Symptom #' + (i + 1)
            ),
            userName: btoa(treatments[i].userName),
            userType: treatments[i].userType,
            isDoctorApproved: treatments[i].isDoctorApproved,
          });

          treatments[i].meds &&
            treatments[i].meds?.forEach((each) => {
              return (
                each.symptom && addNewSymptomToSymptomsCollection(each?.symptom)
              );
            });
        }
        LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTSLIST.SAVETREATMENT, {
          eventName: 'Save Treatments To Firebase',
          // treatment_id: treatments[i].id,
          message: 'Updated Treatments Sucessfully',
        });

        await refetchCurrentPatientData();
        await refetchCurrentStudentData();

        toast.success('Treatments updated successfully.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
        setOpenRightPanel(false);
      } catch (error) {
        console.log(error, 'error');
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setTreatments([]);
    if (patientID || studentID) {
      getTreatments();
    }
  }, [patientID, studentID]);

  if (isLoading) return <Progress />;

  return (
    <Paper
      className='fullHeight'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}
    >
      {treatments?.length > 0 && (
        <Box
          className='fullWidth'
          sx={{ flex: 0.88, overflowY: 'auto' }}
          ref={scrollRef}
        >
          {treatments?.map((treatment, i) => {
            return (
              <Treatments
                key={i}
                index={i}
                treatment={treatment}
                updateTreatments={updateTreatments}
                deleteTreatment={deleteTreatment}
                patientID={patientID}
                studentID={studentID}
                setIsLoading={setIsLoading}
                treatmentsFromDB={treatmentsFromDB}
                getTreatments={getTreatments}
                changeDoctorApproveStatus={changeDoctorApproveStatus}
                typeOfUser={typeOfUser}
              />
            );
          })}
        </Box>
      )}
      <ToastContainer autoClose={false} />
      {!treatments.length > 0 && (
        <Box
          sx={{
            flex: 0.88,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          No Treatments Available
        </Box>
      )}
      <Box
        className='fullWidth'
        sx={{
          flex: 0.12,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          gap: 1,
        }}
      >
        <CustomButton
          variant='extended'
          color='primary'
          ChangeHandler={AddNewTreatment}
        >
          <Add />
          Add New Treatment
        </CustomButton>
        <CustomButton
          variant='extended'
          color='secondary'
          ChangeHandler={UpdatetoFirebase}
          disabled={treatments.length === 0}
        >
          Save Changes
          <Upgrade />
        </CustomButton>
      </Box>
    </Paper>
  );
};

export default memo(UpdateTreatments);
