import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import AuthProvider from './contexts/AuthProvider';
import UIProvider from './contexts/UIProvider';
import AppRoutes from './routes';
import ACMProvider from './contexts/ACMProvider';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <UIProvider>
          <ACMProvider>
            <AppRoutes />
          </ACMProvider>
        </UIProvider>
      </AuthProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
