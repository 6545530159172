import { useQuery } from '@tanstack/react-query';
import { collection, getDocs } from 'firebase/firestore';
import { FirestoreDB } from '../utils/firebase/firebase';

const useSchoolsData = () => {
  return useQuery({
    queryKey: ['schools'],
    queryFn: async () => {
      const schoolsCollection = collection(FirestoreDB, 'schoolsList');
      const snapshot = await getDocs(schoolsCollection);
      return snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },
    staleTime: 60000, // 1 min
    cacheTime: 300000, // 5 min
  });
};

export default useSchoolsData;
