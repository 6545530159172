import React, { useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  AppBar,
  Toolbar,
  ListItemAvatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FirebaseAuth } from "../../utils/firebase/firebase";
import { onAuthStateChanged, sendEmailVerification } from "firebase/auth";
import LogoWhite from "../../assets/images/LogoWhite.png";
import Email from "../../assets/images/Email.png";

const EmailVerificationNote = ({ setUser }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(FirebaseAuth, async (user) => {
      if (user) {
        if (user.emailVerified) {
          setUser(user);
          navigate("/");
        }
      }
    });
    return () => unsubscribe();
  }, [navigate, setUser]);
  const handleResendVerification = async () => {
    const currentUser = FirebaseAuth?.currentUser;
    if (currentUser && !currentUser?.emailVerified) {
      await sendEmailVerification(currentUser);
      console.log("Verification email sent again!");
    }
  };

  return (
    <Grid
      container
      direction="column"
      sx={{ minHeight: "100vh", minWidth:"100vw", backgroundColor: "#e4eef0" }}

    >
      <Grid item>
        <AppBar position="static" sx={{ bgcolor: "var(--clr-theme-purple)" }}>
          <Toolbar>
            <ListItemAvatar sx={{ cursor: "pointer" }}>
              <img src={LogoWhite} alt="Logo" height="50px" />
            </ListItemAvatar>
            <Typography
              variant="h6"
              noWrap
              sx={{
                letterSpacing: ".1rem",
                color: "inherit",
                fontSize: { xs: "20px !important", md: "24px !important" },
                fontWeight: "bold",
              }}
              className="brand"



            >
              Mindly
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
          </Toolbar>
        </AppBar>
      </Grid>

      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ flexGrow: 1 }}
      >
        <Box
          sx={{
            textAlign: "center",
            width: { xs: "90%", sm: "70%", md: "50%", lg: "40%" },
            backgroundColor: "white",
            boxShadow: "0 0 5px var(--clr-theme-purple)",
            borderRadius: 2,
            p: { xs: 2, sm: 3, md: 4 },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Lato",
              fontWeight: 400,
              mb: 2,
              fontSize: { xs: "1.5rem", md: "2rem" },
            }}
          >
            Please verify your email
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: "var(--clr-theme-gray)",
              fontFamily: "Lato",
              fontWeight: 1000,
              mb: 2,
            }}
          >
            Check your email & click the link to proceed to Mindly
          </Typography>
          <Box sx={{display:"flex",flexDirection:"column",justifyContent:"ceneter",alignItems:"center"}}>

          <img
            src={Email}
            alt="Email"
            style={{
              width: "100%",
              maxWidth: "400px",
              height: "auto",
              marginBottom: "1rem",
            }}
          />

          <Button
            onClick={handleResendVerification}
            variant="contained"
            color="success"
            sx={{
              background: "#9291f0",
              textTransform: "capitalize",
              width: { xs: "80px", sm: "100px" },
              mb: 3,
            }}
          >
            Resend
          </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EmailVerificationNote;