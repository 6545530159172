import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  Tooltip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  tooltipClasses,
  styled,
  Box,
  LinearProgress,
} from '@mui/material';
import AuthContext from '../../../contexts/AuthContext';
import HomeCard from '../../../components/HomeCard';
import { ACMContext } from '../../../contexts/ACMProvider';
import MainLayout from '../../../hoc/MainLayout';
import goalsMockData from './mockData';
import { More } from '@mui/icons-material';
import './goals.css';
import useStudentsForOneTeacher from '../../../hooks/useStudentsForOneTeacher';
const No_Photo_User = 'https://cdn-icons-png.flaticon.com/512/456/456212.png';

const ViewAllGoals = () => {
  const { ACM } = useContext(ACMContext);
  const { user, typeOfUser } = useContext(AuthContext);
  const goals_data = Object.values(goalsMockData);

  const {
    data: fetchStudentsForOneTeacher = [],
    isLoading: isTeachersLoading,
    error: teachersError,
    refetch: refetchTeachers,
  } = useStudentsForOneTeacher(user?.uid, typeOfUser);

  const enrolledStudents = useMemo(
    () => fetchStudentsForOneTeacher || [],
    [fetchStudentsForOneTeacher]
  );

  const [selectStudentEmail, setSelectStudentEmail] = useState(
    enrolledStudents?.[0]?.email || ''
  );

  const [selectStudentPhoto, setSelectStudentPhoto] = useState(
    enrolledStudents?.[0]?.photoURL || No_Photo_User
  );

  const [selectStudentName, setSelectStudentName] = useState(
    enrolledStudents?.[0]?.name || ''
  );
  return (
    <MainLayout>
      <Box className='viewGoalsOverviewContainer'>
        <Box className='teacher-goals-area'>
          <HomeCard title='Goals' customClass='viewGoalsOverviewCard'>
            <TableContainer style={{ marginTop: '20px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      Goal Type
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Step 1</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Step 2</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Step 3</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Step 4</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      Percentage
                    </TableCell>

                    <TableCell style={{ fontWeight: 'bold' }}>{''}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {goals_data.length > 0 ? (
                    goals_data.map((goal, index) => (
                      <TableRow key={index}>
                        <TableCell>{goal?.goalType}</TableCell>
                        <TableCell>{goal?.step1}</TableCell>
                        <TableCell>{goal?.step2}</TableCell>
                        <TableCell>{goal?.step3}</TableCell>
                        <TableCell>{goal?.step4}</TableCell>
                        <TableCell>{goal?.progress}</TableCell>
                        <TableCell>
                          <Button sx={{ color: 'black' }}>
                            <More />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align='center'>
                        No goals yet.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </HomeCard>
        </Box>

        <Box className='teacher-in-perspective-area'>
          <HomeCard
            title='In Perspective'
            customClass='viewGoalsOverviewCard'
          ></HomeCard>
        </Box>

        <Box
          className='teacher-progress-area'
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <HomeCard title='Progress' customClass='viewGoalsOverviewCard'>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', height: '33%' }}
            >
              <Typography
                sx={{
                  fontFamily: 'Lato',
                  fontWeight: 700,
                }}
              >
                Lectures Attended
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <LinearProgress
                  variant='determinate'
                  value={90}
                  sx={{
                    flexGrow: 1,
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: '#e0e0e0',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: 'var(--clr-theme-purple)',
                    },
                  }}
                />
                <Typography variant='body2'>{`${90}%`}</Typography>
              </Box>
              <Typography>90/100</Typography>
            </Box>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', height: '33%' }}
            >
              <Typography
                sx={{
                  fontFamily: 'Lato',
                  fontWeight: 700,
                }}
              >
                Assignments Completed
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <LinearProgress
                  variant='determinate'
                  value={67}
                  sx={{
                    flexGrow: 1,
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: '#e0e0e0',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: 'var(--clr-theme-purple)',
                    },
                  }}
                />
                <Typography variant='body2'>{`${67}%`}</Typography>
              </Box>
              <Typography>14/21</Typography>
            </Box>

            <Box
              sx={{ display: 'flex', flexDirection: 'column', height: '33%' }}
            >
              <Typography
                sx={{
                  fontFamily: 'Lato',
                  fontWeight: 700,
                }}
              >
                Exams Taken
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <LinearProgress
                  variant='determinate'
                  value={72}
                  sx={{
                    flexGrow: 1,
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: '#e0e0e0',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: 'var(--clr-theme-purple)',
                    },
                  }}
                />
                <Typography variant='body2'>{`${71}%`}</Typography>
              </Box>
              <Typography>5/7</Typography>
            </Box>
          </HomeCard>
        </Box>

        <Box
          className='teacher-patient-profile-area'
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <HomeCard
            title='Patient Profile'
            customClass='viewGoalsOverviewCard'
            sx={{ width: '100%', height: '100%' }}
          >
            <Box
              sx={{
                width: '80%',

                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                justifySelf: 'center',
                border: '1px solid black',
                borderRadius: '8px',
                padding: '1rem',
                textAlign: 'center',
                backgroundColor: 'var(--clr-theme-white)',
                boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'var(--clr-theme-purple)',
                  color: 'var(--clr-theme-white)',
                  padding: '0.5rem 1rem',

                  maxWidth: '95%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '17px',
                  }}
                >
                  PLACEHOLDER Therapy Patient
                </Typography>
              </Box>
              <img
                src={selectStudentPhoto || No_Photo_User}
                alt='Avatar'
                style={{
                  width: '15vh',
                  height: '15vh',
                  borderRadius: '50%',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundAttachment: 'fixed',
                  margin: '0.5rem auto',
                }}
              />
              <Typography
                sx={{
                  maxWidth: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: '20px',
                  fontFamily: 'Lato',
                  fontWeight: '700',
                }}
              >
                {selectStudentName}
              </Typography>
              <Typography
                sx={{
                  maxWidth: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: '14px',
                  fontFamily: 'Lato',
                  textDecoration: 'underline',
                }}
              >
                {selectStudentEmail}
              </Typography>
            </Box>
            <Typography
              sx={{
                fontFamily: 'Lato',
                fontSize: '25px',
                fontWeight: 700,
                marginTop: '20px',
              }}
            >
              Goal Progress
            </Typography>
            <Box
              sx={{
                width: '80%',
                height: '60px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                justifySelf: 'center',
                border: '1px solid black',
                borderRadius: '8px',
                padding: '1rem',
                textAlign: 'center',
                backgroundColor: 'var(--clr-theme-white)',
                boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
                marginBottom: '15px',
              }}
            >
              <Typography>Placeholder Goal</Typography>
            </Box>
            <Box
              sx={{
                width: '80%',
                height: '60px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                justifySelf: 'center',
                border: '1px solid black',
                borderRadius: '8px',
                padding: '1rem',
                textAlign: 'center',
                backgroundColor: 'var(--clr-theme-white)',
                boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
                marginBottom: '15px',
              }}
            >
              <Typography>Placeholder Goal</Typography>
            </Box>
            <Box
              sx={{
                width: '80%',
                height: '60px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                justifySelf: 'center',
                border: '1px solid black',
                borderRadius: '8px',
                padding: '1rem',
                textAlign: 'center',
                backgroundColor: 'var(--clr-theme-white)',
                boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
                marginBottom: '15px',
              }}
            >
              <Typography>Placeholder Goal</Typography>
            </Box>
            <Typography
              sx={{
                fontFamily: 'Lato',
                fontSize: '25px',
                fontWeight: 700,
                marginTop: '20px',
              }}
            >
              Next Goal
            </Typography>
            <Box
              sx={{
                width: '80%',
                height: '60px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                justifySelf: 'center',
                border: '1px solid black',
                borderRadius: '8px',
                padding: '1rem',
                textAlign: 'center',
                backgroundColor: 'var(--clr-theme-white)',
                boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
                marginBottom: '15px',
              }}
            >
              <Typography>Placeholder Goal</Typography>
            </Box>
          </HomeCard>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default ViewAllGoals;
