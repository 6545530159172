import React, { useContext, useEffect, useState } from 'react';
import MainLayout from '../../hoc/MainLayout';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Button } from 'bootstrap';
import AuthContext from '../../contexts/AuthContext';
import { fetchingTeachersAndStudentsListForSchoolAdmin } from '../../api';
import { ACMContext } from '../../contexts/ACMProvider';

const AdminStudent = () => {
  const { ACM } = useContext(ACMContext);
  const { user, typeOfUser, updateGlobalUser } = useContext(AuthContext);
  const [fetchedStudents, setFetchedStudents] = useState([]);

  const fetchStudentsData = async () => {
    if (ACM[typeOfUser]?.isSchoolAdmin) {
      try {
        const fetchedData = await fetchingTeachersAndStudentsListForSchoolAdmin(
          user?.uid,
          'SCHOOLADMIN'
        );
        setFetchedStudents(fetchedData);
      } catch (err) {
        console.log('Error fetching students:', err);
      }
    }
  };

  useEffect(() => {
    if (ACM[typeOfUser]?.isSchoolAdmin) {
      fetchStudentsData();
    }
  }, [user, typeOfUser]);
  return (
    <MainLayout>
      <Box sx={{ padding: '20px' }}>
        <Typography variant='h4' gutterBottom>
          Students
        </Typography>

        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell style={{ fontWeight: 'bold' }}>
                Admission Number
              </TableCell> */}
                <TableCell style={{ fontWeight: 'bold' }}>
                  Student Name
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  Student Email
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  Parent Name
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  School Name
                </TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>
                  Teacher Name
                </TableCell>
                {/* <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {fetchedStudents.studentsList?.length > 0 ? (
                fetchedStudents?.studentsList?.map((student) => (
                  <TableRow key={student.info?.id}>
                    <TableCell>{student?.info.fullname}</TableCell>
                    <TableCell>{student?.info.email}</TableCell>
                    <TableCell>{student?.info.parentName}</TableCell>
                    <TableCell>{student?.info.schoolName}</TableCell>
                    <TableCell>{student?.info.teacherName}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align='center'>
                    No students data.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </MainLayout>
  );
};

export default AdminStudent;
