import { Typography } from "@mui/material";
import React from "react";

import Logo from "../../assets/images/WhiteLogo.png";

import "./BrandLogo.css";

const BrandLogo = ({ newline }) => {
  const [colorChange, setColorChange] = React.useState(false)
  return (
    <div
      className={`BrandLogo flexBox flexCenter ${
        newline ? "flexColumn" : "flexRow"
      }`}
    >
      <img src={Logo} alt="Logo" />
      <Typography
              className="brand"
              variant="h6"
              noWrap
              
              sx={{
                // display: { xs: "none", md: "flex" },
                letterSpacing: ".1rem",
                fontSize: "1.5rem !important",
                textDecoration: "none",
                marginTop:"0.5rem"
              }}
            >
              Mindly
            </Typography>
       
    </div>
  );
};

export default BrandLogo;
