import { ACM } from '../../constants/UserRoles';
import {
  Box,
  Switch,
  FormControlLabel,
  FormControl,
  Button,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
import React, { useState, useContext } from 'react';
import useStudentData from '../../hooks/useStudentsData';
import useTeachersData from '../../hooks/useTeachersData';
import useOrganizationData from '../../hooks/useOrganizationData';
import useSchoolAdminsData from '../../hooks/useSchoolAdminsData';
import AuthContext from '../../contexts/AuthContext';
import {
  saveDifferencesToFirestore,
  fetchAndMergePermissions,
} from './PermissionsService';

import { ToastContainer, toast } from 'react-toastify';

const UserPermissions = () => {
  const { user, typeOfUser } = useContext(AuthContext);

  const [selectedUserRole, setSelectedUserRole] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const userPermissions = {};
  const roleNames = Object.keys(ACM).filter((role) => role !== 'SUPER_ADMIN');

  roleNames.forEach((role) => {
    const properties = ACM[role];
    userPermissions[role] = {};

    Object.keys(properties).forEach((key) => {
      if (typeof properties[key] === 'boolean') {
        userPermissions[role][key] = properties[key];
      }
    });
  });

  const {
    data: fetchTeachers = [],
    isLoading: isTeachersLoading,
    refetch: refetchTeachers,
  } = useTeachersData(user?.uid, typeOfUser);

  const fetchedTeachers =
    fetchTeachers?.map(
      (teacher) => teacher?.name || teacher?.fullname || 'Unknown Teacher'
    ) || [];

  const {
    data: fetchStudent = [],
    isLoading: isStudentsLoading,
    refetch: refetchStudents,
  } = useStudentData(user?.uid, typeOfUser);

  const fetchedStudents =
    fetchStudent?.map(
      (student) => student?.name || student?.fullname || 'Unknown Student'
    ) || [];

  const { data: fetchOrgData = [], isLoading: isOrgLoading } =
    useOrganizationData(user?.uid, typeOfUser);

  const fetchedOrgAdmins =
    fetchOrgData
      ?.filter((admin) => admin !== null && admin !== undefined)
      .map((admin) => admin?.name || 'Unknown Org Admin') || [];

  const { data: schoolAdmins = [], isLoading: isSchoolAdminsLoading } =
    useSchoolAdminsData(user?.uid, typeOfUser);

  const fetchedSchoolAdmins =
    schoolAdmins?.map((admin) => admin?.name || 'Unknown School Admin') || [];

  const fetchUsersList = (roleName) => {
    if (roleName === 'STUDENT' || roleName === 'PATIENT') {
      setUsersList(fetchedStudents);
    }
    if (roleName === 'TEACHER') {
      setUsersList(fetchedTeachers);
    }
    if (roleName === 'ORGANIZATIONADMIN') {
      setUsersList(fetchedOrgAdmins);
    }
    if (roleName === 'SCHOOLADMIN') {
      setUsersList(fetchedSchoolAdmins);
    }
    if (roleName === 'ADMIN') {
      setUsersList([]);
    }
    if (roleName === 'DOCTOR') {
      setUsersList([]);
    }
  };

  const handleUserRoleChange = (event) => {
    setSelectedUserRole(event.target.value);
    fetchUsersList(event.target.value);
    setSelectedUser('');
    setSelectedPermissions([]);
    setSelectedValues([]);
  };

  const handleUserChange = async (event) => {
    const roleName = selectedUserRole;
    const selectedUserName = event.target.value;
    setSelectedUser(selectedUserName);
    setSelectedPermissions([]);
    setSelectedValues([]);

    if (roleName === 'STUDENT' || roleName === 'PATIENT') {
      const selectedStudent = fetchStudent.find(
        (user) => user.name === selectedUserName
      );

      if (selectedStudent) {
        const permissions = await fetchAndMergePermissions(
          selectedStudent.id,
          'STUDENT'
        );
        setSelectedPermissions(Object.keys(permissions));
        setSelectedValues(Object.values(permissions));
      }
    }
    if (roleName === 'TEACHER') {
      const selectedTeacher = fetchTeachers.find(
        (user) => user.name === selectedUserName
      );
      if (selectedTeacher) {
        const permissions = await fetchAndMergePermissions(
          selectedTeacher.id,
          'TEACHER'
        );
        setSelectedPermissions(Object.keys(permissions));
        setSelectedValues(Object.values(permissions));
      }
    }
    if (roleName === 'ORGANIZATIONADMIN') {
      const selectedOrgAdmin = fetchOrgData.find(
        (user) => user.name === selectedUserName
      );
      if (selectedOrgAdmin) {
        const permissions = await fetchAndMergePermissions(
          selectedOrgAdmin.id,
          'ORGANIZATIONADMIN'
        );
        setSelectedPermissions(Object.keys(permissions));
        setSelectedValues(Object.values(permissions));
      }
    }
    if (roleName === 'SCHOOLADMIN') {
      const selectedSchoolAdmin = schoolAdmins.find(
        (user) => user.name === selectedUserName
      );
      if (selectedSchoolAdmin) {
        const permissions = await fetchAndMergePermissions(
          selectedSchoolAdmin.id,
          'SCHOOLADMIN'
        );

        setSelectedPermissions(Object.keys(permissions));
        setSelectedValues(Object.values(permissions));
      }
    }
    if (roleName === 'ADMIN') {
      setSelectedPermissions(Object.keys(userPermissions['ADMIN']));
      setSelectedValues(Object.values(userPermissions['ADMIN']));
    }
    if (roleName === 'DOCTOR') {
      setSelectedPermissions(Object.keys(userPermissions['DOCTOR']));
      setSelectedValues(Object.values(userPermissions['DOCTOR']));
    }
  };

  const formatRoleName = (roleName) => {
    if (roleName === 'SCHOOLADMIN') {
      return 'School Admin';
    } else if (roleName === 'ORGANIZATIONADMIN') {
      return 'Organization Admin';
    }

    return (
      roleName.toLowerCase().charAt(0).toUpperCase() +
      roleName.slice(1).toLowerCase()
    );
  };

  const handleToggle = (permission) => {
    const index = selectedPermissions.indexOf(permission);
    const updatedValues = [
      ...selectedValues.slice(0, index),
      !selectedValues[index],
      ...selectedValues.slice(index + 1),
    ];
    setSelectedValues(updatedValues);
  };

  const handleSave = async () => {
    const updatedPermissions = selectedPermissions.reduce((obj, key, index) => {
      obj[key] = selectedValues[index];
      return obj;
    }, {});
    if (selectedUserRole === 'STUDENT' || selectedUserRole === 'PATIENT') {
      const curStudent = fetchStudent.find(
        (student) => student.name === selectedUser
      );
      if (!curStudent) {
        console.error(`No student found with name: ${selectedUser}`);
        return;
      }
      saveDifferencesToFirestore(curStudent.id, 'STUDENT', updatedPermissions);
    }

    if (selectedUserRole === 'TEACHER') {
      const curTeacher = fetchTeachers.find(
        (teacher) => teacher.name === selectedUser
      );
      if (!curTeacher) {
        console.error(`No teacher found with name: ${selectedUser}`);
        return;
      }

      saveDifferencesToFirestore(
        curTeacher.id,
        selectedUserRole,
        updatedPermissions
      );
    }
    if (selectedUserRole === 'SCHOOLADMIN') {
      const curSchoolAdmin = schoolAdmins.find(
        (schoolAdmin) => schoolAdmin.name === selectedUser
      );
      if (!curSchoolAdmin) {
        console.error(`No school admin found with name: ${selectedUser}`);
        return;
      }

      saveDifferencesToFirestore(
        curSchoolAdmin.id,
        selectedUserRole,
        updatedPermissions
      );
    }
    if (selectedUserRole === 'ORGANIZATIONADMIN') {
      const curOrgAdmin = fetchOrgData.find(
        (orgAdmin) => orgAdmin.name === selectedUser
      );
      if (!curOrgAdmin) {
        console.error(`No org admin found with name: ${selectedUser}`);
        return;
      }

      saveDifferencesToFirestore(
        curOrgAdmin.id,
        selectedUserRole,
        updatedPermissions
      );
    }

    toast.success('Permissions updated successfully.', {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  return (
    <Box>
      <Box>
        <FormControl
          fullWidth
          sx={{
            marginBottom: '20px',
            color: 'var(--clr-theme-purple) !important',
          }}
        >
          <InputLabel
            shrink={true}
            sx={{
              color: 'var(--clr-theme-purple) !important',
              top: '-7px',
            }}
          >
            Select a role type
          </InputLabel>

          <Select
            value={selectedUserRole}
            onChange={handleUserRoleChange}
            displayEmpty
            sx={{
              fontFamily: 'Lato',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--clr-theme-purple)',
              },
            }}
          >
            <MenuItem value='' disabled>
              Select a role type
            </MenuItem>
            {roleNames.map((roleName) => (
              <MenuItem key={roleName} value={roleName}>
                {formatRoleName(roleName)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <FormControl
          fullWidth
          sx={{
            marginBottom: '20px',
            color: 'var(--clr-theme-purple) !important',
          }}
        >
          <InputLabel
            shrink={true}
            sx={{
              color: 'var(--clr-theme-purple) !important',
              top: '-7px',
            }}
          >
            Select a User
          </InputLabel>

          <Select
            value={selectedUser}
            onChange={handleUserChange}
            displayEmpty
            sx={{
              fontFamily: 'Lato',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--clr-theme-purple)',
              },
            }}
          >
            <MenuItem value='' disabled>
              Select a User
            </MenuItem>
            {usersList.map((userListItem) => (
              <MenuItem key={userListItem} value={userListItem}>
                {formatRoleName(userListItem)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {selectedUserRole != '' && selectedUser != '' && (
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              onClick={() => handleSave()}
              sx={{
                backgroundColor: 'var(--clr-theme-purple)',
                '&:hover': {
                  background: 'var(--clr-theme-purple-50)',
                },
                color: 'var(--clr-theme-white)',
                fontFamily: 'Lato',
              }}
            >
              Save Changes
            </Button>
          </Box>
          <Box
            sx={{
              maxHeight: '500px',
              overflowY: 'auto',
            }}
          >
            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
              {selectedPermissions.map((permission, index) => {
                const value = selectedValues[index];

                return (
                  <li
                    key={permission}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      backgroundColor:
                        index % 2 === 0
                          ? 'var(--clr-theme-white)'
                          : 'var(--clr-theme-purple-50)',
                      padding: '10px',
                    }}
                  >
                    {permission}
                    <FormControlLabel
                      control={
                        <Switch
                          checked={value}
                          onChange={() => handleToggle(permission)}
                          sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                              color: 'var(--clr-theme-white)',
                            },
                            '& .MuiSwitch-thumb': {
                              border: '1px solid var(--clr-theme-purple)',
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                              {
                                backgroundColor: '#9c26b0',
                              },
                          }}
                        />
                      }
                    />
                  </li>
                );
              })}
            </ul>
          </Box>
        </Box>
      )}
      <ToastContainer />
    </Box>
  );
};

export default UserPermissions;
