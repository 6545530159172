import { ACM } from '../../constants/UserRoles';
import {
  Box,
  Tabs,
  Tab,
  Switch,
  FormControlLabel,
  Button,
} from '@mui/material';
import React, { useState } from 'react';

const RolePermissions = () => {
  const userPermissions = {};
  const roleNames = Object.keys(ACM).filter((role) => role !== 'SUPER_ADMIN');

  roleNames.forEach((role) => {
    const properties = ACM[role];
    userPermissions[role] = {};

    Object.keys(properties).forEach((key) => {
      if (typeof properties[key] === 'boolean') {
        userPermissions[role][key] = properties[key];
      }
    });
  });

  const [role, setRole] = useState(roleNames[0]);
  const [rolePermissionsList, setRolePermissionsList] = useState(
    userPermissions[role]
  );

  const handleTabChange = (event, newValue) => {
    setRole(newValue);
    setRolePermissionsList(userPermissions[newValue]);
  };

  const handleToggle = (permission) => {
    const updatedPermissions = {
      ...rolePermissionsList,
      [permission]: !rolePermissionsList[permission],
    };

    setRolePermissionsList(updatedPermissions);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={role}
        onChange={handleTabChange}
        variant='fullWidth'
        aria-label='Role Permissions Tabs'
        className='custom-tabs'
      >
        {roleNames.map((roleName) => (
          <Tab
            key={roleName}
            label={
              roleName === 'SCHOOLADMIN'
                ? 'School Admin'
                : roleName === 'ORGANIZATIONADMIN'
                ? 'Organization Admin'
                : roleName.toLowerCase().charAt(0).toUpperCase() +
                  roleName.slice(1).toLowerCase()
            }
            value={roleName}
            className='custom-tab'
          />
        ))}
      </Tabs>

      <Box sx={{ p: 3 }}>
        <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
          {Object.entries(rolePermissionsList).map(
            ([permission, value], index) => (
              <li
                key={permission}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor:
                    index % 2 === 0
                      ? 'var(--clr-theme-white)'
                      : 'var(--clr-theme-purple-50)',
                  padding: '10px',
                }}
              >
                {permission}
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={() => handleToggle(permission)}
                      sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: 'var(--clr-theme-white)',
                        },
                        '& .MuiSwitch-thumb': {
                          border: '1px solid var(--clr-theme-purple)',
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                          {
                            backgroundColor: '#9c26b0',
                          },
                      }}
                    />
                  }
                />
              </li>
            )
          )}
        </ul>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            sx={{
              backgroundColor: 'var(--clr-theme-purple)',
              color: 'var(--clr-theme-white)',
              fontFamily: 'Lato',
            }}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default RolePermissions;
