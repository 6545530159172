import React, { useContext, useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
  Box,
  Typography,
  Grid,
  Avatar,
  FormLabel,
  Switch,
  Divider,
  Tooltip,
} from '@mui/material';
import MainLayout from '../../hoc/MainLayout';
import './AddGoal.css';
import useStudentsForOneTeacher from '../../hooks/useStudentsForOneTeacher';
import AuthContext from '../../contexts/AuthContext';
import NewGoalDialog from '../../components/NewGoalDialog';

const AddGoalDetails = () => {
  const { user, typeOfUser } = useContext(AuthContext);
  const [formState, setFormSate] = useState({
    StudentName: '',
    goalTitle: '',
    goalNumber: '',
    goalCategory: '',
    typeOfGoal: '',
    behaviorType: '',
    behavior: '',
    definition: '',
    onset: '',
    offset: '',
    measurementType: '',
    goalMeasurement: '',
    target: 0,
    duration: '',
    multipleDataPoints: false,
    dataPointAction: '',
    multipleSessions: false,
    consecutive: false,
    sessionsRequired: '',
    byDate: '',
    baseline: '',
    notes: '',
    isSubgoal: false,
    goal: '',
    parentGoal: '',
    editAccess: [],
    viewAccess: [],
    generateGraphs: true,
    generateReports: true,
    shareWithTeam: false,
    dataType: '',
    minutes: '',
    trials: '',
    isPromptingApplicable: false,
    promptingType: '',
    pMinus: '',
    pPlus: '',
    I: '',
    VP: '',
    IVP: '',
    DVP: '',
    PVP: '',
    GP: '',
    PP: '',
  });

  const addGoalDetailsSaveHandler = () => {};
  const [dataEntryDialogOpen, setDataEntryDialogOpen] = useState(false);
  const {
    data: fetchStudentsForOneTeacher = [],
    isLoading: isTeachersLoading,
    error: teachersError,
    refetch: refetchTeachers,
  } = useStudentsForOneTeacher(user?.uid, typeOfUser);

  const enrolledStudents = useMemo(
    () => fetchStudentsForOneTeacher || [],
    [fetchStudentsForOneTeacher]
  );

  console.log('Enrolled student', enrolledStudents);

  const truncateName = (name, limit = 10) => {
    const capitalized = capitalizeWords(name);
    if (capitalized?.length > limit) {
      return `${capitalized.slice(0, limit)}...`;
    }
    return capitalized;
  };

  const capitalizeWords = (name) => {
    return name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormSate((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleDataEntry = () => {
    setDataEntryDialogOpen(true);
  };

  return (
    <MainLayout>
      <Box className='add-goal-details'>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              overflow: 'hidden',
              maxHeight: '100%',
            }}
          >
            <Typography variant='h5' className='heading' textAlign='center'>
              New Goal Details
            </Typography>

            <Box className='container'>
              <Button
                onClick={handleDataEntry}
                className='button'
                variant='outlined'
              >
                Data Entry
              </Button>
              <Button className='button' variant='outlined'>
                Goals
              </Button>
              <Button className='button' variant='outlined'>
                Statistics
              </Button>
              <Button className='button' variant='outlined'>
                Reports
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            style={{
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 10px)',
              padding: '3rem',
              boxSizing: 'border-box',
            }}
            className='grid-item grid-item-md'
          >
            <Box className='select-student-box' gap={2}>
              <Typography className='select-student-typography' gutterBottom>
                Select Student
              </Typography>
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id='demo-simple-select-label'>
                  Select Student
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={formState.StudentName}
                  label='Select Student'
                  name='StudentName'
                  onChange={handleChange}
                >
                  <MenuItem value='All'>All Students</MenuItem>
                  {enrolledStudents.map((student) => (
                    <MenuItem key={student.studentId} value={student.name}>
                      {truncateName(student?.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box className='student-info-box' gap={2}>
              <Avatar />
              <TextField
                label='Student Name'
                variant='outlined'
                fullWidth
                className='student-textfield'
              />
              <TextField
                label='Student ID'
                variant='outlined'
                fullWidth
                className='student-textfield'
              />
            </Box>
            <Box className='goal-box'>
              <Box className='goal-box-flex'>
                <Typography className='goal-box-typography' gutterBottom>
                  Goal Title
                </Typography>
                <TextField
                  label='Goal Title'
                  placeholder='Ex: Screaming'
                  fullWidth
                  className='goal-textfield white-textfield'
                />
              </Box>
              <Box className='goal-box-flex'>
                <Typography className='goal-box-typography' gutterBottom>
                  Goal Number
                </Typography>
                <TextField
                  label='Goal Number'
                  type='number'
                  fullWidth
                  className='goal-textfield white-textfield'
                />
              </Box>
            </Box>
            <Typography className='goal-box-typography' gutterBottom>
              Goal Category
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                mb: 2,
                fontFamily: 'Lato',
              }}
            >
              {['ABA', 'Speech', 'OT', 'Classroom', 'Vision', 'Other'].map(
                (category) => (
                  <FormControlLabel
                    control={<Checkbox />}
                    label={category}
                    key={category}
                    sx={{ mr: 2 }}
                  />
                )
              )}
            </Box>
            <Typography className='goal-box-typography' gutterBottom>
              Type of Goal
            </Typography>
            <RadioGroup
              row
              name='typeOfGoal'
              value={formState.typeOfGoal}
              onChange={handleChange}
              sx={{ mb: 2 }}
            >
              <FormControlLabel
                value='behavior'
                control={<Radio />}
                label='Behavior'
              />
              <FormControlLabel
                value='skill'
                control={<Radio />}
                label='Skill Acquisition'
              />
            </RadioGroup>
            {formState.typeOfGoal === 'behavior' && (
              <Box className='select-student-box' gap={2}>
                <Typography className='select-student-typography' gutterBottom>
                  Behavior Type
                </Typography>
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel id='demo-simple-select-label'>
                    Behavior Type
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Behavior Type'
                  >
                    <MenuItem value='type1'>Type 1</MenuItem>
                    <MenuItem value='type2'>Type 2</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
            {formState.typeOfGoal === 'skill' && (
              <>
                <Box className='select-student-box' gap={2}>
                  <Typography
                    className='select-student-typography'
                    gutterBottom
                  >
                    Skill Name
                  </Typography>
                  <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel id='demo-simple-select-label'>
                      Sill Name
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      label='Sill Name'
                    >
                      <MenuItem value='All'>Dropdown</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Typography className='goal-box-typography' gutterBottom>
                  Skill
                </Typography>
                <TextField
                  placeholder='Skill Name'
                  fullWidth
                  sx={{ mb: 2, maxWidth: 300 }}
                />
                <Typography className='goal-box-typography' gutterBottom>
                  Defination
                </Typography>
                <TextField placeholder='Defination' fullWidth sx={{ mb: 2 }} />
                <Typography className='goal-box-typography' gutterBottom>
                  Natural Cue
                </Typography>
                <TextField
                  placeholder='Natural Cue'
                  fullWidth
                  sx={{ mb: 2, maxWidth: 300 }}
                />
              </>
            )}
            <Typography className='goal-box-typography' gutterBottom>
              Behavior
            </Typography>
            <TextField placeholder='Enter title' fullWidth sx={{ mb: 2 }} />
            <Typography className='goal-box-typography' gutterBottom>
              Definition
            </Typography>
            <TextField placeholder="Provider's name" fullWidth sx={{ mb: 2 }} />
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <Typography className='goal-box-typography' gutterBottom>
                  Onset
                </Typography>
                <TextField placeholder='Enter progress notes here' fullWidth />
              </Grid>
              <Grid item xs={6}>
                <Typography className='goal-box-typography' gutterBottom>
                  Offset
                </Typography>
                <TextField placeholder='Enter progress notes here' fullWidth />
              </Grid>
            </Grid>

            <Divider sx={{ my: 2, borderBottomWidth: 3, bgcolor: 'black' }} />

            <FormControl component='fieldset' fullWidth sx={{ p: 1 }}>
              <Typography variant='h5' className='heading' gutterBottom>
                Data Collection
              </Typography>
              <Typography className='goal-box-typography' gutterBottom>
                How is this goal measured?
              </Typography>
              <RadioGroup
                row
                name='goalMeasurement'
                value={formState.goalMeasurement}
                onChange={handleChange}
              >
                <FormControlLabel
                  className='goal-box-typography'
                  value='Frequency'
                  control={<Radio />}
                  label='Frequency'
                />
                <FormControlLabel
                  className='goal-box-typography'
                  value='Duration'
                  control={<Radio />}
                  label='Duration'
                />
                <FormControlLabel
                  className='goal-box-typography'
                  value='Percentage'
                  control={<Radio />}
                  label='Percentage'
                />
                <FormControlLabel
                  className='goal-box-typography'
                  value='Trials'
                  control={<Radio />}
                  label='Trials'
                />
              </RadioGroup>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                }}
              >
                {formState.goalMeasurement === 'Trials' ? (
                  <>
                    <Box>
                      {/* Trials Section */}
                      <Typography className='goal-box-typography' gutterBottom>
                        What is the data type?
                      </Typography>
                      <RadioGroup
                        row
                        name='dataType'
                        value={formState.dataType}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          className='goal-box-typography'
                          value='+/-'
                          control={<Radio />}
                          label='+/-'
                        />
                        <FormControlLabel
                          className='goal-box-typography'
                          value='%'
                          control={<Radio />}
                          label='%'
                        />
                        <FormControlLabel
                          className='goal-box-typography'
                          value='times'
                          control={<Radio />}
                          label='times'
                        />
                        <FormControlLabel
                          className='goal-box-typography'
                          value='minutes'
                          control={<Radio />}
                          label='minutes'
                        />
                        <TextField
                          sx={{ maxWidth: 100, mr: 1 }}
                          placeholder='enter unit'
                        />
                        <Typography
                          className='goal-box-typography'
                          sx={{ mt: 1.5 }}
                        >
                          others
                        </Typography>
                      </RadioGroup>
                      <Typography className='goal-box-typography'>
                        Define "+":
                      </Typography>
                      <TextField fullWidth placeholder='definition' />

                      <Typography className='goal-box-typography'>
                        Define "-":
                      </Typography>
                      <TextField fullWidth placeholder='definition' />

                      {/* Trials Input */}
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 2,
                          alignItems: 'center',
                          mt: 2,
                        }}
                      >
                        <Typography className='goal-box-typography'>
                          Trails
                        </Typography>
                        <TextField
                          type='number'
                          name='trials'
                          value={formState.trials}
                          onChange={handleChange}
                          sx={{ width: 60 }}
                        />
                        <Typography className='goal-box-typography'>
                          out of
                        </Typography>
                        <TextField type='number' sx={{ width: 60 }} />
                        <Typography className='goal-box-typography'>
                          trials
                        </Typography>
                      </Box>

                      {/* Prompting Section */}
                      <Typography
                        className='goal-box-typography'
                        sx={{ mt: 2 }}
                      >
                        Is Prompting Applicable?
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={formState.isPromptingApplicable}
                            onChange={() =>
                              setFormSate({
                                ...formState,
                                isPromptingApplicable:
                                  !formState.isPromptingApplicable,
                              })
                            }
                          />
                        }
                        label={formState.isPromptingApplicable ? 'Yes' : 'No'}
                      />

                      {formState.isPromptingApplicable && (
                        <>
                          <Typography
                            className='goal-box-typography'
                            sx={{ mt: 2 }}
                          >
                            Which Type:
                          </Typography>
                          <RadioGroup
                            name='promptingType'
                            value={formState.promptingType}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              className='goal-box-typography'
                              value='P+ & P-'
                              control={<Radio />}
                              label='P+ & P-'
                            />

                            {/* Conditional Fields for Prompting Type */}
                            {formState.promptingType === 'P+ & P-' && (
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: 2,
                                  alignItems: 'center',
                                  // flexDirection: 'column',
                                  mt: 1,
                                }}
                              >
                                <Typography className='goal-box-typography'>
                                  How many allowed per trail?
                                </Typography>
                                <Typography className='goal-box-typography'>
                                  P-
                                </Typography>
                                <TextField
                                  type='number'
                                  name='pMinus'
                                  value={formState.pMinus}
                                  onChange={handleChange}
                                  sx={{ width: 60 }}
                                />
                                <Typography className='goal-box-typography'>
                                  P+
                                </Typography>
                                <TextField
                                  type='number'
                                  name='pPlus'
                                  value={formState.pPlus}
                                  onChange={handleChange}
                                  sx={{ width: 60 }}
                                />
                              </Box>
                            )}

                            <FormControlLabel
                              className='goal-box-typography'
                              value='IVP, DVP, PVP, GP, PP'
                              control={<Radio />}
                              label='I, VP (IVP, DVP, PVP), GP, PP'
                            />
                          </RadioGroup>

                          {formState.promptingType ===
                            'IVP, DVP, PVP, GP, PP' && (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                mt: 1,
                              }}
                            >
                              <Box sx={{ display: 'flex', gap: 5 }}>
                                <Typography className='goal-box-typography'>
                                  I
                                </Typography>
                                <TextField
                                  type='number'
                                  name='I'
                                  value={formState.I}
                                  onChange={handleChange}
                                  sx={{ width: 60 }}
                                />
                                <Typography className='goal-box-typography'>
                                  VP
                                </Typography>
                                <TextField
                                  type='number'
                                  name='VP'
                                  value={formState.VP}
                                  onChange={handleChange}
                                  sx={{ width: 60 }}
                                />
                              </Box>
                              <Box sx={{ display: 'flex', gap: 4 }}>
                                <Typography className='goal-box-typography'>
                                  IVP
                                </Typography>
                                <TextField
                                  type='number'
                                  name='IVP'
                                  value={formState.IVP}
                                  onChange={handleChange}
                                  sx={{ width: 60 }}
                                />
                                <Typography className='goal-box-typography'>
                                  DVP
                                </Typography>
                                <TextField
                                  type='number'
                                  name='DVP'
                                  value={formState.DVP}
                                  onChange={handleChange}
                                  sx={{ width: 60 }}
                                />
                                <Typography className='goal-box-typography'>
                                  PVP
                                </Typography>
                                <TextField
                                  type='number'
                                  name='PVP'
                                  value={formState.PVP}
                                  onChange={handleChange}
                                  sx={{ width: 60 }}
                                />
                              </Box>
                              <Box sx={{ display: 'flex', gap: 2 }}>
                                <Typography className='goal-box-typography'>
                                  GP
                                </Typography>
                                <TextField
                                  type='number'
                                  name='GP'
                                  value={formState.GP}
                                  onChange={handleChange}
                                  sx={{ width: 60 }}
                                />
                                <Typography className='goal-box-typography'>
                                  PP
                                </Typography>
                                <TextField
                                  type='number'
                                  name='PP'
                                  value={formState.PP}
                                  onChange={handleChange}
                                  sx={{ width: 60 }}
                                />
                              </Box>
                            </Box>
                          )}
                        </>
                      )}
                    </Box>
                  </>
                ) : (
                  <>
                    {/* Target Section */}
                    <FormControl fullWidth sx={{ flex: 1, maxWidth: 300 }}>
                      <Typography className='goal-box-typography' gutterBottom>
                        Target:
                      </Typography>
                      <Select
                        name='target'
                        value={formState.target}
                        onChange={handleChange}
                        displayEmpty
                      >
                        <MenuItem value=''>Select Target</MenuItem>
                        <MenuItem value='option1'>Option 1</MenuItem>
                        <MenuItem value='option2'>Option 2</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl sx={{ maxWidth: 80, mt: 3.5 }}>
                      <TextField
                        type='number'
                        value={formState.minutes}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <Typography className='goal-box-typography' sx={{ mt: 3 }}>
                      {formState.goalMeasurement === 'Frequency' && 'f'}
                      {formState.goalMeasurement === 'Duration' && 'min'}
                      {formState.goalMeasurement === 'Percentage' && '%'}
                      {/* {formState.goalMeasurement === 'Trials' && '#'} */}
                    </Typography>
                  </>
                )}
              </Box>

              <FormControl component='fieldset' margin='normal'>
                <Typography className='goal-box-typography' gutterBottom>
                  Multiple data points per session?
                </Typography>
                <RadioGroup
                  row
                  name='multipleDataPoints'
                  value={formState.multipleDataPoints}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    className='goal-box-typography'
                    value='Yes'
                    control={<Radio />}
                    label='Yes'
                  />
                  <FormControlLabel
                    className='goal-box-typography'
                    value='No'
                    control={<Radio />}
                    label='No'
                  />
                </RadioGroup>
              </FormControl>

              {formState.multipleDataPoints === 'Yes' && (
                <FormControl
                  component='fieldset'
                  margin='normal'
                  sx={{ ml: 4 }}
                >
                  <Typography className='goal-box-typography' gutterBottom>
                    What do we do with multiple data points per sessions?
                  </Typography>
                  <RadioGroup
                    row
                    name='dataOperation'
                    value={formState.dataOperation}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      className='goal-box-typography'
                      value='average'
                      control={<Radio />}
                      label='Average'
                    />
                    <FormControlLabel
                      className='goal-box-typography'
                      value='add'
                      control={<Radio />}
                      label='Add'
                    />
                    <FormControlLabel
                      className='goal-box-typography'
                      value='highest'
                      control={<Radio />}
                      label='Take Highest'
                    />
                    <FormControlLabel
                      className='goal-box-typography'
                      value='lowest'
                      control={<Radio />}
                      label='Take Lowest'
                    />
                  </RadioGroup>
                </FormControl>
              )}

              <FormControl component='fieldset' margin='normal'>
                <Typography className='goal-box-typography' gutterBottom>
                  Does target have to be achieved multiple times to meet goals?
                </Typography>
                <RadioGroup
                  row
                  name='multipleAchievements'
                  value={formState.multipleAchievements}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    className='goal-box-typography'
                    value='Yes'
                    control={<Radio />}
                    label='Yes'
                  />
                  <FormControlLabel
                    className='goal-box-typography'
                    value='No'
                    control={<Radio />}
                    label='No'
                  />
                </RadioGroup>
              </FormControl>

              {formState.multipleAchievements === 'Yes' && (
                <Box className='select-student-box' gap={2} sx={{ ml: 4 }}>
                  <Typography className='goal-box-typography' gutterBottom>
                    How many sessions?
                  </Typography>
                  <TextField
                    label='Sessions'
                    type='number'
                    name='sessionCount'
                    value={formState.sessionCount}
                    onChange={handleChange}
                  />
                </Box>
              )}

              {formState.multipleAchievements === 'Yes' && (
                <Box className='select-student-box' gap={2} sx={{ ml: 4 }}>
                  <Typography className='goal-box-typography' gutterBottom>
                    Does it have to be consecutive
                  </Typography>
                  <RadioGroup
                    row
                    name='consecutive'
                    value={formState.consecutive}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      className='goal-box-typography'
                      value='Yes'
                      control={<Radio />}
                      label='Yes'
                    />
                    <FormControlLabel
                      className='goal-box-typography'
                      value='No'
                      control={<Radio />}
                      label='No'
                    />
                  </RadioGroup>
                </Box>
              )}

              <TextField
                label='By Date'
                type='date'
                InputLabelProps={{ shrink: true }}
                name='byDate'
                value={formState.byDate}
                onChange={handleChange}
                fullWidth
                sx={{ mt: 2 }}
              />

              <TextField
                label='Baseline'
                name='baseline'
                value={formState.baseline}
                onChange={handleChange}
                fullWidth
                sx={{ mt: 2 }}
              />

              <TextField
                label='Notes'
                name='notes'
                value={formState.notes}
                onChange={handleChange}
                fullWidth
                multiline
                rows={3}
                sx={{ mt: 2 }}
              />
              <FormControl component='fieldset' margin='normal'>
                <Typography className='goal-box-typography' gutterBottom>
                  Is this a subgoal?
                </Typography>

                <RadioGroup
                  row
                  name='isSubgoal'
                  value={formState.isSubgoal}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    className='goal-box-typography'
                    value='Yes'
                    control={<Radio />}
                    label='Yes'
                  />
                  <FormControlLabel
                    className='goal-box-typography'
                    value='No'
                    control={<Radio />}
                    label='No'
                  />
                </RadioGroup>

                {formState.isSubgoal === 'Yes' && (
                  <Box className='select-student-box' gap={2}>
                    <Typography
                      className='select-student-typography'
                      gutterBottom
                    >
                      For which goal?
                    </Typography>
                    <FormControl sx={{ minWidth: 200 }}>
                      <InputLabel id='demo-simple-select-label'>
                        Goal
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        label='Goal'
                      >
                        <MenuItem value='type1'>Type 1</MenuItem>
                        <MenuItem value='type2'>Type 2</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                )}
              </FormControl>
              <Divider sx={{ my: 2, borderBottomWidth: 3, bgcolor: 'black' }} />
              <FormControlLabel
                control={
                  <Switch
                    checked={formState.generateGraphs}
                    onChange={handleChange}
                    name='generateGraphs'
                  />
                }
                label='Generate graphs'
                labelPlacement='start'
                className='goal-box-typography'
                sx={{ width: '100%', justifyContent: 'space-between', m: 0 }}
              />
              <Divider sx={{ my: 2, borderBottomWidth: 3, bgcolor: 'black' }} />
              <FormControlLabel
                control={
                  <Switch
                    checked={formState.generateReports}
                    onChange={handleChange}
                    name='generateReports'
                  />
                }
                className='goal-box-typography'
                labelPlacement='start'
                label='Generate reports'
                sx={{ width: '100%', justifyContent: 'space-between', m: 0 }}
              />
              <Divider sx={{ my: 2, borderBottomWidth: 3, bgcolor: 'black' }} />

              <FormControlLabel
                control={
                  <Switch
                    checked={formState.shareWithTeam}
                    onChange={handleChange}
                    name='shareWithTeam'
                  />
                }
                label='Share with team'
                labelPlacement='start'
                className='goal-box-typography'
                sx={{ width: '100%', justifyContent: 'space-between', m: 0 }}
              />
              {formState.shareWithTeam && (
                <Box sx={{ mt: 2 }}>
                  {/* Edit & View Access */}
                  <Typography
                    variant='subtitle2'
                    sx={{ fontWeight: 'bold', mt: 1 }}
                    className='goal-box-typography'
                  >
                    Edit & View Access:
                  </Typography>
                  <FormControl component='fieldset'>
                    <RadioGroup row>
                      {[
                        'ABA Team 1',
                        'ABA Team 2',
                        'SLP Team 1',
                        'SLP Team 2',
                        'Class Team 1',
                        'Class Team 2',
                        'Admin',
                        'Parent/Guardian',
                      ].map((team) => (
                        <FormControlLabel
                          className='goal-box-typography'
                          key={team}
                          value={team}
                          control={<Radio size='small' />}
                          label={team}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>

                  {/* View Only Access */}
                  <Typography
                    variant='subtitle2'
                    sx={{ fontWeight: 'bold', mt: 2 }}
                    className='goal-box-typography'
                  >
                    View Only Access:
                  </Typography>
                  <FormControl component='fieldset'>
                    <RadioGroup row>
                      {[
                        'ABA Team 1',
                        'ABA Team 2',
                        'SLP Team 1',
                        'SLP Team 2',
                        'Class Team 1',
                        'Class Team 2',
                        'Admin',
                        'Parent/Guardian',
                      ].map((team) => (
                        <FormControlLabel
                          className='goal-box-typography'
                          key={team}
                          value={team}
                          control={<Radio size='small' />}
                          label={team}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Box>
              )}
              <Divider sx={{ my: 2, borderBottomWidth: 3, bgcolor: 'black' }} />
              {/* <Button
                color='primary'
                sx={{
                  mt: 3,
                  width: '30%',
                  backgroundColor: 'var(--clr-theme-purple)',
                  color: 'var(--clr-theme-white)',
                  border: 'var(--clr-theme-purple)',
                }}
              >
                Save Changes
              </Button> */}
              <Box
                className='fullWidth flexBox flexCenter flexWrap'
                gap={2}
                mt={3}
              >
                <Button
                  variant='outlined'
                  // onClick={handleClose}
                  sx={{
                    width: '30%',
                    fontFamily: 'Lato',
                    textTransform: 'capitalize',
                    color: 'var(--clr-theme-purple)',
                    borderColor: 'var(--clr-theme-purple)',
                  }}
                  // disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  variant='extended'
                  onClick={addGoalDetailsSaveHandler}
                  sx={{
                    width: '30%',
                    // alignSelf: "end",
                    backgroundColor: 'var(--clr-theme-purple)!important',
                    color: 'var(--clr-theme-white)',
                    '&:disabled': {
                      color: 'var(--clr-theme-white) !important',
                      backgroundColor: 'rgb(211, 211, 211) !important',
                    },
                  }}
                  // disabled={isLoading}
                >
                  Save
                  {/* <SendRounded sx={{ ml: 1 }} fontSize="small" /> */}
                </Button>
                {/* {isLoading && <Progress />} */}
              </Box>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {dataEntryDialogOpen && (
        <NewGoalDialog
          open={dataEntryDialogOpen}
          onClose={() => setDataEntryDialogOpen(false)}
        />
      )}
    </MainLayout>
  );
};

export default AddGoalDetails;
