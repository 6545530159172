import React, { useContext, useState, useEffect, createContext } from 'react';
import { ACM as localACM } from '../constants/UserRoles';
import AuthContext from './AuthContext';

export const ACMContext = createContext();

const ACMProvider = ({ children }) => {
  const { user, typeOfUser } = useContext(AuthContext);
  const permissions = user?.permissions || {};

  const [ACM, setACM] = useState(localACM);

  useEffect(() => {
    if (user && typeOfUser) {
      const userACM = mergeUserPermissionsWithLocalACM(
        ACM[typeOfUser],
        permissions
      );

      const newACM = {
        ...ACM,
        [typeOfUser]: userACM,
      };

      if (JSON.stringify(ACM) !== JSON.stringify(newACM)) {
        setACM(newACM);
      }
    }
  }, [user, typeOfUser, permissions, ACM]);

  return <ACMContext.Provider value={{ ACM }}>{children}</ACMContext.Provider>;
};

export default ACMProvider;

const mergeUserPermissionsWithLocalACM = (acmForUser, permissions) => {
  return {
    ...acmForUser,
    ...Object.keys(permissions).reduce((acmHolder, key) => {
      if (acmForUser.hasOwnProperty(key)) {
        acmHolder[key] = permissions[key];
      }
      return acmHolder;
    }, {}),
  };
};
