import {
  Box,
  Button,
  DialogActions,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  tooltipClasses,
  styled,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useContext, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Reminders.css';
import usePatientsForDoctor from '../../hooks/usePatientsForDoctor';
import AuthContext from '../../contexts/AuthContext';
import CustomButton from '../CustomButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { FirestoreDB, LogAnalyticsEvent } from '../../utils/firebase/firebase';
import { addDoc, collection, getDocs } from 'firebase/firestore';
import Progress from '../Progress';
import Allreminders from './Allreminders';
import dayjs from 'dayjs';
import { ANALYTICS_KEYS } from '../../constants/AnalyticKeys';
import { ACMContext } from '../../contexts/ACMProvider';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: 'bold',
    padding: '10px',
  },
}));

const ITEM_HEIGHT = 82;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
var currentDate = new Date();

var formattedDateTime = formatTime(currentDate);

function pad(n) {
  return n < 10 ? '0' + n : n;
}

function formatTime(date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert 0 to 12 for AM/PM format
  return `${pad(formattedHours)}:${pad(minutes)} ${ampm}`;
}

const Reminders = ({ currentPatient, patient }) => {
  const { ACM } = useContext(ACMContext);
  const [patientData, setPatientData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(
    patient?.id || currentPatient?.patientId || ''
  );
  const [value, setValue] = useState(new Date());
  const [time, setTime] = useState([formattedDateTime]);
  const [textReminder, setTextReminder] = useState('');
  const [showCreateReminder, setShowCreateReminder] = useState(true); // State to control visibility of Create Reminder section
  const [selectedDates, setSelectedDates] = useState([]);
  const [active, setActive] = useState(true);
  const [isLoading, SetIsLoading] = useState(false);
  const { user, typeOfUser } = useContext(AuthContext);
  const {
    data: patients = [],
    isLoading: isPatientDataLoading,
    error: errorPatientData,
  } = usePatientsForDoctor(user?.uid);
  const [allReminders, setAllReminders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);

  useEffect(() => {
    if (ACM[typeOfUser]?.getPatientData) {
      if (patients) {
        const dataArray = patients?.map((each) => ({
          ...each?.patientInfo,
          id: each?.patientId,
          // id: each?.patientInfo?.fullname,
        }));
        setPatientData(dataArray);
      }
    }
  }, [selectedPatient, patients]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    const isSelected = selectedDates.includes(formattedDate);

    if (isSelected) {
      const updatedDates = selectedDates.filter((d) => d !== formattedDate);
      setSelectedDates(updatedDates);
    } else {
      setSelectedDates([...selectedDates, formattedDate]);
    }
  };

  const handleTextChangeHandler = (e) => {
    setTextReminder(e.target.value);
  };

  const handleViewAllReminders = () => {
    setShowCreateReminder(false); // Hide Create Reminder section when View All Reminders button is clicked
  };

  const addTimePicker = () => {
    setTime([...time, formattedDateTime]); // Add current time to the array
  };

  const deleteTimePicker = (indexToDelete) => {
    const updatedTimes = time.filter((_, index) => index !== indexToDelete);
    setTime(updatedTimes);
  };

  const updateTime = (index, newTime) => {
    try {
      const formattedNewTime = formatTime(new Date(newTime));
      const updatedTimes = [...time];
      updatedTimes[index] = formattedNewTime;
      setTime(updatedTimes);
    } catch (error) {
      console.error('Error updating time:', error);
    }
  };

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for AM/PM format
    return `${formattedHours}:${pad(minutes)} ${ampm}`;
  };

  function pad(n) {
    return n < 10 ? '0' + n : n;
  }

  const resetForm = () => {
    setSelectedPatient(patient?.id || currentPatient?.patientId || '');
    setValue(new Date());
    setTime([formattedDateTime]);
    setTextReminder('');
    setSelectedDates([]);
    SetIsLoading(false); // Reset loading state to false
  };
  const setReminderHandler = async () => {
    // Set loading to true when reminder is being added
    SetIsLoading(true);

    try {
      const timeZone = Intl.DateTimeFormat().resolvedOptions()?.timeZone;

      const remindersData = {
        message: textReminder || '',
        dates: selectedDates || [],
        times: time || [],
        active: active,
        timeZone,
        doctorId: user?.uid,
      };

      await addDoc(
        collection(FirestoreDB, 'notifications', selectedPatient, 'reminders'),
        remindersData
      );
      LogAnalyticsEvent(ANALYTICS_KEYS.DASHBOARD.REMINDERS, {
        message: textReminder || '',
        dates: selectedDates || [],
        times: time || [],
        active: active,
        timeZone,
        doctorId: user?.uid,
      });

      setSuccessPopUp(true);
      resetForm();
    } catch (error) {
      console.error('Error adding reminder:', error.message);
      // Handle error here, such as showing a toast message or displaying an error modal
    } finally {
      // Set loading to false when the operation completes (whether success or failure)
      SetIsLoading(false);
    }
  };

  const getRemindersData = async (patientId) => {
    // Set loading state to true when fetching data starts
    setLoading(true);

    try {
      const remindersRef = collection(
        FirestoreDB,
        'notifications',
        patientId,
        'reminders'
      );
      const remindersSnapshot = await getDocs(remindersRef);
      const remindersData = remindersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return remindersData;
    } catch (error) {
      console.error('Error fetching reminders data:', error.message);
      // Handle error here
    } finally {
      // Clear loading state when data fetching is complete
      setLoading(false);
    }
  };

  const fetchReminders = async () => {
    if (patientData) {
      const allPatientReminders = []; // Array to store all reminders for all patients
      for (const patient of patientData) {
        const reminderData = await getRemindersData(patient.id);
        allPatientReminders.push({
          patientId: patient?.id,
          reminders: reminderData,
          patientName: patient?.fullname,
          patientPhotoURL: patient?.photoURL,
        });
      }
      setAllReminders(allPatientReminders);
    }
  };
  const deleteDatePicker = (indexToDelete) => {
    const updatedDates = selectedDates.filter(
      (_, index) => index !== indexToDelete
    );
    setSelectedDates(updatedDates);
  };

  useEffect(() => {
    fetchReminders(); // Call the async function to fetch reminders
  }, [patientData, showCreateReminder]);

  const capitalizeWords = (name) => {
    return name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const truncateName = (name, limit = 10) => {
    const capitalized = capitalizeWords(name);
    if (capitalized?.length > limit) {
      return `${capitalized.slice(0, limit)}...`;
    }
    return capitalized;
  };

  if (isLoading) return <Progress />;

  return (
    <>
      {showCreateReminder ? (
        <>
          <Box
            className='fullWidth fullHeight flexBox flexColumn Reminders'
            gap={2}
            sx={{
              border: '1px solid',
              p: 3,
              backgroundColor: '#e4e4e4',
              borderRadius: 1,
            }}
          >
            <>
              <Box>
                <Typography variant='h6' gutterBottom>
                  Create Reminder
                </Typography>
                <TextField
                  value={textReminder}
                  onChange={handleTextChangeHandler}
                  fullWidth
                  sx={{ backgroundColor: '#fff' }}
                />
              </Box>
              <Box>
                <FormControl fullWidth>
                  <Typography variant='h6' gutterBottom>
                    Patient
                  </Typography>
                  <Select
                    disabled={selectedPatient}
                    key={selectedPatient}
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={selectedPatient}
                    onChange={(e) => {
                      setSelectedPatient(e.target.value);
                    }}
                    sx={{ backgroundColor: '#fff' }}
                    MenuProps={MenuProps}
                  >
                    {patientData?.map((patient) => {
                      return (
                        <MenuItem key={patient.id} value={patient?.id}>
                          <BootstrapTooltip
                            title={
                              patient?.parentName
                                ? `${patient.fullname} (${patient.parentName})`
                                : patient?.fullname
                            }
                          >
                            {patient?.parentName
                              ? `${truncateName(
                                  patient.fullname
                                )} (${truncateName(patient.parentName)})`
                              : `${truncateName(patient.fullname)}`}
                          </BootstrapTooltip>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography gutterBottom>Select Date/Time</Typography>
                <Calendar
                  value={selectedDates}
                  onChange={handleDateChange}
                  //  selectRange={false} // Ensure selectRange is false for multiple date selection
                  tileClassName={({ date }) =>
                    selectedDates.some(
                      (selectedDate) => selectedDate === formatDate(date)
                    )
                      ? 'highlight'
                      : null
                  }
                  tileDisabled={
                    ({ date, view }) => view === 'month' && date < new Date() // Disable past dates for month view
                  }
                />
                <div>
                  Selected Dates:
                  <ul>
                    {selectedDates?.map((date, index) => (
                      <>
                        <li key={index}>
                          {date}
                          <IconButton onClick={() => deleteDatePicker(index)}>
                            <DeleteIcon color='error' />
                          </IconButton>
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </Box>
              <Box>
                <Typography gutterBottom>Select Time</Typography>
                {time?.map((time, index) => (
                  <Box key={index} mb={1}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        value={dayjs(time, 'HH:mm A')}
                        onChange={(val) => updateTime(index, val)}
                        renderInput={(params) => (
                          <TextField
                            value={time}
                            onChange={handleTextChangeHandler}
                            {...params}
                            InputLabelProps={{ style: { color: 'gray' } }}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: 'gray', // Change border color to gray
                                },
                                '& .MuiInputLabel-root': {
                                  color: 'gray', // Change label color to gray
                                },
                                '& .MuiOutlinedInput-input': {
                                  color: 'gray', // Change input text color to gray
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>

                    <IconButton onClick={() => deleteTimePicker(index)}>
                      <DeleteIcon color='error' />
                    </IconButton>
                  </Box>
                ))}

                <Button
                  variant='contained'
                  onClick={addTimePicker}
                  sx={{ mt: 1 }}
                  color='secondary'
                >
                  Add
                </Button>
              </Box>
            </>
          </Box>
          <Box
            className='flexBox flexColumn'
            sx={{ width: '100%', gap: 2, mt: 2 }}
          >
            <CustomButton autoFocus ChangeHandler={setReminderHandler}>
              Set Reminders
            </CustomButton>
            <Button
              variant='text'
              sx={{
                textTransform: 'capitalize',
                textDecoration: 'underline',
                color: 'black',
              }}
              onClick={handleViewAllReminders} // Handle click event for View All Reminders button
            >
              View All Reminders
            </Button>
          </Box>
        </>
      ) : (
        <Box className='fullWidth fullHeight'>
          <Allreminders
            allReminders={allReminders}
            selectedPatient={selectedPatient}
            patientData={patientData}
            time={time}
            setActive={setActive}
            fetchReminders={fetchReminders}
            loading={loading}
          />
          <Button
            sx={{
              textTransform: 'capitalize',
              color: 'black',
              textDecoration: 'underline',
            }}
            fullWidth
            onClick={() => setShowCreateReminder(true)}
          >
            Switch To Reminder
          </Button>
        </Box>
      )}
      <Dialog open={successPopUp} onClose={() => setSuccessPopUp(false)}>
        <DialogTitle>
          <Typography
            variant='h4'
            align='center'
            style={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold' }}
          >
            {'Set Reminder'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              color: 'var(--clr-theme-gray)',
              fontFamily: 'Karla',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            Your reminder has been set successfully!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='success'
            onClick={() => setSuccessPopUp(false)}
            sx={{
              backgroundColor: '#9291f0',
              textTransform: 'capitalize',
              fontFamily: 'Lato',
              fontWeight: 400,
              fontSize: '1.2rem',
              width: '100%',
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Reminders;
