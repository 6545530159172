import { ArrowRightAlt } from '@mui/icons-material';
import {
  Box,
  Fab,
  List,
  ListItem,
  ListItemText,
  Typography,
  Skeleton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MainLayout from '../../hoc/MainLayout';
import PatientDetails from './PatientDetails';
import FullScreenDialog from '../../components/FullScreenDialog';
import VideoListPatient from '../../components/VideoListforPatientOverView';
import './PatientOverview.css';
import AuthContext from '../../contexts/AuthContext';
import { useContext } from 'react';
import ViewPatientAllVideos from '../../components/ViewPatientAllVideos';
import SymptomRatingChart from '../../components/Statistics/symptomRatingChart';
import PatientMeds from './PatientMeds';
import PatientDiagnosis from './PatientDiagnosis';
import PatientInformation from './PatientInformation';
import PatientCard from '../../components/PatientCard';
import { UIContext } from '../../contexts/UIProvider';
import { fetchAllAttachmentsByUser } from '../../api';
import { FirestoreDB, LogAnalyticsEvent } from '../../utils/firebase/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import DeleteModel from './DeleteModel';
import { ANALYTICS_KEYS } from '../../constants/AnalyticKeys';
import { ACMContext } from '../../contexts/ACMProvider';
import useOnePatientData from '../../hooks/useOnePatientData';

const PatientOverview = () => {
  const { ACM } = useContext(ACMContext);
  const location = useLocation();
  const [patient, setPatient] = useState();
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    location.state?.patientData && setPatient(location.state?.patientData);
  }, [location.state]);

  const { user, typeOfUser } = useContext(AuthContext);
  const { updateSelectedMenu, setSelectedMenuKey } = useContext(UIContext);

  const {
    data: currentPatientData = [],
    isLoading: loadingFetchOnePatientData,
    error: errorPatientData,
    refetch: refetchCurrentPatientData,
  } = useOnePatientData(patient?.id);

  const navigate = useNavigate();

  const getPatientAttachments = async () => {
    try {
      let fetchedDocuments = await fetchAllAttachmentsByUser(
        patient?.id,
        'PATIENT'
      );

      // Filter out attachments marked as deleted
      const filteredAttachments = fetchedDocuments?.data?.filter(
        (document) => !document?.docData?.isDeleted // Exclude deleted documents
      );
      setDocuments(filteredAttachments);
    } catch (error) {
      console.log('Error fetching documents:', error);
    }
  };
  useEffect(() => {
    getPatientAttachments();
  }, [patient]);

  const handleDelete = async (docId, patientId) => {
    try {
      const docRef = doc(
        FirestoreDB,
        'historicalData',
        patientId,
        'attachments',
        docId
      );
      await updateDoc(docRef, { isDeleted: true });
      setDocuments((prevDocuments) =>
        prevDocuments.filter((doc) => doc.docId !== docId)
      );
      LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTOVERVIEW.DOCUMENTDELETE, {
        eventName: 'Patient Profile Document Delete',
        patientID: patientId,
        documentID: docId,
      });
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const openDocumentHandler = (documentUrl, documentName) => {
    navigate('/smartsearch', { state: { documentUrl, documentName } }); // for open doc in smartsearch
    LogAnalyticsEvent(ANALYTICS_KEYS.PATIENTOVERVIEW.DOCUMENTVIEW, {
      eventName: 'Patient Profile Document Viewed',
    });
  };

  useEffect(() => {
    location.state?.patientData && setPatient(location.state?.patientData);
  }, [location.state]);

  const loadingList = Array(4)
    .fill(null)
    .map((_, index) => (
      <ListItem
        key={index}
        style={{
          width: '100%',
          border: '1px solid white',
          boxShadow: '0 0 5px var(--clr-theme-purple)',
          padding: '16px',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            flexGrow: 1,
          }}
        >
          <Skeleton
            variant='rectangular'
            width='100%'
            height={15}
            animation='pulse'
            sx={{
              borderRadius: '4px',
            }}
          />
        </div>
      </ListItem>
    ));

  return (
    <MainLayout>
      <Box className='patientOverviewContainer'>
        <Box className='profile-area patientOverview'>
          <PatientCard customClass='patientOverviewCard'>
            {patient && (
              <PatientDetails
                user={user}
                patient={patient}
                currentPatientData={currentPatientData}
              />
            )}
          </PatientCard>
        </Box>
        <Box className='Diagnosis-area patientOverview'>
          <PatientCard
            customClass='patientOverviewCard'
            title={'Diagnosis'}
            textAlign={'center'}
            color={'var(--clr-theme-purple)'}
          >
            {patient && (
              <PatientDiagnosis
                patient={patient}
                currentPatientData={currentPatientData}
                loading={loadingFetchOnePatientData}
              />
            )}
          </PatientCard>
        </Box>
        <Box className='Meds-area patientOverview' height={'100%'}>
          <PatientCard
            customClass='patientOverviewCard'
            title={'Treatment Information'}
            textAlign={'center'}
            color={'var(--clr-theme-purple)'}
          >
            {patient && (
              <PatientMeds
                patient={patient}
                currentPatientData={currentPatientData}
                loading={loadingFetchOnePatientData}
                refetchCurrentPatientData={refetchCurrentPatientData}
              />
            )}
          </PatientCard>
        </Box>
        <Box className='Information-area patientOverview'>
          <PatientCard
            customClass='patientOverviewCard'
            title={'Information'}
            textAlign={'center'}
            color={'var(--clr-theme-purple)'}
          >
            {patient && (
              <PatientInformation
                patient={patient}
                setPatient={setPatient}
                currentPatientData={currentPatientData}
                loading={loadingFetchOnePatientData}
              />
            )}
          </PatientCard>
        </Box>
        <Box className='statistics-area patientOverview'>
          <PatientCard
            title={'Statistics'}
            subheader='Rating by Symptom'
            customClass='patientOverviewCard'
            color={'var(--clr-theme-purple)'}
            actions={
              <Fab
                className='viewAllButton'
                variant='extended'
                color='primary'
                size='small'
                onClick={() => {
                  setSelectedMenuKey('Statistics');
                  updateSelectedMenu('Statistics');
                  navigate('/statistics');
                }}
              >
                View all
              </Fab>
            }
          >
            <SymptomRatingChart patientID={patient?.id} />
          </PatientCard>
        </Box>
        <Box className='videos-area patientOverview'>
          <PatientCard
            customClass='patientOverviewCard'
            title='Videos'
            textAlign={'start'}
            color={'var(--clr-theme-purple)'}
            actions={
              <FullScreenDialog
                customClass='viewAllButton'
                buttonText={'View All'}
                buttonColor='primary'
                buttonIcon={<ArrowRightAlt />}
                title='All Videos'
                buttonVariant='extended'
              >
                <ViewPatientAllVideos
                  patient={patient}
                  typeOfUser={typeOfUser}
                />
              </FullScreenDialog>
            }
          >
            {ACM[typeOfUser]?.isDoctor && (
              <VideoListPatient patient={patient} typeOfUser={typeOfUser} />
            )}
          </PatientCard>
        </Box>
        <Box className='Attachment-area patientOverview'>
          <PatientCard
            customClass='patientOverviewCard'
            title='Attachments'
            textAlign={'start'}
            color={'var(--clr-theme-purple)'}
            actions={
              <Fab
                className='viewAllButton'
                variant='extended'
                color='primary'
                size='small'
                onClick={() => {
                  navigate('/historical', {
                    state: { patientId: patient?.id },
                  });
                }}
              >
                View all
              </Fab>
            }
          >
            {patient && (
              <List
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '12px',
                  marginTop: '10px',
                }}
              >
                {documents && documents?.length > 0 ? (
                  documents
                    ?.sort(
                      (doc1, doc2) =>
                        doc2.docData.createdAt._seconds -
                        doc1.docData.createdAt._seconds
                    )
                    .map((document) => (
                      <ListItem
                        key={document.docId}
                        style={{
                          width: '100%',
                          border: '1px solid white',
                          boxShadow: '0 0 5px var(--clr-theme-purple)',
                          padding: '16px',
                          borderRadius: '5px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        className='list-item'
                        onClick={() =>
                          openDocumentHandler(
                            document?.docData?.info?.url,
                            document?.docData?.info?.fileName
                          )
                        }
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            flexGrow: 1,
                          }}
                        >
                          <ListItemText
                            primary={document?.docData?.info?.fileName}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                            }}
                            className='primary'
                          />
                          <ListItemText
                            secondary={
                              <>
                                <Typography
                                  component='span'
                                  variant='body2'
                                  color='textSecondary'
                                >
                                  Date:{' '}
                                  {new Date(
                                    document.docData.createdAt._seconds * 1000
                                  ).toLocaleDateString()}{' '}
                                  {','}
                                  Time:{' '}
                                  {new Date(
                                    document.docData.createdAt._seconds * 1000
                                  ).toLocaleTimeString()}
                                </Typography>
                              </>
                            }
                            style={{ textAlign: 'right' }}
                          />
                        </div>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <DeleteModel
                            videoId={document?.docId}
                            patientId={patient?.id}
                            videoFileName={document?.docData?.info?.fileName}
                            handleDelete={() =>
                              handleDelete(document.docId, patient?.id)
                            }
                          />
                        </div>
                      </ListItem>
                    ))
                ) : loadingFetchOnePatientData ? (
                  loadingList
                ) : (
                  <div>
                    <ListItem>
                      <ListItemText primary='No documents found' />
                    </ListItem>
                  </div>
                )}
              </List>
            )}
          </PatientCard>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default PatientOverview;
