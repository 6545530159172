import React, { useContext, Suspense } from 'react';
import AuthContext from '../../../contexts/AuthContext';
import HomeCard from '../../../components/HomeCard';
import Edit from '../../../assets/images/edit.png';
import './Profile.css';
import { ACMContext } from '../../../contexts/ACMProvider';

function Profile() {
  const { ACM } = useContext(ACMContext);
  const { typeOfUser } = useContext(AuthContext);
  const UserComponent = ACM[typeOfUser]?.component;
  const Component = React.lazy(() =>
    import(`../../../components/${UserComponent}`)
  );
// console.log('UserComponent',UserComponent)
  return (
    <HomeCard
      title='Edit your profile'
      picture={Edit}
      showAvatar
      customClass='profileCard'
    >
      <Suspense fallback={<div>Loading...</div>}>
        <Component />
      </Suspense>
    </HomeCard>
  );
}

export default Profile;
