export const FIRESTORE_DOCTOR_TEMPLATE = {
  address: {
    line1: '',
    line2: '',
    street: '',
    pincode: '',
    county: '',
    state: '',
  },
  contactNo: {
    areaCode: 0,
    number: 0,
  },
  experience: 0,
  firstname: '',
  lastname: '',
  provider: { gender: 'male', npi: '', type: '' },
  specialization: [],
  workingHours: {
    end: '',
    start: '',
  },
};

export const FIRESTORE_PATIENT_TEMPLATE = {
  address: {
    line1: '',
    line2: '',
    street: '',
    pincode: '',
    county: '',
    state: '',
  },
  contactNo: {
    areaCode: 0,
    number: 0,
  },
  age: 0,
  birthWeight: 0,
  currentWeight: 0,
  diagnosis: [],
  dob: '',
  father: {
    age: 0,
    firstname: '',
    lastname: '',
  },
  mother: {
    age: 0,
    firstname: '',
    lastname: '',
  },
  firstname: '',
  lastname: '',
  treatment: {
    meds: [],
    therapy: [],
  },
  gender: '',
  height: 0,
};

export const FIRESTORE_PATIENT_HISTORY_TEMPLATE = {
  comments: '',
  dateDiagnosed: '',
  pastTreatment: '',
  remarks: [],
  treatmentStart: 0,
  treatmentEnd: 0,
};
export const FIRESTORE_TEACHER_TEMPLATE = {
  address: {
    country: '',
    line1: '',
    line2: '',
    pincode: '',
    state: '',
    street: '',
  },
  contactNo: {
    areaCode: 0,
    number: 0,
  },
  dob: '',
  fullname: '',
  gender: '',
  schoolDetails: {
    schoolBranchID: '',
    schoolID: '',
    specialized: [],
    teacherCode: '',
  },
};
export const FIRESTORE_STUDENT_TEMPLATE = {
  address: {
    country: '',
    line1: '',
    line2: '',
    pincode: '',
    state: '',
    street: '',
  },
  age: '',
  contactNo: {
    areaCode: 0,
    number: 0,
  },
  dob: '',
  email: '',
  fullname: '',
  parentName: '',
  photoURL: '',
  schoolDetails: {
    class: {
      grade: null,
      section: '',
      schoolBranchID: '',
      schoolID: '',
      schoolName: '',
    },
  },
};
export const FIRESTORE_SCHOOLADMIN_TEMPLATE = {
  address: {
    country: '',
    line1: '',
    line2: '',
    pincode: '',
    state: '',
    street: '',
  },
  contactNo: {
    areaCode: 0,
    number: 0,
  },
  dob: '',
  fullname: '',
  gender: '',
  schoolDetails: {
    schoolBranchID: '',
    schoolID: '',
    specialized: [],
    teacherCode: '',
  },
};

export const FIRESTORE_ORGANIZATIONADMIN_TEMPLATE = {
  address: {
    country: '',
    line1: '',
    line2: '',
    pincode: '',
    state: '',
    street: '',
  },
  contactNo: {
    areaCode: 0,
    number: 0,
  },
  dob: '',
  fullname: '',
  gender: '',
};
