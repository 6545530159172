import {
  Box,
  Button,
  DialogActions,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  tooltipClasses,
  styled,
  Tooltip,
  Chip,
  Checkbox,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { FirestoreDB, LogAnalyticsEvent } from '../../utils/firebase/firebase';
import { addDoc, collection, getDocs } from 'firebase/firestore';
import dayjs from 'dayjs';
import { ACMContext } from '../../contexts/ACMProvider';
import AuthContext from '../../contexts/AuthContext';
import useStudentsForOneTeacher from '../../hooks/useStudentsForOneTeacher';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: 'bold',
    padding: '10px',
  },
}));

const ITEM_HEIGHT = 82;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
var currentDate = new Date();

var formattedDateTime = formatTime(currentDate);

function pad(n) {
  return n < 10 ? '0' + n : n;
}
function formatTime(date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  return `${pad(formattedHours)}:${pad(minutes)} ${ampm}`;
}

const NewGoalDialog = ({ open, onClose }) => {
  const { ACM } = useContext(ACMContext);
  const { user, typeOfUser } = useContext(AuthContext);
  const [textReminder, setTextReminder] = useState('');
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [time, setTime] = useState([formattedDateTime]);

  const handleStudentChange = (e) => {
    const value = e.target.value;

    if (value.some((student) => student.id === 'allstudents')) {
      setSelectedStudents(isAllStudentsSelected ? [] : [...studentData]);
    } else {
      setSelectedStudents(value);
    }
  };
  const [selectedDates, setSelectedDates] = useState([]);
  const [dialogPage, setDialogPage] = useState(1);

  const {
    data: studentData = [],
    isLoading: isTeachersLoading,
    error: teachersError,
    refetch: refetchTeachers,
  } = useStudentsForOneTeacher(user?.uid, typeOfUser);

  const isAllStudentsSelected = selectedStudents.length === studentData.length;
  const capitalizeWords = (name) => {
    return name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const truncateName = (name, limit = 10) => {
    const capitalized = capitalizeWords(name);
    if (capitalized?.length > limit) {
      return `${capitalized.slice(0, limit)}...`;
    }
    return capitalized;
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    const isSelected = selectedDates.includes(formattedDate);

    if (isSelected) {
      const updatedDates = selectedDates.filter((d) => d !== formattedDate);
      setSelectedDates(updatedDates);
    } else {
      setSelectedDates([...selectedDates, formattedDate]);
    }
  };

  const handleTextChangeHandler = (e) => {
    setTextReminder(e.target.value);
  };
  const deleteDatePicker = (indexToDelete) => {
    const updatedDates = selectedDates.filter(
      (_, index) => index !== indexToDelete
    );
    setSelectedDates(updatedDates);
  };

  const updateTime = (index, newTime) => {
    try {
      const formattedNewTime = formatTime(new Date(newTime));
      const updatedTimes = [...time];
      updatedTimes[index] = formattedNewTime;
      setTime(updatedTimes);
    } catch (error) {
      console.error('Error updating time:', error);
    }
  };

  const deleteTimePicker = (indexToDelete) => {
    const updatedTimes = time.filter((_, index) => index !== indexToDelete);
    setTime(updatedTimes);
  };

  const addTimePicker = () => {
    setTime([...time, formattedDateTime]);
  };

  const handleNext = () => {
    setDialogPage(2);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDialog-paper': {
            width: '40vw',
            maxWidth: 'none',
            height: '55vw',
          },
        }}
      >
        <DialogContent>
          {dialogPage === 1 ? (
            <>
              <Box
                className='fullWidth fullHeight flexBox flexColumn Reminders'
                gap={2}
                sx={{
                  border: '1px solid',
                  p: 3,
                  borderRadius: 1,
                }}
              >
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <Typography
                      variant='h3'
                      gutterBottom
                      sx={{ fontFamily: 'Lato', fontWeight: '700' }}
                    >
                      DATA ENTRY
                    </Typography>
                  </Box>
                  <Box>
                    <FormControl fullWidth>
                      <Typography variant='h6' gutterBottom>
                        Select Students
                      </Typography>
                      <Select
                        multiple
                        value={selectedStudents}
                        onChange={handleStudentChange}
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                          >
                            {selected.map((student) => (
                              <Chip
                                sx={{ backgroundColor: '#9291f0' }}
                                key={student.id}
                                label={student.name}
                              />
                            ))}
                          </Box>
                        )}
                        getOptionLabel={(student) => student.name}
                      >
                        <MenuItem
                          key='all'
                          value={{ id: 'allstudents', name: 'All Students' }}
                        >
                          <Checkbox checked={isAllStudentsSelected} />
                          All Students
                        </MenuItem>

                        {studentData.map((student) => (
                          <MenuItem key={student.id} value={student}>
                            <Checkbox
                              checked={selectedStudents.some(
                                (s) => s.id === student.id
                              )}
                            />
                            {student.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography gutterBottom>Select Date/Time</Typography>
                    <Calendar
                      value={selectedDates}
                      onChange={handleDateChange}
                      tileClassName={({ date }) =>
                        selectedDates.some(
                          (selectedDate) => selectedDate === formatDate(date)
                        )
                          ? 'highlight'
                          : null
                      }
                      tileDisabled={({ date, view }) =>
                        view === 'month' && date < new Date()
                      }
                    />
                    <div>
                      Selected Dates:
                      <ul>
                        {selectedDates?.map((date, index) => (
                          <>
                            <li key={index}>
                              {date}
                              <IconButton
                                onClick={() => deleteDatePicker(index)}
                              >
                                <DeleteIcon color='error' />
                              </IconButton>
                            </li>
                          </>
                        ))}
                      </ul>
                    </div>
                  </Box>
                  <Box>
                    <Typography gutterBottom>Select Time</Typography>
                    {time?.map((time, index) => (
                      <Box key={index} mb={1}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            value={dayjs(time, 'HH:mm A')}
                            onChange={(val) => updateTime(index, val)}
                            renderInput={(params) => (
                              <TextField
                                value={time}
                                onChange={handleTextChangeHandler}
                                {...params}
                                InputLabelProps={{
                                  style: { color: '#9291f0' },
                                }}
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      borderColor: '#9291f0',
                                    },
                                    '& .MuiInputLabel-root': {
                                      color: '#9291f0',
                                    },
                                    '& .MuiOutlinedInput-input': {
                                      color: '#9291f0',
                                    },
                                  },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>

                        <IconButton onClick={() => deleteTimePicker(index)}>
                          <DeleteIcon color='error' />
                        </IconButton>
                      </Box>
                    ))}

                    <Button
                      variant='contained'
                      onClick={addTimePicker}
                      sx={{ mt: 1 }}
                      color='secondary'
                    >
                      Add
                    </Button>
                  </Box>
                </>
              </Box>
              <Box
                className='flexBox flexColumn'
                sx={{ width: '100%', gap: 2, mt: 2 }}
              ></Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Button
                  disabled={
                    selectedDates.length === 0 ||
                    selectedStudents.length === 0 ||
                    time.length === 0
                  }
                  sx={{
                    backgroundColor:
                      selectedDates.length === 0 ||
                      selectedStudents.length === 0 ||
                      time.length === 0
                        ? 'lightgray'
                        : 'var(--clr-theme-purple)',
                    '&:hover': {
                      background:
                        selectedDates.length === 0 ||
                        selectedStudents.length === 0 ||
                        time.length === 0
                          ? 'lightgray'
                          : 'var(--clr-theme-purple-50)',
                    },
                    color: 'var(--clr-theme-white)',
                    fontFamily: 'Lato',
                    width: '90%',
                    marginBottom: '20px',
                  }}
                  onClick={handleNext}
                >
                  Next
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Typography
                  variant='h3'
                  gutterBottom
                  sx={{ fontFamily: 'Lato', fontWeight: '700' }}
                >
                  DATA ENTRY
                </Typography>
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
export default NewGoalDialog;
