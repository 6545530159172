const goalsMockData = {
  goal1: {
    goalType: 'arts',
    step1: 85,
    step2: 75,
    step3: 90,
    step4: 80,
    progress: 82,
  },
  goal2: {
    goalType: 'math',
    step1: 60,
    step2: 50,
    step3: 70,
    step4: 55,
    progress: 58,
  },
  goal3: {
    goalType: 'writing',
    step1: 95,
    step2: 90,
    step3: 85,
    step4: 80,
    progress: 88,
  },
  goal4: {
    goalType: 'science',
    step1: 70,
    step2: 60,
    step3: 75,
    step4: 65,
    progress: 70,
  },
  goal5: {
    goalType: 'history',
    step1: 80,
    step2: 85,
    step3: 90,
    step4: 95,
    progress: 87.5,
  },
  goal6: {
    goalType: 'coding',
    step1: 40,
    step2: 30,
    step3: 50,
    step4: 45,
    progress: 41.25,
  },
};

export default goalsMockData;
