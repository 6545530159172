import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { DataContext } from '../contexts/DataProvider';

const useOneStudentData = (studentUid) => {
  const { fetchOneStudentData } = useContext(DataContext);
  return useQuery({
    queryKey: ['oneStudentData', studentUid],
    queryFn: () => fetchOneStudentData(studentUid),
    enabled: !!studentUid,
    staleTime: 60000, // 1 min
    cacheTime: 300000, // 5 min
  });
};

export default useOneStudentData;